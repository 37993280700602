/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// react plugin used to create charts
//import { Bar } from "react-chartjs-2";
//import { Line, Bar } from "react-chartjs-2";
// react plugin for creating vector maps
// import { VectorMap } from "react-jvectormap";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,  
  //Table,
  Row,
  Col,
} from "reactstrap";


import ProcessedFiles from "../components/Files/ProcessedFiles";
import {sliData} from "../commom/sliHelper"
import NumberFormat from 'react-number-format';
import moment from 'moment';
import ReactBSAlert from "react-bootstrap-sweetalert";

import ChartInventory from "./components/ChartInventory.jsx"
import ChartAttemptsbyScoreBD from "./components/ChartAttemptsbyScoreBD.jsx"
import ChartAttemptsbyScoreLBD from "./components/ChartAttemptsbyScoreLBD.jsx"
import ChartAttemptsbyInventory from "./components/ChartAttemptsbyInventory.jsx"
import ChartKPI from "./components/ChartKPI.jsx"

import ChartMonthsKPI from "./components/kpi/ChartMonthsKPI.jsx"
import ChartMultLinesCustomerCheckPhones from "./components/ChartMultLinesCustomerCheckPhones.jsx"


// var mapData = {
//   AU: 760,
//   BR: 550,
//   CA: 120,
//   DE: 1300,
//   FR: 540,
//   GB: 690,
//   GE: 200,
//   IN: 200,
//   RO: 600,
//   RU: 300,
//   US: 2920
// };

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bigChartData: "data1",
            CustomerID: sessionStorage.getItem("current_CustomerID"),
            ProviderID: sessionStorage.getItem("current_ProviderID"),
            GroupTestSegmentationID: sessionStorage.getItem("current_GroupTestSegmentationID"),
            accounts: {Qty:0, LastDate:0},
            promise: {Qty:0, LastDate:0},
            hot: {Qty:0, LastDate:0},
            collectorLasHour: {Qty:0, LastDate:0},
            AttemptsandCallsView: {},
            CallsLastDayView: {},
            CallsLast15DaysView: {}
        };
       
    }

    LoadData(customerID, providerID, groupTestSegmentationID){

        if (customerID !==null && providerID !==null && groupTestSegmentationID !==null) {

            /*
                Numero de Contas
            */
            var AccountsInfo; 
            var PromiseIVR;
            var baseHot;
            var collectors;


            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(customerID)
                , ProviderID: parseInt(providerID)
                , GroupTestSegmentationID: parseInt(groupTestSegmentationID)
                , Graphname: 'graphQtyActiveAccounts'
            }
            ).then((result) => {
                if (result.status === 'Success'){

                    if (result.data.data==null){
                        this.setState({accounts: [{Qty:0, LastDate:0}] });
                    }
                    else{
                        AccountsInfo=JSON.parse(result.data.data)[0];                   
                        this.setState({accounts: AccountsInfo})
                    }
                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })

            /*
                Numero de Promessas feitas pela URA
            */

            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(customerID)
                , ProviderID: parseInt(providerID)
                , GroupTestSegmentationID: parseInt(groupTestSegmentationID)
                , Graphname: 'graphQtyPromiseIVR'
            }
            ).then((result) => {
                if (result.status === 'Success'){
                    if (result.data.data==null){
                        this.setState({promise: [{Qty:0, LastDate:0}] });
                    }
                    else{
                        PromiseIVR=JSON.parse(result.data.data)[0];                       
                        this.setState({promise: PromiseIVR})
                    }
                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })

            /*
                Numero de Telefones Hot's
            */

           sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(customerID)
                , ProviderID: parseInt(providerID)
                , GroupTestSegmentationID: parseInt(groupTestSegmentationID)
                , Graphname: 'graphQtyHotBase'
            }
            ).then((result) => {
                if (result.status === 'Success'){
                    if (result.data.data==null){
                        this.setState({hot: [{Qty:0, LastDate:0}] });
                    }
                    else{
                        baseHot=JSON.parse(result.data.data)[0];
                        this.setState({hot: baseHot})
                    }

                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })

            /*
                Numero de Collectors na última hora
            */

            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(customerID)
                , ProviderID: parseInt(providerID)
                , GroupTestSegmentationID: parseInt(groupTestSegmentationID)
                , Graphname: 'graphQtyCollectorsIVRLastHour'
            }
            ).then((result) => {
                if (result.status === 'Success'){
                    if (result.data.data==null){
                        this.setState({collectorLasHour: [{Qty:0, LastDate:0}] });
                    }
                    else{
                        collectors=JSON.parse(result.data.data)[0];
                        this.setState({collectorLasHour: collectors})
                    }

                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })

           

        }

    }


    componentDidMount() {       
            
        this.LoadData(this.state.CustomerID, this.state.ProviderID, this.state.GroupTestSegmentationID);
        //document.body.classList.remove.toggle("login-page");
        document.body.classList.toggle("DashBoard-Page");
        //document.documentElement.classList.remove("rtl-active");
    }

    componentDidUpdate(prevProps){
        if (this.state.CustomerID!== sessionStorage.getItem("current_CustomerID") 
            || (this.state.ProviderID!== sessionStorage.getItem("current_ProviderID")) 
            || (this.state.GroupTestSegmentationID!== sessionStorage.getItem("current_GroupTestSegmentationID")))
        {
            this.LoadData(sessionStorage.getItem("current_CustomerID"), sessionStorage.getItem("current_ProviderID"), sessionStorage.getItem("current_GroupTestSegmentationID") );
            this.setState({
                CustomerID: sessionStorage.getItem("current_CustomerID"),
                ProviderID: sessionStorage.getItem("current_ProviderID"),
                GroupTestSegmentationID: sessionStorage.getItem("current_GroupTestSegmentationID")
            });
        }
    }

    basicAlert = (btnstyle) => {
        if (btnstyle==='success'){
            this.setState({
            alert: (
                <ReactBSAlert                
                success  
                style={{ display: "block", marginTop: "-100px" }}
                title={this.state.LastServerMessage}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle={btnstyle}
                //["default","primary","link","info","success","warning","danger"]
                btnSize=""
                />
            )
            });
        }
        else{
            this.setState({
                alert: (
                    <ReactBSAlert
                    danger  //Transforma a string em tag html
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.LastServerMessage}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle={btnstyle}
                    //["default","primary","link","info","success","warning","danger"]
                    btnSize=""
                    />
                )
                });
    
        }
    };    

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };  


  render() {
    let{
        accounts,
        promise,
        hot,
        collectorLasHour,
        alert,        
    } = this.state
    return (
      <>
        <div className="content">
            {alert}
          <Row>
            <Col xs="12">
                <ChartInventory 
                    CustomerID={this.state.CustomerID} 
                    ProviderID={this.state.ProviderID} 
                    GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                ></ChartInventory>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-warning">
                        <i className="tim-icons icon-notes" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">(#)-Clientes Ativos</p>
                        <CardTitle tag="h3">
                            <NumberFormat value={accounts.Qty} displayType={'text'} thousandSeparator={true} />
                            {/* {moment(accounts.QtyOfAccounts).format()} */}
                            </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="tim-icons icon-refresh-01" />Ultima atualização: {moment(accounts.LastDate).format('DD/MM/YYYY HH:mm:ss')}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-primary">
                        <i className="tim-icons icon-mobile" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">(#)-CPF/CNPJ - Hot</p>
                        <CardTitle tag="h3">
                            <NumberFormat value={hot.Qty} displayType={'text'} thousandSeparator={true} />
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                        <i className="tim-icons icon-refresh-01" />Ultima atualização: {moment(hot.LastDate).format('DD/MM/YYYY HH:mm:ss')}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-success">
                        <i className="tim-icons icon-credit-card" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">(%)-Base Hot</p>
                        <CardTitle tag="h3">
                            <NumberFormat value={hot.Qty/accounts.Qty*100} decimalScale={2} displayType={'text'} thousandSeparator={true} />
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                        <i className="tim-icons icon-refresh-01" />Ultima atualização: {moment(promise.LastDate).format('DD/MM/YYYY HH:mm:ss')}
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs="5">
                      <div className="info-icon text-center icon-danger">
                        <i className="tim-icons icon-single-02" />
                      </div>
                    </Col>
                    <Col xs="7">
                      <div className="numbers">
                        <p className="card-category">(#)-Máximo Operadores</p>
                        <CardTitle tag="h3">
                            <NumberFormat value={collectorLasHour.Qty} displayType={'text'} thousandSeparator={true} />    
                        </CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="tim-icons icon-watch-time" /> Mês atual                  
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col xs="12">
                <ChartMultLinesCustomerCheckPhones
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        GraphName="CustomerCheckPhones"
                        Title="(%)-Análise de discagem"
                        SubTitle="Dias Corridos"
                >                        
                </ChartMultLinesCustomerCheckPhones>                
            </Col>
            <Col xs="12">
                <ChartMonthsKPI 
                    CustomerID={this.state.CustomerID}
                    ProviderID={this.state.ProviderID}
                    GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                    // GraphName={radioType==="simpleSum"?"AllKpi-RPCBySuccess":"AllKpi-RPCBySuccess-AC"}
                    GraphName={"AllKpi-DailyPenetration"}                    
                    Title="(%)-Penetração Não Acumulada"
                    SubTitle="Comparação por dia útil"
                    // MarketValuePF={radioType==="simpleSum"?'40':'0'}
                    // MarketValuePJ={radioType==="simpleSum"?'40':'0'}
                    MarketValuePF={'0'}
                    MarketValuePJ={'0'}
                >                        
                </ChartMonthsKPI>                
            </Col>
            <Col lg="4">              
                <ChartAttemptsbyInventory 
                    CustomerID={this.state.CustomerID} 
                    ProviderID={this.state.ProviderID}
                    GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                >                    
                </ChartAttemptsbyInventory>
            </Col>           
            <Col lg="4">
                <ChartAttemptsbyScoreLBD 
                    CustomerID={this.state.CustomerID} 
                    ProviderID={this.state.ProviderID}
                    GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                >

                </ChartAttemptsbyScoreLBD>
            </Col>
            <Col lg="4">
                <ChartAttemptsbyScoreBD 
                    CustomerID={this.state.CustomerID} 
                    ProviderID={this.state.ProviderID}
                    GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                >
                </ChartAttemptsbyScoreBD>                
            </Col>
          </Row>
          <Row>
                <Col xs="12">
                    <ChartKPI 
                        CustomerID={this.state.CustomerID} 
                        ProviderID={this.state.ProviderID} 
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                    >                        
                    </ChartKPI>
                </Col>
          </Row>
          <Row>
            <Col lg="5">
                <Card>
                    <CardHeader>
                    <div className="tools float-right">                    
                    </div>
                    <CardTitle tag="h5">Últimos 5 arquivos Importados</CardTitle>
                    </CardHeader>
                    <CardBody>
                    <ProcessedFiles rows="5" direction="I" CustomerID={this.state.CustomerID} ProviderID={this.state.ProviderID}></ProcessedFiles>
                    </CardBody>
                </Card>
            </Col>
            <Col lg="7">
                <Card>
                    <CardHeader>
                    <div className="tools float-right">                    
                    </div>
                    <CardTitle tag="h5">Últimos 5 arquivos Gerados</CardTitle>
                    </CardHeader>
                    <CardBody>
                    <ProcessedFiles rows="5" direction="O" CustomerID={this.state.CustomerID} ProviderID={this.state.ProviderID}></ProcessedFiles>
                    </CardBody>
                </Card>
            </Col>
            {/* <Col lg="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Global Sales by Top Locations</CardTitle>
                  <p className="card-category">
                    All products that were shipped
                  </p>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("assets/img/US.png")}
                                />
                              </div>
                            </td>
                            <td>USA</td>
                            <td className="text-right">2.920</td>
                            <td className="text-right">53.23%</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("assets/img/DE.png")}
                                />
                              </div>
                            </td>
                            <td>Germany</td>
                            <td className="text-right">1.300</td>
                            <td className="text-right">20.43%</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("assets/img/AU.png")}
                                />
                              </div>
                            </td>
                            <td>Australia</td>
                            <td className="text-right">760</td>
                            <td className="text-right">10.35%</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("assets/img/GB.png")}
                                />
                              </div>
                            </td>
                            <td>United Kingdom</td>
                            <td className="text-right">690</td>
                            <td className="text-right">7.87%</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("assets/img/RO.png")}
                                />
                              </div>
                            </td>
                            <td>Romania</td>
                            <td className="text-right">600</td>
                            <td className="text-right">5.94%</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="flag">
                                <img
                                  alt="..."
                                  src={require("assets/img/BR.png")}
                                />
                              </div>
                            </td>
                            <td>Brasil</td>
                            <td className="text-right">550</td>
                            <td className="text-right">4.34%</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col className="ml-auto mr-auto" md="6">
                      <VectorMap
                        map={"world_mill"}
                        backgroundColor="transparent"
                        zoomOnScroll={false}
                        containerStyle={{
                          width: "100%",
                          height: "300px"
                        }}
                        regionStyle={{
                          initial: {
                            fill: "#e4e4e4",
                            "fill-opacity": 0.9,
                            stroke: "none",
                            "stroke-width": 0,
                            "stroke-opacity": 0
                          }
                        }}
                        series={{
                          regions: [
                            {
                              values: mapData,
                              scale: ["#AAAAAA", "#444444"],
                              normalizeFunction: "polynomial"
                            }
                          ]
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
