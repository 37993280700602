export const verifyLength = (value, length) => {
    if (value.length >= length) {
        return true;
    }
    return false;
};
// function that verifies if two strings are equal
export const compare = (string1, string2) => {
    if (string1 === string2) {
        return true;
    }
    return false;
};


