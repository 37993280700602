import React from 'react';
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Label
  } from "reactstrap";

import ReactDatetime from "react-datetime";  
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactTable from "react-table";
import {sliData} from "../../commom/sliHelper"
import {CSVLink} from "react-csv";
//import moment, { now } from 'moment';
import 'moment/locale/pt-br';
import Loader from 'react-loader-spinner'

import icons from '../../variables/icons';

class ProjectionSummary extends React.Component {
    
    constructor(props){
        super();
        this.state={
            cpf:"",
            LastServerMessage:"",
            qtyCallsLoaded:0,
            CustomerID: sessionStorage.getItem("current_CustomerID"),
            ProviderID: sessionStorage.getItem("current_ProviderID"),
            GroupTestSegmentationID: sessionStorage.getItem("current_GroupTestSegmentationID"),
            ProjectionDate: "",

            data:[],
            isLoadingPage: false,
            spinner: (<Loader
                type="Rings"
                //Audio, Ball-Triangle, Bars, Circles, Grid, Hearts, Oval, Puff, Rings, TailSpin, ThreeDots
                color="#00BFFF"
                height={960}
                width={"100%"}
                //timeout={3000} //3 secs
        
                />)
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.csvLink = React.createRef();
    }

    LoadData = () =>{

        if (this.state.CustomerID!==null && this.state.ProviderID!==null && this.state.GroupTestSegmentationID!==null ){
            
            let dataSource;

            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(this.state.CustomerID)
                , ProviderID: parseInt(this.state.ProviderID)
                , GroupTestSegmentationID: parseInt(this.state.GroupTestSegmentationID)
                , StartDate: this.state.ProjectionDate===""?null:this.state.ProjectionDate
                , Graphname: "ProjectionSummary" //this.props.GraphName
            }).then((result) => {
                if (result.status === 'Success'){
                    if (result.data.data===null){
                
                        this.setState({data: [] });

                    }
                    else{
                
                        // t.Type, T.Date, T.Attempts, T.Connects, T.RPC, T.TP, T.Promise , ISNULL(t2.Qty,0) Inventory

                        dataSource=JSON.parse(result.data.data);
                        
                        let data=dataSource;
                        
                        this.setState({ data: data 
                                        , isLoadingPage:false
                        });
                                

                    }

                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })


        }
    }

    basicAlert = (btnstyle) => {
        if (btnstyle==='success'){
            this.setState({
            alert: (
                <ReactBSAlert                
                success  
                style={{ display: "block", marginTop: "-100px" }}
                title={this.state.LastServerMessage}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle={btnstyle}
                //["default","primary","link","info","success","warning","danger"]
                btnSize=""
                />
            )
            });
        }
        else{
            this.setState({
                alert: (
                    <ReactBSAlert
                    danger  //Transforma a string em tag html
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.LastServerMessage}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle={btnstyle}
                    //["default","primary","link","info","success","warning","danger"]
                    btnSize=""
                    />
                )
                });

        }
      };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };      

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toString().toLowerCase()).startsWith(filter.value.toLowerCase())
                :
                true
        );
    }

    componentDidMount() {
        //document.body.classList.toggle("BradescoTab-page");
        this.LoadData(); 
    }

    componentDidUpdate(prevState){
        
        if (this.state.CustomerID!== sessionStorage.getItem("current_CustomerID") || (this.state.ProviderID!== sessionStorage.getItem("current_ProviderID")) ){
            this.setState({
                CustomerID: sessionStorage.getItem("current_CustomerID"),
                ProviderID: sessionStorage.getItem("current_ProviderID"),                
                data: []
                }
                ,
                ()=>{ 
                        this.LoadData(); 
            
                }           
            );
        }
                
    }

    ProjectionDate = (event) =>
    {

        this.setState({ProjectionDate:event._d},()=>{
            this.LoadData();
        })
     

    }

    render(){
        let {
            alert,
            data,
            isLoadingPage,
            spinner,
            ProjectionDate,
        } = this.state;

        return (

            <div className="content">
                {alert}
                <Row>
                    <Col lg="12">
                        <Card className="sticky">
                            <Row>
                                <Col sm="1">
                                    <Label className="text-info">Data Base Projeção:</Label>
                                </Col>
                                <Col sm="2">
                                    <ReactDatetime 
                                        defaultValue={ProjectionDate}
                                        timeFormat={false}
                                        locale=""
                                        onChange={e => 
                                            {
                                                this.ProjectionDate(e)
                                            }
                                        }
                                    ></ReactDatetime>
                                </Col>
                            </Row>
                        </Card>                    
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col xs={12} md={12}>
                        <Card>
                            <CardHeader>                                
                                <CardTitle tag="h4">Resumo de Projeções - Todas as Carteiras</CardTitle>
                                
                              
                                {
                                    this.state.data.length!==0?
                                       (
                                            <CSVLink 
                                                data={data}
                                                filename={'Projeções - Todas as Carteiras.csv'}
                                                target="_blank" 
                                            >
                                                <i className={"tim-icons " + icons[5].name} />Baixar Projeção em ".csv"
                                            </CSVLink>                                            
                                       )
                                    :
                                        null
                                }
                              
                            </CardHeader>
                            {isLoadingPage
                                ?spinner
                                :
                                (
                                    <CardBody>
                                        <ReactTable
                                            data={data}
                                            filterable
                                            resizable={false}
                                            // defaultPageSize={5}
                                            pageSize={(data.length > 20) ? 20 : data.length}
                                            // showPaginationTop
                                            showPaginationBottom={false}
                                            className="-striped -highlight"
                                            showPageSizeOptions= {false}
                                            defaultFilterMethod={this.filterCaseInsensitive}
                                            columns={[
                                            {
                                                Header: "Carteira",
                                                accessor: "ProviderAlias",
                                                headerStyle: {textAlign: 'center'},
                                                //className:'text-center',
                                                Cell: row => {
                                                    return row.value;
                                                }
                                            },
                                            {
                                                Header: "% Goal Final",
                                                accessor: "GoalPercentage",
                                                headerStyle: {textAlign: 'center'},
                                                className:'text-center',
                                                Cell: row => {
                                                    //row.styles['color'] = "#0000";
                                                    row.styles['backgroundColor'] = row.value >=1? '#428519': '#CC2B2B';
                                                    return (row.value*100).toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits:2})
                                                }
                                            },   
                                            {
                                                Header: "Meta",
                                                accessor: "Goal",
                                                headerStyle: {textAlign: 'center'},
                                                className:'text-center',
                                                Cell: row => {
                                                    return row.value.toLocaleString(undefined,{minimumFractionDigits: 0,maximumFractionDigits:0})
                                                }
                                            },
                                            {
                                                Header: "Projeção Final",
                                                accessor: "ProjectionAmount",
                                                headerStyle: {textAlign: 'center'},
                                                className:'text-center',
                                                Cell: row => {
                                                    return row.value.toLocaleString(undefined,{minimumFractionDigits: 0,maximumFractionDigits:0})
                                                }
                                                
                                                // Cell: row => (
                                                //     <div
                                                //       style={{
                                                //         textAlign: 'center'
                                                //       }}
                                                //       >{(row.value).toLocaleString(undefined,{minimumFractionDigits: 0,maximumFractionDigits:0})}</div>
                                                // )
                                            }, 
                                            {
                                                Header: "Dif. Meta",
                                                accessor: "GapToGoal",
                                                headerStyle: {textAlign: 'center'},
                                                className:'text-center',
                                                Cell: row => {
                                                    return row.value.toLocaleString(undefined,{minimumFractionDigits: 0,maximumFractionDigits:0})
                                                }
                                            }, 
                                            {
                                                Header: "Caixa MTD",
                                                accessor: "CashMTD",
                                                headerStyle: {textAlign: 'center'},
                                                className:'text-center',
                                                Cell: row => {
                                                    return row.value.toLocaleString(undefined,{minimumFractionDigits: 0,maximumFractionDigits:0})
                                                }
                                            },
                                            {
                                                Header: "Inventário",
                                                headerClassName:"text-center",
                                                columns:[
                                                    {
                                                        Header: "Promessa",
                                                        accessor: "ActivePromise",
                                                        headerStyle: {textAlign: 'center'},
                                                        className:'text-center',
                                                        Cell: row => {
                                                            return row.value.toLocaleString(undefined,{minimumFractionDigits: 0,maximumFractionDigits:0})
                                                        }
                                                    }
                                                    ,
                                                    {
                                                        Header: "Acordo",
                                                        accessor: "ActiveAgreement",
                                                        headerStyle: {textAlign: 'center'},
                                                        className:'text-center',
                                                        Cell: row => {
                                                            return row.value.toLocaleString(undefined,{minimumFractionDigits: 0,maximumFractionDigits:0})
                                                        }
                                                    }
                                                
                                                ]
                                            },
                                            {
                                                Header: "Geração",
                                                headerClassName:"text-center",                                                
                                                columns:[
                                                    {
                                                        Header: "Média Dia",
                                                        accessor: "PromiseIntraMonthAvg",
                                                        headerStyle: {textAlign: 'center'},
                                                        className:'text-center',
                                                        Cell: row => {
                                                            return row.value.toLocaleString(undefined,{minimumFractionDigits: 0,maximumFractionDigits:0})
                                                        }
                                                    }
                                                    ,
                                                    {
                                                        Header: "MTD",
                                                        accessor: "PromiseMTD",
                                                        headerStyle: {textAlign: 'center'},
                                                        className:'text-center',
                                                        Cell: row => {
                                                            return row.value.toLocaleString(undefined,{minimumFractionDigits: 0,maximumFractionDigits:0})
                                                        }
                                                    }
                                                
                                                ]
                                            },
                                            {
                                                Header: "Conversão",
                                                headerClassName:"text-center",
                                                columns:[
                                                    {
                                                        Header: "Promessa",
                                                        accessor: "PromiseConversion",
                                                        headerStyle: {textAlign: 'center'},
                                                        className:'text-center',
                                                        Cell: row => {
                                                            return (row.value*100).toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits:2})
                                                        }
                                                    }
                                                    ,
                                                    {
                                                        Header: "Acordo",
                                                        accessor: "AgreementConversion",
                                                        headerStyle: {textAlign: 'center'},
                                                        className:'text-center',
                                                        Cell: row => {
                                                            return (row.value*100).toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits:2})
                                                        }
                                                    }
                                                
                                                ]
                                            },                                                                                       
                                            {
                                                Header: "Ger.Nec.p/Dia",
                                                accessor: "MissingGeneration",
                                                headerStyle: {textAlign: 'center'},
                                                className:'text-center',
                                                Cell: row => {
                                                    return row.value.toLocaleString(undefined,{minimumFractionDigits: 0,maximumFractionDigits:0})
                                                }
                                            },
                                                                                 

                                            // {
                                            //     Header: "CPC/Conexão",
                                            //     accessor: "RPCRate",
                                            //     headerStyle: {textAlign: 'center'},
                                            //     Cell: row => (
                                            //         <div
                                            //           style={{
                                            //             textAlign: 'center'
                                            //           }}
                                            //         >{(row.value*100).toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits:2})}</div>
                                            //     )
                                            // },
                                           


                                            // {
                                            //     Header: "Actions",
                                            //     accessor: "actions",
                                            //     sortable: false,
                                            //     filterable: false
                                            // }
                                            ]}
                                            
                                        />
                                    </CardBody>
                                )
                            }
                        </Card>
                    </Col>
         
                </Row>
                
            </div>

        )

    }

}
export default ProjectionSummary;
