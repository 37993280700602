import React from 'react';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    ButtonGroup,

  } from "reactstrap";

import { Line } from "react-chartjs-2";

import classNames from "classnames";

import {sliData} from "../../../commom/sliHelper"

let chartOptions = {
    maintainAspectRatio: false,
    legend: {
        display: true,
        labels: {
            fontColor: 'rgb(255, 99, 132)'
        }
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          type: 'linear',          
          barPercentage: 1.6,
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent"
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 0,
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
        // ,{
        //     type: 'linear',          
        //     barPercentage: 1.6,
        //     display: true,
        //     position: 'right',
        //     label: 'test',
        //     id: 'y-axis-2',
        //     gridLines: {
        //       drawBorder: false,
        //       color: "rgba(29,140,248,0.0)",
        //       zeroLineColor: "transparent"
        //     },
        //     ticks: {
        //       suggestedMin: 60,
        //       suggestedMax: 125,
        //       padding: 20,
        //       fontColor: "#9a9a9a"
        //     }
        //   }
      ],
      xAxes: [
        {
          barPercentage: 1.6,          
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ]
    }
  };



class ChartMonthsKPI extends React.Component {
    constructor(props){
        super(props);
        this.state={
            GraphView1: {},
            GraphView2: {},
            
            bigChartData: "data1",
            Title: this.props.Title,
            SubTitle:this.props.SubTitle
        }; 

    }   

    SetGraphData = (barLabel,labelData,data1,data2,data3,data4,data5,data6, marketData) => {
        var graphFunction = canvas => {
            let ctx = canvas.getContext("2d");
        
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        
            gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
            gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
            gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
            
            let months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
            let d = new Date();
           


            let label1=months[d.getMonth()];
            let label2=months[d.getMonth()-1 + (d.getMonth()-1<0?12:0)];
            let label3=months[d.getMonth()-2 + (d.getMonth()-2<0?12:0)];
            let label4=months[d.getMonth()-3 + (d.getMonth()-3<0?12:0)];
            let label5=months[d.getMonth()-4 + (d.getMonth()-4<0?12:0)];
            let label6=months[d.getMonth()-5 + (d.getMonth()-5<0?12:0)];
            
            // let label1="0";            
            // let label2="1";
            // let label3="2";
            // let label4="3";
            // let label5="4";
            // let label6="5"; 

            // if (monthsName.length !== 0){
            //     label1 = monthsName[0];            
            //     label2 = monthsName[1];
            //     label3 = monthsName[2];
            //     label4 = monthsName[3];
            //     label5 = monthsName[4];
            //     label6 = monthsName[5];
            // }
            



            return {
              labels: labelData,
              datasets: [
                {
                  type: "line",
                  label: label1,
                  fill: true,
                  hidden: false,
                  backgroundColor: gradientStroke,
                  borderColor: "#ff6600", //"#1f8ef1",
                  borderWidth: 4,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  pointBackgroundColor: "#ff6600",//"#1f8ef1",
                  pointBorderColor: "rgba(255,255,255,0)",
                  pointHoverBackgroundColor: "#ff6600", //"#1f8ef1","#ff6600"
                  pointBorderWidth: 20,
                  pointHoverRadius: 4,
                  pointHoverBorderWidth: 15,
                  pointRadius: 2,
                  data: data1,
                  yAxisID: "y-axis-1"
                }
                ,{
                    type: "line",
                    label: label2,
                    fill: true,
                    hidden: false,
                    backgroundColor: gradientStroke,
                    borderColor: "#65C57B",
                    borderWidth: 2,
                    borderDash: [],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: "#65C57B",
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: "#65C57B",
                    pointBorderWidth: 20,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 2,
                    data: data2,
                    yAxisID: "y-axis-1"
                }
                ,{
                    type: "line",
                    label: label3,
                    fill: true,
                    hidden: false,
                    backgroundColor: gradientStroke,
                    borderColor: "#1f8ef1",
                    borderWidth: 2,
                    borderDash: [],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: "#1f8ef1",
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: "#1f8ef1",
                    pointBorderWidth: 20,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 2,
                    data: data3,
                    yAxisID: "y-axis-1"
                },               
                {
                    type: "line",
                    label: label4,
                    fill: true,
                    hidden: false,
                    backgroundColor: gradientStroke,
                    borderColor: "#cc6699",
                    borderWidth: 2,
                    borderDash: [],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: "#cc6699",
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: "#cc6699",
                    pointBorderWidth: 20,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 2,
                    data: data4,
                    yAxisID: "y-axis-1"
                },               
                {
                    type: "line",
                    label: label5,
                    fill: true,
                    hidden: true,
                    backgroundColor: gradientStroke,
                    borderColor: "#fe2e64",
                    borderWidth: 2,
                    borderDash: [],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: "#fe2e64",
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: "#fe2e64",
                    pointBorderWidth: 20,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 2,
                    data: data5,
                    yAxisID: "y-axis-1"
                },               
                {
                    type: "line",
                    label: label6,
                    fill: true,
                    hidden: true,
                    backgroundColor: gradientStroke,
                    borderColor: "#ffc300",
                    borderWidth: 2,
                    borderDash: [],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: "#ffc300",
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: "#ffc300",
                    pointBorderWidth: 20,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 2,
                    data: data6,
                    yAxisID: "y-axis-1"
                },
                {
                    type: "line",
                    label: 'Mercado',
                    fill: true,
                    backgroundColor: gradientStroke,
                    borderColor: "#FF0000",
                    borderWidth: 1,
                    borderDash: [5,3],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: "#FF0000",
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: "#FF0000",
                    pointBorderWidth: 20,
                    pointHoverRadius: 1,
                    pointHoverBorderWidth: 15,
                    pointRadius: 0,
                    data: marketData,
                    yAxisID: "y-axis-1"
                }        
              ]
            };
        }


        return graphFunction
    }

    LoadData = () =>{

        if (this.props.CustomerID!==null && this.props.ProviderID!==null && this.props.GroupTestSegmentationID!==null ){
            
            var dataSource;

            let mkpj = 0;
            mkpj = this.props.MarketValuePJ;
            let mkpf = 0;
            mkpf = this.props.MarketValuePF;

            if (mkpf===undefined)
            {
                mkpf=0;
            }
            else
            {
               
            }

            if (mkpj===undefined)
            {
                mkpj=0;
            }
            else
            {
                
            }

            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(this.props.CustomerID)
                , ProviderID: parseInt(this.props.ProviderID)
                , GroupTestSegmentationID: parseInt(this.props.GroupTestSegmentationID)
                , Graphname: this.props.GraphName
            }).then((result) => {
                if (result.status === 'Success'){
                    if (result.data.data===null){
                
                       // this.setState({Graph1View: {}, Graph2View: {}})

                        this.setState({GraphView1: this.SetGraphData("Alias1",[1], [0],[0], [0],[0],[0],[0],['0'])
                        , GraphView2: this.SetGraphData("Alias2",[1], [0],[0], [0],[0],[0],[0],['0'])

                    });
                    }
                    else{
                
                        // t.Type, T.Date, T.Attempts, T.Connects, T.RPC, T.TP, T.Promise , ISNULL(t2.Qty,0) Inventory

                        dataSource=JSON.parse(result.data.data);
                        
                        let Graph1Label=[];
                        let Graph1Data0=[];
                        let Graph1Data1=[];
                        let Graph1Data2=[];
                        let Graph1Data3=[];
                        let Graph1Data4=[];
                        let Graph1Data5=[];

                        let Graph2Label=[];
                        let Graph2Data0=[];
                        let Graph2Data1=[];
                        let Graph2Data2=[];
                        let Graph2Data3=[];
                        let Graph2Data4=[];
                        let Graph2Data5=[];
                        
                        let MarketDataPF=[];
                        let MarketDataPJ=[];

                        for (var i in dataSource){
                            if (dataSource[i].Type==="F"){
                                Graph1Label.push(dataSource[i].BusinessDayOfMonth);
                                Graph1Data0.push(dataSource[i].Month0);
                                Graph1Data1.push(dataSource[i].Month1);
                                Graph1Data2.push(dataSource[i].Month2);
                                Graph1Data3.push(dataSource[i].Month3);
                                Graph1Data4.push(dataSource[i].Month4);
                                Graph1Data5.push(dataSource[i].Month5);
                                MarketDataPF.push(mkpf);
                            }
                            if (dataSource[i].Type==="J"){
                                Graph2Label.push(dataSource[i].BusinessDayOfMonth);
                                Graph2Data0.push(dataSource[i].Month0);
                                Graph2Data1.push(dataSource[i].Month1);
                                Graph2Data2.push(dataSource[i].Month2);
                                Graph2Data3.push(dataSource[i].Month3);
                                Graph2Data4.push(dataSource[i].Month4);
                                Graph2Data5.push(dataSource[i].Month5);
                                MarketDataPJ.push(mkpj);
                            }

                        }
                       

                        let Graph1ResultView = this.SetGraphData("Alias1", Graph1Label, Graph1Data0, Graph1Data1, Graph1Data2,Graph1Data3,Graph1Data4,Graph1Data5,MarketDataPF);
                        let Graph2ResultView = this.SetGraphData("Alias2", Graph2Label, Graph2Data0, Graph2Data1, Graph2Data2,Graph2Data3,Graph2Data4,Graph2Data5,MarketDataPJ);
                        
                        this.setState({ GraphView1: Graph1ResultView, GraphView2: Graph2ResultView });
                                

                    }

                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })


        }
    }

    initializeGraph = () =>{


        this.setState({GraphView1: this.SetGraphData("Alias1",[1], [0],[0], [0],[0],[0],[0],['0'])
                     , GraphView2: this.SetGraphData("Alias2",[1], [0],[0], [0],[0],[0],[0],['0'])
        });
        // this.setState({GraphView1: this.SetGraphData("",[], [], [], [],[],[],[])
        //     , GraphView2: this.SetGraphData("",[], [], [], [],[],[],[])
        // });
    }


    setBgChartData = name => {
        this.setState({
        bigChartData: name
        });
    };

    componentDidMount() {
        this.initializeGraph();
        this.LoadData();
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps){
        
        if ((prevProps.CustomerID !== this.props.CustomerID) 
                || (prevProps.ProviderID !== this.props.ProviderID) 
                || (prevProps.GroupTestSegmentationID !== this.props.GroupTestSegmentationID) 
                || (prevProps.GraphName !== this.props.GraphName) ){
            this.LoadData();
        }
                
    }

   
    render(){
        let {    
            GraphView1,
            GraphView2,
            Title,
            SubTitle,
        } = this.state;

        return (

            <div className="content">
                <Row>
                    <Col>
                        <Card className="card-chart">
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="6">
                                        <h5 className="card-category">{SubTitle}</h5>
                                        <CardTitle tag="h3">
                                            <i className="tim-icons icon-shape-star text-danger" />{" "}
                                            {Title}
                                        </CardTitle>
                                    </Col>
                                    <Col sm="6">
                                        <ButtonGroup
                                            className="btn-group-toggle float-right"
                                            data-toggle="buttons"
                                        >
                                            <Button
                                                color="info"
                                                id="0"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: this.state.bigChartData === "data1"
                                                })}
                                                onClick={() => this.setBgChartData("data1")}
                                                >
                                                <input defaultChecked name="options" type="radio" />
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Física
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="1"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: this.state.bigChartData === "data2"
                                                })}
                                                onClick={() => this.setBgChartData("data2")}
                                                >
                                                <input name="options" type="radio" />
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Jurídica
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-bus-front-12" />
                                                </span>
                                            </Button>
                                            {/* <Button
                                                color="info"
                                                id="2"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: this.state.bigChartData === "data3"
                                                })}
                                                onClick={() => this.setBgChartData("data3")}
                                                >
                                                <input name="options" type="radio" />
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Dialer
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-tap-02" />
                                                </span>
                                            </Button> */}
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                            <div className="chart-area">
                                <Line
                                    data={this.state.bigChartData==="data1"?GraphView1:GraphView2}
                                    options={chartOptions}
                                />
                            </div>
                            </CardBody>
                        </Card>
                    </Col>                   
                </Row>
            </div>

        )

    }

}
export default ChartMonthsKPI;
