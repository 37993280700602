import React from 'react';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    ButtonGroup,
    Label,

  } from "reactstrap";

import { Bar } from "react-chartjs-2";
import classNames from "classnames";
import {sliData} from "../../commom/sliHelper"
import ReactDatetime from "react-datetime";

let chartOptions =
{
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(225,78,202,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 120,
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(225,78,202,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }
      ]
    }
}



class ChartAttemptsbyScoreLBD extends React.Component {
    constructor(props){
        super(props);
        this.state={
            GraphView1: {},
            GraphView2: {},
            GraphView3: {},
            bigChartData: "data1",
            LastDate: "",
            CallDate:""
        };

    }

    SetGraphData = (barLabel,labelData,data) => {
        var graphFunction = canvas => {
            let ctx = canvas.getContext("2d");

            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

            gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
            gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
            gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

            return {
              labels: labelData,
              datasets: [
                {
                  label: barLabel,
                  fill: true,
                  backgroundColor: gradientStroke,
                  hoverBackgroundColor: gradientStroke,
                  borderColor: "#d048b6",
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  data: data
                }
              ]
            };

        }


        return graphFunction
    }

    LoadData = () =>{

        /*
            Inventário Total
        */
        if (this.props.CustomerID!==null && this.props.ProviderID!==null && this.props.GroupTestSegmentationID!==null){

            var dataSource;

            /*
                Ligações por score 15 ultimos dias
            */

            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(this.props.CustomerID)
                , ProviderID: parseInt(this.props.ProviderID)
                , GroupTestSegmentationID: parseInt(this.props.GroupTestSegmentationID)
                , StartDate: this.state.CallDate===""?null:this.state.CallDate
                , Graphname: 'graphCallByScoreLastday'
            }).then((result) => {
                if (result.status === 'Success'){
                    if (result.data.data==null){

                        this.setState({GraphView1: {}, GraphView2: {} ,GraphView3: {} })
                    }
                    else{


                        dataSource=JSON.parse(result.data.data);
                        let Graph1Data=[];
                        let Graph1Label=[];
                        let Graph2DataURA=[];
                        let Graph2LabelURA=[];
                        let Graph3DataDialer=[];
                        let Graph3LabelDialer=[];
                        //this.setState({collectorLasHour: collectors})

                        for (var i in dataSource){
                            if (dataSource[i].Type==="Total"){
                                Graph1Data.push(dataSource[i].Attempts);
                                Graph1Label.push(dataSource[i].ScoreTier);
                            }
                            if (dataSource[i].Type==="URA"){
                                Graph2DataURA.push(dataSource[i].Attempts);
                                Graph2LabelURA.push(dataSource[i].ScoreTier);
                            }
                            if (dataSource[i].Type==="Dialer"){
                                Graph3DataDialer.push(dataSource[i].Attempts);
                                Graph3LabelDialer.push(dataSource[i].ScoreTier);
                            }
                        }

                        let graph1View= this.SetGraphData("URA+Dialer", Graph1Label, Graph1Data);
                        let graph2View= this.SetGraphData("URA", Graph2LabelURA, Graph2DataURA);
                        let graph3View= this.SetGraphData("Dialer", Graph3LabelDialer, Graph3DataDialer);

                        this.setState({GraphView1: graph1View, GraphView2: graph2View ,GraphView3: graph3View, LastDate: dataSource[0].LastDate});


                    }

                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {

                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })


        }
    }

    CallDate =(event) => 
    {

        this.setState({CallDate:event._d},()=>{
            this.LoadData()  
        })
     

    }


    setBgChartData = name => {
        this.setState({
        bigChartData: name
        });
    };

    componentDidMount() {
        document.body.classList.toggle("BradescoTab-page");
        this.LoadData();
    }


    componentDidUpdate(prevProps){

        if (
            (prevProps.CustomerID !== this.props.CustomerID)
            || (prevProps.ProviderID !== this.props.ProviderID)
            || (prevProps.GroupTestSegmentationID !== this.props.GroupTestSegmentationID)
        )
        {
            this.LoadData();
        }

    }


    render(){
        let {
            GraphView1,
            GraphView2,
            GraphView3,
            LastDate
        } = this.state;

        return (

            <div className="content">
                <Card className="card-chart">
                    <CardHeader>
                        <Row>
                            <Col className="text-left" sm="6">
                                <h5 className="card-category">Último dia útil - {LastDate}</h5>
                                <CardTitle tag="h3">
                                    <i className="tim-icons icon-shape-star text-danger" />{" "}
                                    (#)-Tentativas x Score
                                </CardTitle>
                            </Col>
                            <Col sm="6">
                                <Row>
                                    <Col sm="12">
                                        <ButtonGroup
                                            className="btn-group-toggle float-right"
                                            data-toggle="buttons"
                                        >
                                            <Button
                                                color="info"
                                                id="0"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: this.state.bigChartData === "data1"
                                                })}
                                                onClick={() => this.setBgChartData("data1")}
                                                >
                                                <input defaultChecked name="options" type="radio" />
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Ura+Dialer
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="1"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: this.state.bigChartData === "data2"
                                                })}
                                                onClick={() => this.setBgChartData("data2")}
                                                >
                                                <input name="options" type="radio" />
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Ura
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-bus-front-12" />
                                                </span>
                                            </Button>
                                            <Button
                                                color="info"
                                                id="2"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: this.state.bigChartData === "data3"
                                                })}
                                                onClick={() => this.setBgChartData("data3")}
                                                >
                                                <input name="options" type="radio" />
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Dialer
                                                </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-tap-02" />
                                                </span>
                                            </Button>
                                            
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="4" className="text-right">
                                        <Label className="text-danger">Data:</Label>
                                    </Col>
                                    <Col sm="8">
                                        <ReactDatetime  className="text-danger"
                                            timeFormat={false}
                                            onChange={e => 
                                                {
                                                    this.CallDate(e)
                                                    //sliValidations.fieldChange(this, e, "endDate")
                                                    //sliValidations.fieldChange(this, e, "ddd", "length",2)
                                                }
                                        }
                                        
                                        >                                    
                                        </ReactDatetime>
                                    </Col>
                                </Row>
                                {/* <Label sm="2">Data:</Label>  */}
                                {/*  */}
                            </Col>
                            

                            {/* <Col sm="6">
                            </Col> */}
                        </Row>
                    </CardHeader>
                    <CardBody>
                    <div className="chart-area">
                        <Bar
                        data={this.state.bigChartData==="data1"?GraphView1:((this.state.bigChartData==="data2")?GraphView2:GraphView3)}
                        options={chartOptions}
                        />
                    </div>
                    </CardBody>
                </Card>
            </div>

        )

    }

}
export default ChartAttemptsbyScoreLBD;
