import React from 'react';
import {
	Chart,
	Point,
	Annotation,
	Axis,
	Coordinate,
	registerShape,
} from 'bizcharts';

// 
registerShape('point', 'pointer', {
	draw(cfg, container) {

		const group = container.addGroup();
		
		const center = this.parsePoint({ x: 0, y: 0 }); // 获取极坐标系下画布中心点
		const start = this.parsePoint({ x: 0, y: 0.5 }); // 获取极坐标系下起始点
	

		//const preAngle = this.preAngle || 0;
        //const preAngle;
		const angle1 = Math.atan((start.y - center.y) / (start.x - center.x));
		const angle = (Math.PI - 2 * (angle1)) * cfg.points[0].x;

		this.preAngle = angle;

		return group;
	},
});


const scale = {
				value: {
					min: 0,
					max: 1,
					tickInterval: 0.1,
					formatter: v => v * 100
				}
			}

class RingDisk extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data:this.props.Value,
        };
       
    }

    componentDidMount() {
        // document.body.classList.toggle("BradescoTab-page");         
        // this.useEffect([[]]);
    }
    
 
     componentDidUpdate(prevProps){
         
        if ((prevProps.Value !== this.props.Value ) ){                            
             // this.setState({GraphView:graphData});
            this.setState({data:this.props.Value});
        }
       
    }


    render(){
        let {    
             data,
        } = this.state;
        return (

            <Chart
                height={150}
                data={data}
                scale={scale}
                autoFit
            >
            <Coordinate
                type="polar"
                radius={0.75}
                startAngle={(-1.4) * Math.PI}
                endAngle={(0.4) * Math.PI}
            />
                <Axis
                    name="value"
                    line={null}
                    visible={false}
                    label={{
                        offset: -36,
                        style: {
                            fontSize: 18,
                            textAlign: 'center',
                            textBaseline: 'middle',
                        },
                    }}
                
                    grid={null}
                />
                <Point
                    position="value*1"
                    color="#1890FF"
                    shape="pointer"
                />
                <Annotation.Arc
                    start={[0, 1]}
                    end={[1, 1]}
                    style={{
                        stroke: '#CBCBCB',
                        lineWidth: 18,
                        lineDash: null,
                        lineCap: 'round',
                    }}
                />
                <Annotation.Arc
                    start={[0, 1]}
                    end={[data>1?1:data, 1]}
                    style={{
                        stroke: '#1890FF',
                        lineCap: 'round',
                        lineWidth: 18,
                        lineDash: null,
                    }}
                />
                <Annotation.Text
                    position={['50%', '50%']}
                    //content={`${Math.round(data[0].value * 100)}%`}
                    content={`${Math.round(data * 100)}%`}
                    style={{
                    fontSize: 30,
                    fill: '#ffff',
                    textAlign: 'center',
                    }}
                />
            </Chart>
        )
    }

}

export default RingDisk;