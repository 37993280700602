/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import GoogleMaps from "views/maps/GoogleMaps.jsx";
//import FullScreenMap from "views/maps/FullScreenMap.jsx";
//import Buttons from "views/components/Buttons.jsx";
//import Pricing from "views/pages/Pricing.jsx";
//import Rtl from "views/pages/Rtl.jsx";
import Dashboard from "sli/views/Dashboard.jsx";
import BradescoTab from "sli/views/pages/BradescoTab.jsx"
import LoginSLI from "sli/views/pages/LoginSLI.jsx";
import DefaultPage from "./sli/views/pages/Default.jsx";
import WebFTP from "./sli/views/pages/WebFTP.jsx";
// import DynamicReportCalls from "./sli/views/pages/DynamicReportCalls.jsx";
import Register from "sli/views/pages/RegisterSLI.jsx";
import ResetPassword from "sli/views/pages/ResetPassword.jsx";
import DashboardKpi from "sli/views/DashboardKpi.jsx";
import DashboardCash from "sli/views/DashboardCash.jsx";
import CallHistory from "sli/views/pages/CallHistory.jsx";
import SMSProcess from "sli/views/pages/SMSProcess.jsx";
import SkipProcess from "sli/views/pages/SkipProcess.jsx";
import BlackList from "sli/views/pages/Maintenance/BlackList.jsx";
import DashboardCollectorsKPI from "sli/views/DashboardCollectorsKPI.jsx";
import ProjectionSummary from "sli/views/pages/ProjectionSummary.jsx";


const routes = [
    {
        collapse: true,
        name: "Dashboards",
        rtlName: "",
        icon: "tim-icons icon-chart-pie-36",
        state: "pagesCollapse",
        visible: true,
        views: [
            {
                path: "/dashboard",
                name: "Dashboard",
                rtlName: "لوحة القيادة",
                mini: "D1",
                rtlMini: "ع",
                component: Dashboard,
                layout: "/MainSLI",
                visible: true
            },
            {
                path: "/dashboardKpi",
                name: "Dashboard KPI",
                rtlName: "لوحة القيادة",
                mini: "D2",
                rtlMini: "ع",
                component: DashboardKpi,
                layout: "/MainSLI",
                visible: true
            },
            {
                path: "/dashboardCash",
                name: "Dashboard Cash",
                rtlName: "لوحة القيادة",
                mini: "D3",
                rtlMini: "ع",
                component: DashboardCash,
                layout: "/MainSLI",
                visible: true
            },
            {
                path: "/dashboardCollectorsKPI",
                name: "DashBoard Collectors",
                rtlName: "لوحة القيادة",
                mini: "D4",
                rtlMini: "ع",
                component: DashboardCollectorsKPI,
                layout: "/MainSLI",
                visible: true
            }
        ]
    },
    {
        collapse: true,
        name: "Customizadas",
        rtlName: "CS",
        icon: "tim-icons icon-notes",
        state: "CustomCollapse",
        visible: true,
        views: [
            {
                path: "/Bradesco",
                name: "Bradesco Tabulações",
                rtlName: "لوحة القيادة",
                mini: "CB1",
                rtlMini: "ع",
                component: BradescoTab,
                layout: "/MainSLI",
                visible: true
            }
            //,
            // {
            //     path: "/dashboard2",
            //     name: "Dashboard2",
            //     rtlName: "لوحة القيادة",
            //     mini: "CB2",
            //     rtlMini: "ع",
            //     component: BradescoTab,
            //     layout: "/MainSLI"
            // }
        ]    
    }
    ,
    {
        collapse: true,
        name: "Relatórios",
        rtlName: "صفحات",
        icon: "tim-icons icon-paper",
        state: "ReportsCollapse",
        visible: true,
        views: [
            // {
            //     path: "/DynamicReportCalls",
            //     name: "Calls",
            //     rtlName: "عالتسعير",
            //     mini: "K",
            //     rtlMini: "ع",
            //     component: DynamicReportCalls,
            //     layout: "/MainSLI",
            //     visible: true
            // },
            {
                path: "/rtl-support",
                name: "Operations",
                rtlName: "صودعم رتل",
                mini: "O",
                rtlMini: "صو",
                component: Dashboard,
                layout: "/rtl",
                visible: true
            }
        ]
    },
    {
        collapse: true,
        name: "Consultas",
        rtlName: "المكونات",
        icon: "tim-icons icon-zoom-split",
        state: "SLIViewCollapse",
        visible: true,
        views: [   
            {
                path: "/ProjectionSummary",
                name: "Resumo Projeções",
                rtlName: "صودعم رتل",
                mini: "P",
                rtlMini: "صو",
                component: ProjectionSummary,
                layout: "/MainSLI",
                visible: true
            },        
            {
                path: "/CallHistory",
                name: "Ligações por Período",
                rtlName: "صودعم رتل",
                mini: "L",
                rtlMini: "صو",
                component: CallHistory,
                layout: "/MainSLI",
                visible: true
            },
            {
                path: "/SMSProcess",
                name: "Controle SMS",
                rtlName: "صودعم رتل",
                mini: "S",
                rtlMini: "صو",
                component: SMSProcess,
                layout: "/MainSLI",
                visible: true
            },
            {
                path: "/SkipProcess",
                name: "Controle Enriquecimento",
                rtlName: "صودعم رتل",
                mini: "E",
                rtlMini: "صو",
                component: SkipProcess,
                layout: "/MainSLI",
                visible: true
            }

        ]
    },
    {
        collapse: true,
        name: "Manutenção",
        rtlName: "المكونات",
        icon: "tim-icons icon-molecule-40",
        state: "SLIDataCollapse",
        visible: true,
        views: [           
            {
                path: "/BlackList",
                name: "BlackList",
                rtlName: "وصفت",
                mini: "DC",
                rtlMini: "ب",
                component: BlackList,
                layout: "/MainSLI",
                visible: true
            },
            {
                path: "/buttons",
                name: "Providers",
                rtlName: "وصفت",
                mini: "DP",
                rtlMini: "ب",
                component: Dashboard,
                layout: "/MainSLI",
                visible: true
            },
            {
                path: "/buttons",
                name: "Asset Owners",
                rtlName: "وصفت",
                mini: "DO",
                rtlMini: "ب",
                component: Dashboard,
                layout: "/MainSLI",
                visible: true
            },
            {
                path: "/buttons",
                name: "Skip Providers",
                rtlName: "وصفت",
                mini: "DS",
                rtlMini: "ب",
                component: Dashboard,
                layout: "/MainSLI",
                visible: true
            },
            {
                path: "/buttons",
                name: "Dispositions",
                rtlName: "وصفت",
                mini: "DD",
                rtlMini: "ب",
                component: Dashboard,
                layout: "/MainSLI",
                visible: true
            }
        ]
    },    
    {
        collapse: true,
        name: "Configurações",
        rtlName: "خرائط",
        icon: "tim-icons icon-settings",
        state: "SettingsCollapse",
        visible: true,
        views: [
            {
                collapse: true,
                name: "File System",
                rtlName: "خرائط",
                mini: "F",
                rtlMini: "ر",
                state: "FileCollapse",
                visible: true,
                views: [
                    {
                        path: "/google-maps",
                        name: "File Configuration",
                        rtlName: "خرائط جوجل",
                        mini: "FC",
                        rtlMini: "زم",
                        component: Dashboard,
                        layout: "/MainSLI",
                        visible: true
                    },
                    {
                        path: "/full-screen-map",
                        name: "File Layouts",
                        rtlName: "خريطة كاملة الشاشة",
                        mini: "FL",
                        rtlMini: "ووم",
                        component: Dashboard,
                        layout: "/MainSLI",
                        visible: true                        
                    }, 
                    {
                        path: "/full-screen-map",
                        name: "File Owners",
                        rtlName: "خريطة كاملة الشاشة",
                        mini: "FSM",
                        rtlMini: "ووم",
                        component: Dashboard,
                        layout: "/MainSLI",
                        visible: true
                    } 

                ]

            },
            {
                collapse: true,
                name: "Sli Configurações",
                rtlName: "خرائط",
                mini: "S",
                rtlMini: "ر",
                state: "SLIConfigurationCollapse",
                visible: true,
                views: [
                    {
                        path: "/google-maps",
                        name: "Phone Counters",
                        rtlName: "خرائط جوجل",
                        mini: "SC",
                        rtlMini: "زم",
                        component: Dashboard,
                        layout: "/MainSLI",
                        visible: true
                    },
                    {
                        path: "/full-screen-map",
                        name: "Phone Flows",
                        rtlName: "خريطة كاملة الشاشة",
                        mini: "SF",
                        rtlMini: "ووم",
                        component: Dashboard,
                        layout: "/MainSLI",
                        visible: true
                    }, 
                    {
                        path: "/full-screen-map",
                        name: "Company Dispositions",
                        rtlName: "خريطة كاملة الشاشة",
                        mini: "SD",
                        rtlMini: "ووم",
                        component: Dashboard,
                        layout: "/MainSLI",
                        visible: true
                    } 

                ]

            },
            {
                collapse: true,
                name: "Users",
                rtlName: "خرائط",
                mini: "U",
                rtlMini: "ر",
                state: "UsersCollapse",
                visible: true,
                views: [
                    {
                        path: "/google-maps",
                        name: "Profile",
                        rtlName: "خرائط جوجل",
                        mini: "UP",
                        rtlMini: "زم",
                        component: Dashboard,
                        layout: "/MainSLI",
                        visible: true
                    },
                    {
                        path: "/full-screen-map",
                        name: "Change Password",
                        rtlName: "خريطة كاملة الشاشة",
                        mini: "UC",
                        rtlMini: "ووم",
                        component: Dashboard,
                        layout: "/MainSLI",
                        visible: true
                    }
                ]

            }
        ]
    }, 
    {
        path: "/login",
        name: "Login",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginSLI,
        layout: "/authSLI",
        visible: false
    },
    {
        path: "/register",
        name: "Register",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: Register,
        layout: "/authSLI",
        visible: false
    },
    {
        path: "/resetpassword",
        name: "ResetPassword",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: ResetPassword,
        layout: "/authSLI",
        visible: false
    },
    {
        path: "/WebFTP",
        name: "WebFTP",
        rtlName: "هعذاتسجيل الدخول",
        mini: "",
        rtlMini: "هعذا",
        component: WebFTP,
        layout: "/MainSLI",
        visible: true
    },
    {
        path: "/Default",
        name: "Default",
        rtlName: "هعذاتسجيل الدخول",
        mini: "DF",
        rtlMini: "هعذا",
        component: DefaultPage,
        layout: "/MainSLI",
        visible: false
    },

];

export default routes;
