import React from 'react';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Row,
    Col,
    FormGroup,
    Form,
    Label
  } from "reactstrap";

import ReactDatetime from "react-datetime";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactTable from "react-table";
import {sliData, sliValidations} from "../../commom/sliHelper"
import { CSVLink } from "react-csv";
import moment from 'moment';
import 'moment/locale/pt-br';
import Loader from 'react-loader-spinner'

import icons from '../../variables/icons';

class CallHistory extends React.Component {
    constructor(props){
        super();
        this.state={
            cpf:"",
            LastServerMessage:"",
            qtyCallsLoaded:0,
            CustomerID: sessionStorage.getItem("current_CustomerID"),
            ProviderID: sessionStorage.getItem("current_ProviderID"),
            ddd:"",
            phoneNumber:"",
            startCallDate:"",
            endCallDate:"",
            data:[],
            dddState:'',
            cpfState:'',
            phoneNumberState:'',
            btnSearchDisabled: false,
            isLoadingPage: false,
            spinner: (<Loader
                type="Rings"
                //Audio, Ball-Triangle, Bars, Circles, Grid, Hearts, Oval, Puff, Rings, TailSpin, ThreeDots
                color="#00BFFF"
                height={960}
                width={"100%"}
                //timeout={3000} //3 secs
        
                />)
        };
        this.ButtonClick = this.ButtonClick.bind(this);
        this.hideAlert = this.hideAlert.bind(this);

    }

    ButtonClick = (ev) =>{
        this.setState({btnSearchDisabled: true})
        if ( (this.state.cpf ==="") && (this.state.phoneNumber ==="" || this.state.ddd==="")){

            this.setState({ LastServerMessage : "Informe ao menos o CPF ou telefone com DDD!" , btnSearchDisabled: false},() => {this.basicAlert('danger')});

        }
        else{
        
            if ( (this.state.cpf !=="") && !( sliValidations.verifyCPF(this.state.cpf) || sliValidations.verifyCNPJ(this.state.cpf)) ) { 
                this.setState({ LastServerMessage : "CPF ou CNPJ inválido!" , btnSearchDisabled: false , isLoadingPage:false },() => {this.basicAlert('danger')});
            }
            else{


                if ((this.state.startCallDate) !=="" && (this.state.startCallDate>this.state.endCallDate))
                {
                    this.setState({ LastServerMessage : "Data inicial maior que a Data Final!" , btnSearchDisabled: false,  isLoadingPage:false },() => {this.basicAlert('danger')});
                }
                else
                {

                    this.setState({isLoadingPage: true});
                    sliData.post('/Skip/GetCallHistory',{
                        CustomerID: parseInt(this.state.CustomerID),
                        ProviderID:parseInt(this.state.ProviderID),
                        // eslint-disable-next-line
                        DocumentNumber: parseFloat(this.state.cpf.replace(/\./g,'').replace(/\,/g,'').replace(/\-/g,'').replace(/\//g,'') ),
                        DDD: parseInt(this.state.ddd),
                        PhoneNumber: parseInt(this.state.phoneNumber),
                        StartCallDate : this.state.startCallDate===""?null:this.state.startCallDate,
                        EndCallDate : this.state.endCallDate===""?null:this.state.endCallDate
                    }).then((result) => {
                        
                        //let jsonData=JSON.parse(result.data.data)
                        //let jsonData=result.data;

                    // for 

                        var finalData=result.data.map((prop, key) => {
                            return {
                            id: key,
                            callID: prop.callID,
                            callDate: moment(prop.callDate).format('DD/MM/YYYY HH:mm:ss'),
                            callSource: prop.callSource,
                            DDD: prop.ddd,
                            documentNumber: prop.documentNumber,
                            number: prop.number,
                            isRPC: prop.isRPC===0?"Não":"Sim",
                            isSuccess: prop.isSuccess===0?"Não":"Sim",
                            isTP: prop.isTP===0?"Não":"Sim",
                            isPromise: prop.isPromise===0?"Não":"Sim",
                            disposition: prop.disposition,
                            customerCallDescription: prop.customerCallDescription,
                            }
                        });

                                              
                        //rowsPerPage

                        this.setState(
                            { LastServerMessage : result.message
                            , data : finalData 
                            , btnSearchDisabled: false 
                            , isLoadingPage:false
                            },() =>{
                                    if (result.status !== 'Success'){
                                        
                                        this.basicAlert('danger');

                                    }
                                    else{

                                        //this.updateRecords();
                                        //this.basicAlert('success');
                                        //this.setState({documentNumber: "",disableButtons:true});                            

                                    }                    
                        })
                    }).catch((error) => {
                        
                        this.setState({ LastServerMessage: error.message, btnSearchDisabled: false , isLoadingPage:false },() => {this.basicAlert('danger')})

                    })
                }
            }
        }
    }

    basicAlert = (btnstyle) => {
        if (btnstyle==='success'){
            this.setState({
            alert: (
                <ReactBSAlert                
                success  
                style={{ display: "block", marginTop: "-100px" }}
                title={this.state.LastServerMessage}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle={btnstyle}
                //["default","primary","link","info","success","warning","danger"]
                btnSize=""
                />
            )
            });
        }
        else{
            this.setState({
                alert: (
                    <ReactBSAlert
                    danger  //Transforma a string em tag html
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.LastServerMessage}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle={btnstyle}
                    //["default","primary","link","info","success","warning","danger"]
                    btnSize=""
                    />
                )
                });

        }
      };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };      

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toString().toLowerCase()).startsWith(filter.value.toLowerCase())
                :
                true
        );
    }
    
    componentDidMount() {
        document.body.classList.toggle("BradescoTab-page");
    }

    componentDidUpdate(prevState){
        
        if (this.state.CustomerID!== sessionStorage.getItem("current_CustomerID") || (this.state.ProviderID!== sessionStorage.getItem("current_ProviderID")) ){
            this.setState({
                CustomerID: sessionStorage.getItem("current_CustomerID"),
                ProviderID: sessionStorage.getItem("current_ProviderID"),
                data:[]
            });
        }
                
    }

    render(){
        let {
            btnSearchDisabled,
            cpf,            
            alert,
            ddd,
            phoneNumber,
            data,
            isLoadingPage,
            spinner,
        } = this.state;

        return (

            <div className="content">
                {alert}
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Informe os dados para pesquisa: mínimo cpf ou telefone com DDD</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Col sm="5">
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h4"></CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Form action="/" className="form-horizontal" method="get">
                                                <Row>                                                    
                                                    <Label sm="2">CPF</Label>
                                                    <Col sm="10">
                                                        <FormGroup className={`has-label ${this.state.cpfState}`}>
                                                            <Input
                                                                name="cpf"
                                                                type="text"
                                                                value = {cpf}
                                                                maxLength="15"
                                                                onChange={e => {
                                                                    if (sliValidations.verifyPattern(e.target.value, /^[0-9\b]+$/) || e.target.value==="")
                                                                    {
                                                                        sliValidations.fieldChange(this, e, "cpf", "cpf")   
                                                                        if (e.target.value==="")
                                                                        {
                                                                            this.setState({ cpfState: "has-success" })
                                                                        }                                                                 
                                                                    }
                                                                }}
                                                            />
                                                            {this.state.cpfState === "has-danger" ? (
                                                                <label className="error">
                                                                    CPF/CNPJ inválido.
                                                                </label>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                    
                                                </Row>
                                                <Row>
                                                    <Label sm="2">DDD</Label>
                                                    <Col sm="6">
                                                        <FormGroup className={`has-label ${this.state.dddState}`}>
                                                            <Input
                                                                name="DDD"
                                                                type="text"
                                                                value = {ddd}
                                                                maxLength="2"
                                                                onChange={e => {
                                                                    if (sliValidations.verifyPattern(e.target.value, /^[0-9\b]+$/) || e.target.value==="")
                                                                    {
                                                                        sliValidations.fieldChange(this, e, "ddd", "ddd")
                                                                        if (e.target.value==="")
                                                                        {
                                                                            this.setState({ dddState: "has-success" })
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            {this.state.dddState === "has-danger" ? (
                                                                <label className="error">
                                                                    DDD inválido.
                                                                </label>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                    
                                                </Row>
                                                <Row>
                                                    <Label sm="2">Telefone</Label>
                                                    <Col sm="8">
                                                        <FormGroup className={`has-label ${this.state.phoneNumberState}`}>
                                                            <Input
                                                                name="phoneNumber"
                                                                type="text"
                                                                value = {phoneNumber}
                                                                maxLength="9"
                                                                onChange={e => 
                                                                    {
                                                                        if (sliValidations.verifyPattern(e.target.value, /^[0-9\b]+$/) || e.target.value==="")
                                                                        {
                                                                            sliValidations.fieldChange(this, e, "phoneNumber", "length",8)
                                                                            if (e.target.value==="")
                                                                            {
                                                                                this.setState({ phoneNumberState: "has-success" })
                                                                            }

                                                                        }
                                                                        
                                                                    }
                                                                }
                                                            />           
                                                            {this.state.phoneNumberState === "has-danger" ? (
                                                                <label className="error">
                                                                    Telefone inválido.
                                                                </label>
                                                            ) : null}                                 
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Label sm="2">Data Inicial</Label>
                                                    <Col sm="10">
                                                        <FormGroup>
                                                            <ReactDatetime
                                                                inputProps={{
                                                                    className: "form-control",
                                                                    placeholder: "Datetime Picker Here"
                                                                }}
                                                                onChange={e => 
                                                                    {
                                                                        this.setState({startCallDate: e._d})
                                                                        //sliValidations.fieldChange(this, e, "endDate")
                                                                        //sliValidations.fieldChange(this, e, "ddd", "length",2)
                                                                    }
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>                              
                                                    <Label sm="2">Data Final</Label>          
                                                    <Col sm="10">
                                                        <FormGroup>
                                                            <ReactDatetime
                                                                inputProps={{
                                                                    className: "form-control",
                                                                    placeholder: "Datetime Picker Here"
                                                                    
                                                                }}
                                                                onChange={e => 
                                                                    {
                                                                        this.setState({endCallDate: e._d})
                                                                        //sliValidations.fieldChange(this, e, "endDate")
                                                                        //sliValidations.fieldChange(this, e, "ddd", "length",2)
                                                                    }
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="ml-auto" md="3">
                                                        {/* </Row><Button disabled={btnloginDisabled} color="primary" onClick={this.loginClick}> */}
                                                        <Button className="float-right" size="sm" disabled={btnSearchDisabled} color="primary" onClick={e =>this.ButtonClick(e)}>
                                                            Pesquisar
                                                        </Button>
                                                    </Col>

                                                </Row>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>                                
                <Row className="mt-5">
                    <Col xs={12} md={12}>
                        <Card>
                            <CardHeader>                                
                                <CardTitle tag="h4">Resultado da pesquisa</CardTitle>
                                {
                                    this.state.data.length!==0?                                       
                                       (<CSVLink data={data}><i className={"tim-icons " + icons[5].name} />Baixar Pesquisa em .csv</CSVLink>)                                       
                                    :
                                    null
                                }

                            </CardHeader>
                            {isLoadingPage
                                ?spinner
                                :
                                (
                                    <CardBody>
                                        <ReactTable
                                            data={data}
                                            filterable
                                            resizable={false}
                                            columns={[
                                            {
                                                Header: "CPF/CNPJ",
                                                accessor: "documentNumber",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },
                                            {
                                                Header: "ID Chamada",
                                                accessor: "callID",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },
                                            {
                                                Header: "Data Ligação",
                                                accessor: "callDate",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },
                                            {
                                                Header: "Origem",
                                                accessor: "callSource",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },
                                            {
                                                Header: "DDD",
                                                accessor: "DDD",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )

                                            },
                                            {
                                                Header: "Telefone",
                                                accessor: "number",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )

                                            },
                                            {
                                                Header: "Tabulação SLI",
                                                accessor: "disposition",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )

                                            },
                                            {
                                                Header: "Tabulação Cliente",
                                                accessor: "customerCallDescription",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )

                                            },                                    
                                            {
                                                Header: "Conectou?",
                                                accessor: "isSuccess",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )

                                            },
                                            {
                                                Header: "CPC",
                                                accessor: "isRPC",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )

                                            },
                                            {
                                                Header: "Promessa",
                                                accessor: "isPromise",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )

                                            },
                                            {
                                                Header: "Recado",
                                                accessor: "isTP",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )

                                            },
                                            // {
                                            //     Header: "Actions",
                                            //     accessor: "actions",
                                            //     sortable: false,
                                            //     filterable: false
                                            // }
                                            ]}
                                            defaultPageSize={5}
                                            pageSize={(data.length > 20) ? 20 : data.length}
                                            showPaginationTop
                                            showPaginationBottom={false}
                                            className="-striped -highlight"
                                            showPageSizeOptions= {false}
                                            defaultFilterMethod={this.filterCaseInsensitive}
                                        />
                                    </CardBody>
                                )
                            }
                        </Card>
                    </Col>
         
                </Row>
                
            </div>

        )

    }

}
export default CallHistory;
