/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import ReactBSAlert from "react-bootstrap-sweetalert";

import {sliValidations, sliData} from "../../commom/sliHelper"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  FormGroup,
  Container,
  Col,
  
} from "reactstrap";

class LoginSLI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginEmail: "",
            loginPassword: "",
            loginEmailState: "",
            loginPasswordState: "",
            LastServerMessage: "",
            btnloginDisabled: false
        }

        this.loginClick = this.loginClick.bind(this);
        this.basicAlert = this.basicAlert.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.inputAlert = this.inputAlert.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.inputConfirmAlertNext = this.inputConfirmAlertNext.bind(this);
    }

    loginClick = () => {

       this.setState({btnloginDisabled: true})

        if ((this.state.loginFullName === "") || (this.state.loginPassword === "")) {

            if (this.state.loginEmail === "") {
                this.setState({ loginEmailState: "has-danger" });
            }
            if (this.state.loginPassword === "") {
                this.setState({ loginPasswordState: "has-danger" });
            }
            
            this.setState({btnloginDisabled: false});

        }
        else {

            sliData.post('/Users/Login',{
                username: this.state.loginEmail,
                Password: this.state.loginPassword
            }).then((result) => {
                if (result.status === 'Invalid'){
                    this.setState({ LastServerMessage : result.message })
                    this.basicAlert();

                    this.setState({btnloginDisabled: false});

                }
                else{
                    sessionStorage.setItem('id_token', result.data.token)
                    
                    document.body.classList.remove("login-page");
                    document.body.classList.remove("pricing-page");
                    document.body.classList.remove("register-page");
                    document.body.classList.remove("lock-page");

                    if (result.data.defaultPage!==null){
                        this.props.history.push(result.data.defaultPage);
                    }else {
                        this.props.history.push("/MainSLI/default");
                    }

                }                    
            }).catch((error) => {
                this.setState({ LastServerMessage: error.message})
                this.basicAlert("danger");
                
                this.setState({btnloginDisabled: false});

            })
           

        }

    };

    resetPassword = () => {

        sliData.post('/Users/EmailtoResetPassword', {
            username: this.state.loginEmail
        }).then((result) => {

        }).catch((error) => {
            this.setState({ LastServerMessage: error.message})
            this.basicAlert("danger");
        })        

    }

    inputAlert = () => {
        this.setState({
            alert: (
            <ReactBSAlert
                input
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title="informe seu email"
                onConfirm={e => this.inputConfirmAlert(e)}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                btnSize=""
            />
            )
        });
    };
    inputConfirmAlert = e => {
        this.setState({ alert: e });
        setTimeout(this.inputConfirmAlertNext, 200);
    };
    inputConfirmAlertNext = () => {
        const inputValue = this.state.alert;
        this.setState({ loginEmail: inputValue});

        this.resetPassword();

        this.setState({
            alert: (
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="success"
                btnSize=""
                title="Email enviado para: "
            >
                <b>{inputValue}</b>
            </ReactBSAlert>
            )
        });
    };

    basicAlert = () => {
        this.setState({
          alert: (
            <ReactBSAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title={this.state.LastServerMessage}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="danger"
              //["default","primary","link","info","success","warning","danger"]
              btnSize=""
            />
          )
        });
      };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    componentDidMount() {
        document.body.classList.toggle("login-page");
    }   

    render() {
        // taking all the states
        let {
            
            loginEmailState,
            loginPasswordState,
            btnloginDisabled,

        } = this.state;
        return (
            <>
                <div className="content">
                    {this.state.alert}
                    <Container>
                        <Col className="ml-auto mr-auto" lg="4" md="6">
                            <Form className="LoginForm">
                                <Card className="card-login card-white">    
                                    <CardHeader>                                        
                                        <img
                                            alt="..."
                                            src={require("assets/img/card-primary.png")}
                                        />
                                        <CardTitle tag="h1">Login</CardTitle>
                                    </CardHeader>
                                    <CardBody>                                        
                                        <FormGroup className={`has-label ${loginEmailState}`}>
                                            <label>Email Address *</label>
                                            <Input
                                                name="email"
                                                type="email"
                                                onChange={e => sliValidations.fieldChange(this, e, "loginEmail", "email")}
                                            />
                                            {this.state.loginEmailState === "has-danger" ? (
                                                <label className="error">
                                                    Please enter a valid email address.
                                                </label>
                                            ) : null}
                                        </FormGroup>
                                        <FormGroup className={`has-label ${loginPasswordState}`}>
                                            <label>Password *</label>
                                            <Input
                                                name="password"
                                                type="password"
                                                autoComplete="off"
                                                onChange={e => {
                                                    sliValidations.fieldChange(this, e, "loginPassword", "password")

                                                }
                                                }
                                            />
                                            {this.state.loginPasswordState === "has-danger" ? (
                                                <label className="error">This field is required.</label>
                                            ) : null}                                            
                                        </FormGroup>
                                        <div className="text-primary">
                                            * Required fields                                                                              
                                        </div>                                        
                                    </CardBody>
                                    <CardFooter className="text-left">
                                        <Button disabled={btnloginDisabled} color="primary" onClick={this.loginClick}>
                                            Login
                                        </Button>
                                        <a
                                            href="#resetPassword"
                                            className="pull-right"
                                            onClick={this.inputAlert}
                                        >
                                            Forgot password?
                                        </a>
                                    </CardFooter>
                                </Card>

                            </Form>
                        </Col>
                    </Container>
                </div>
            </>
        );
    }
}

export default LoginSLI;
