import React from 'react';
// reactstrap components
import {
    Row,
    Col
  } from "reactstrap";

  
import logo from "../../../assets/img/SLIlogo_Icon_transparente.png";

class DefaultPage extends React.Component {
    constructor(props){
        super(props);
        this.state={
        };

    }
 

    componentDidMount() {
        document.body.classList.toggle("Default-page");
    }

  
    render(){

        return (

            <div className="content opacity: 0.5" >
                <Row className="justify-content-md-center">
                    <Col xs="12" sm="8" md="4">
                        <img src={logo} style={{opacity: 0.1}} alt="react-logo" /> 
                    </Col>
                </Row>
            </div>

        )

    }

}
export default DefaultPage;
