/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// react plugin used to create charts
//import { Bar } from "react-chartjs-2";
//import { Line, Bar } from "react-chartjs-2";
// react plugin for creating vector maps
// import { VectorMap } from "react-jvectormap";

// reactstrap components
import {
  //Table,
  Row,
  Col,
  Card,
  FormGroup,
  Label,
  Input,
  CardHeader,
  CardBody, 
  CardTitle,
} from "reactstrap";

import ReactBSAlert from "react-bootstrap-sweetalert";
//import moment from 'moment';

import ReactTable from "react-table";
import { CSVLink } from "react-csv";
import NumberFormat from 'react-number-format';

import {sliData} from "../commom/sliHelper"
import Loader from 'react-loader-spinner'

import icons from '../variables/icons';
//import { redraw } from "plotly.js";

import { FcBullish , FcBearish} from "react-icons/fc";

import{ Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import ChartOperator from "./components/ChartOperator";

class DashboardCollectorsKPI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            CustomerID: sessionStorage.getItem("current_CustomerID"),
            ProviderID: sessionStorage.getItem("current_ProviderID"),
            GroupTestSegmentationID: sessionStorage.getItem("current_GroupTestSegmentationID"),
            isLoading: true,           
            radioType: "4",
            radioMonthType: "currentMonth",
            data:[],
            isLoadingPage: false,
            modalOperator: false,
            spinner: (<Loader
                type="Rings"
                //Audio, Ball-Triangle, Bars, Circles, Grid, Hearts, Oval, Puff, Rings, TailSpin, ThreeDots
                color="#00BFFF"
                height={960}
                width={"100%"}
                //timeout={3000} //3 secs
        
                />)

        };
       
    }


    componentDidMount() {       
            
        this.loadData(this.state.CustomerID, this.state.ProviderID, this.state.GroupTestSegmentationID,4);
        //document.body.classList.remove.toggle("login-page");
        document.body.classList.toggle("DashBoard-Page");
        //document.documentElement.classList.remove("rtl-active");
    }

    componentDidUpdate(prevProps){
        if (this.state.CustomerID!== sessionStorage.getItem("current_CustomerID") 
            || (this.state.ProviderID!== sessionStorage.getItem("current_ProviderID")) 
            || (this.state.GroupTestSegmentationID!== sessionStorage.getItem("current_GroupTestSegmentationID")))
        {
            this.loadData(sessionStorage.getItem("current_CustomerID"), sessionStorage.getItem("current_ProviderID"), sessionStorage.getItem("current_GroupTestSegmentationID"), 4 );
            this.setState({
                CustomerID: sessionStorage.getItem("current_CustomerID"),
                ProviderID: sessionStorage.getItem("current_ProviderID"),
                GroupTestSegmentationID: sessionStorage.getItem("current_GroupTestSegmentationID")
            });
        }
    }

    basicAlert = (btnstyle) => {
        if (btnstyle==='success'){
            this.setState({
            alert: (
                <ReactBSAlert                
                success  
                style={{ display: "block", marginTop: "-100px" }}
                title={this.state.LastServerMessage}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle={btnstyle}
                //["default","primary","link","info","success","warning","danger"]
                btnSize=""
                />
            )
            });
        }
        else{
            this.setState({
                alert: (
                    <ReactBSAlert
                    danger  //Transforma a string em tag html
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.LastServerMessage}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle={btnstyle}
                    //["default","primary","link","info","success","warning","danger"]
                    btnSize=""
                    />
                )
                });
    
        }
    };    

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };  

    onRadioValueChange = (event) => {
        this.loadData(this.state.CustomerID, this.state.ProviderID, this.state.GroupTestSegmentationID, event.target.value)
        this.setState({
            radioType : event.target.value
          });

    }

    onRadioMonthValueChange = (event) => {
        this.loadData(this.state.CustomerID, this.state.ProviderID, this.state.GroupTestSegmentationID, this.state.radioType)
        this.setState({
            radioMonthType : event.target.value
          });

    }


    loadData = (customerID, providerID, groupTestSegmentationID, quartile) =>
    {

        this.setState({ isLoading : true, isLoadingPage: true})

        if (customerID !==null && providerID !==null && groupTestSegmentationID !==null) {
            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(customerID)
                , ProviderID: parseInt(providerID)
                , GroupTestSegmentationID: parseInt(groupTestSegmentationID)
                , Graphname: 'CollectorsKPI'
            }
            ).then((result) => {


                if (result.status === 'Invalid'){               
                    this.setState({ LastServerMessage : result.message })
                    this.basicAlert();
                }
                else{


                    //convertendo dados para o formato que a tabela preicisa
                    let finalData=JSON.parse(result.data.data);

                    if (finalData!==null)
                    {
                        finalData=finalData.filter(e=> e.FinalQuartile===parseInt(quartile))
                        
                        if (this.state.radioMonthType==="currentMonth")
                        {
                            finalData=finalData.filter(e=> e.CurrentMonthQuartile!==0)
                        }

                        finalData=finalData.map((prop, key) => {                              
                            return {
                                id: key,
                                username: prop.Username,
                                fullName: prop.FullName,
                                finalQuartile: prop.FinalQuartile,
                                dstRPC1: prop.DstRPC1,
                                dstPromise1: prop.DstPromise1,
                                prmToRPC1: prop.PrmToRPC1,
                                last5DaysQuartile: prop.Last5DaysQuartile,
                                dstRPC2: prop.DstRPC2,
                                dstPromise2: prop.DstPromise2,
                                prmToRPC2: prop.PrmToRPC2,
                                currentMonthQuartile: prop.CurrentMonthQuartile,
                                dstRPC3: prop.DstRPC3,
                                dstPromise3: prop.DstPromise3,
                                prmToRPC3: prop.PrmToRPC3,
                                lastMonthQuartile: prop.LastMonthQuartile,
                                totalPoints: prop.TotalPoints,
                                isOutLier: prop.IsOutLier===1?"Sim":"Não",
                                finalPrmToRPC: (prop.FinalPrmToRPC*100).toFixed(2)*1.00,
                                workedDays: prop.WorkedDays,
                                direction:
                                (
                                    <div>
                                    {                                    
                                        prop.Last5DaysQuartile>prop.FinalQuartile && prop.Last5DaysQuartile!==0? (<FcBearish/>):   prop.Last5DaysQuartile<prop.FinalQuartile && prop.Last5DaysQuartile!==0? (<FcBullish />) : null                                                                        
                                    }
                                    </div>

                                )
                            }
                        });
                    }
                    else{
                        finalData=[];
                    }
                    this.setState({ data : finalData , isLoading: false , isLoadingPage: false});
                }         

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })
        }

    }


    onRowClick = (state, rowInfo, column, instance) =>
    {

        return {
            onClick: e => {

                this.setState({CollectorUserName: rowInfo.row._original.username, CollectorFullName: rowInfo.row._original.fullName},()=>{
                    this.toggleModalOperator();
                })
                
/*
                console.log('A Td Element was clicked!')
                console.log('it produced this event:', e)
                console.log('It was in this column:', column)
                console.log('It was in this row:', rowInfo)
                console.log('It was in this table instance:', instance)
                */
            }
        }

    }

    toggleModalOperator = () => {
        this.setState({
            modalOperator: !this.state.modalOperator
        });
    }

    render() {
    let{
        alert,   
        radioType, 
        radioMonthType,
        data,
        isLoadingPage,
        spinner
    } = this.state
    return (
      <>
        <div className="content">
            {alert}
            <Modal isOpen={this.state.modalOperator} toggle={this.toggleModalOperator} modalClassName="modal-black" size="lg">
                <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggleModalOperator}>
                    <i className="tim-icons icon-simple-remove"></i>
                </button>
                <h5 className="modal-title">(%) Conversão do Operador - {this.state.CollectorUserName} - {this.state.CollectorFullName}</h5>
                </div>
                <ModalBody>
                    <ChartOperator 
                        CustomerID={this.state.CustomerID} 
                        ProviderID={this.state.ProviderID} 
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        CollectorUserName={this.state.CollectorUserName}
                        CollectorFullName={this.state.CollectorFullName}
                    >                        
                    </ChartOperator>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.toggleModalOperator}>
                        Fechar
                    </Button>
                </ModalFooter>
            </Modal>
            <Row>
                <Col lg="12">
                    <Card>
                        <FormGroup check className="form-check-radio">
                            <Label check>
                                <Input
                                    // defaultChecked
                                    // defaultValue="simpleSum"
                                    id="q4"
                                    name="graphType"
                                    type="radio"
                                    value="4"
                                    checked={radioType==="4"}
                                    onChange={this.onRadioValueChange}
                                />
                                <span className="form-check-sign" />
                                4 Quartil
                            </Label>    
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            <Label check>
                                <Input
                                    //defaultValue="acumulative"
                                    id="q3"
                                    name="graphType"
                                    type="radio"
                                    value="3"
                                    checked={radioType==="3"}
                                    onChange={this.onRadioValueChange}
                                />
                                <span className="form-check-sign" />
                                3 Quartil
                            </Label>
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            <Label check>
                                <Input
                                    //defaultValue="acumulative"
                                    id="q2"
                                    name="graphType"
                                    type="radio"
                                    value="2"
                                    checked={radioType==="2"}
                                    onChange={this.onRadioValueChange}
                                />
                                <span className="form-check-sign" />
                                2 Quartil
                            </Label>
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            <Label check>
                                <Input
                                    //defaultValue="acumulative"
                                    id="q1"
                                    name="graphType"
                                    type="radio"
                                    value="1"
                                    checked={radioType==="1"}
                                    onChange={this.onRadioValueChange}
                                />
                                <span className="form-check-sign" />
                                1 Quartil
                            </Label>

                        </FormGroup>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <Card>
                        <FormGroup check className="form-check-radio">
                            <Label check>
                                <Input
                                    // defaultChecked
                                    // defaultValue="simpleSum"
                                    id="m1"
                                    name="graphMonth"
                                    type="radio"
                                    value="currentMonth"
                                    checked={radioMonthType==="currentMonth"}
                                    onChange={this.onRadioMonthValueChange}
                                />
                                <span className="form-check-sign" />
                                Operadores ativos Mês Atual
                            </Label>    
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            <Label check>
                                <Input
                                    //defaultValue="acumulative"
                                    id="m2"
                                    name="graphMonth"
                                    type="radio"
                                    value="lastMonth"
                                    checked={radioMonthType==="lastMonth"}
                                    onChange={this.onRadioMonthValueChange}
                                />
                                <span className="form-check-sign" />
                                Todos os dados
                            </Label>                            

                        </FormGroup>
                    </Card>
                </Col>
            </Row>
            <Row>

                <Col xs={12} md={12}>
                    <Card>
                        <CardHeader>                                
                            <CardTitle tag="h4">Quadro de Funcionários</CardTitle>
                            {
                                this.state.data.length!==0?                                       
                                    (<CSVLink 
                                            data={data} 
                                            filename={'Operadores.csv'}>
                                            <i 
                                                className={"tim-icons " + icons[5].name} 
                                            />
                                        Baixar visão em .csv
                                     </CSVLink>)
                                :
                                null
                            }

                        </CardHeader>
                        {isLoadingPage
                            ?spinner
                            :
                            (
                                <CardBody>
                                    <ReactTable
                                        data={data}
                                        filterable
                                        resizable={false}
                                        getTrProps={this.onRowClick}
                                        columns={[
                                        {
                                            Header: "Tendência",
                                            accessor: "direction",
                                            headerStyle: {textAlign: 'center'},
                                            Cell: row => (
                                                <div
                                                    style={{
                                                    textAlign: 'center'
                                                    }}
                                                >{row.value}</div>
                                            )
                                        },
                                        {
                                            Header: "Usuário",
                                            accessor: "username",
                                            headerStyle: {textAlign: 'center'},
                                            Cell: row => (
                                                <div
                                                    style={{
                                                    textAlign: 'center'
                                                    }}
                                                >{row.value}</div>
                                            )
                                        },
                                        {
                                            Header: "Nome Operador",
                                            accessor: "fullName",
                                            headerStyle: {textAlign: 'center'},
                                            Cell: row => (
                                                <div
                                                    style={{
                                                    textAlign: 'center'
                                                    }}
                                                >{row.value}</div>
                                            )
                                        },
                                        {
                                            Header: "Q. SLI",
                                            accessor: "finalQuartile",
                                            headerStyle: {textAlign: 'center'},
                                            Cell: row => (
                                                <div
                                                    style={{
                                                    textAlign: 'center'
                                                    }}
                                                >{row.value}</div>
                                            )
                                        },
                                        {
                                            Header: "(%)-Prom/CPC",
                                            accessor: "finalPrmToRPC",
                                            headerStyle: {textAlign: 'center'},
                                            Cell: row => (
                                                <div
                                                    style={{
                                                    textAlign: 'center'
                                                    }}
                                                > <NumberFormat value={row.value} displayType={'text'} thousandSeparator={true} /></div>
                                            )
                                        },                                        
                                        {
                                            Header: "Pontos",
                                            accessor: "totalPoints",
                                            headerStyle: {textAlign: 'center'},
                                            Cell: row => (
                                                <div
                                                    style={{
                                                    textAlign: 'center'
                                                    }}
                                                    > <NumberFormat value={row.value} displayType={'text'} thousandSeparator={true} /></div>
                                            )
                                        },
                                        {
                                            Header: "Qtd Dias",
                                            accessor: "workedDays",
                                            headerStyle: {textAlign: 'center'},
                                            Cell: row => (
                                                <div
                                                    style={{
                                                    textAlign: 'center'
                                                    }}
                                                >{row.value}</div>
                                            )
                                        },
                                        {
                                            Header: "Q. últ. 5 dias",
                                            accessor: "last5DaysQuartile",
                                            headerStyle: {textAlign: 'center'},
                                            Cell: row => (
                                                <div
                                                    style={{
                                                    textAlign: 'center'
                                                    }}
                                                >{row.value}</div>
                                            )
                                        },                                       
                                        {
                                            Header: "Q. Mês Atual",
                                            accessor: "currentMonthQuartile",
                                            headerStyle: {textAlign: 'center'},
                                            Cell: row => (
                                                <div
                                                    style={{
                                                    textAlign: 'center'
                                                    }}
                                                >{row.value}</div>
                                            )
                                        },
                                        {
                                            Header: "Q. Ult. Mês",
                                            accessor: "lastMonthQuartile",
                                            headerStyle: {textAlign: 'center'},
                                            Cell: row => (
                                                <div
                                                    style={{
                                                    textAlign: 'center'
                                                    }}
                                                >{row.value}</div>
                                            )
                                        },
                                        {
                                            Header: "Fora da Curva",
                                            accessor: "isOutLier",
                                            headerStyle: {textAlign: 'center'},
                                            Cell: row => (
                                                <div
                                                    style={ row.value==="Não"?                                                        
                                                                {
                                                                    textAlign: 'center',
                                                    
                                                                }
                                                            :
                                                            {
                                                                textAlign: 'center',
                                                                backgroundColor: '#ff6666'
                                                            }
                                                    }
                                                >{row.value}</div>
                                            )
                                        },
                                        // {
                                        //     Header: "Qtd Confirmada",
                                        //     accessor: "smsQtyConfirmed",
                                        //     headerStyle: {textAlign: 'center'},
                                        //     Cell: row => (
                                        //         <div
                                        //             style={{
                                        //             textAlign: 'center'
                                        //             }}
                                        //         >{row.value}</div>
                                        //     )
                                        // },
                                        // {
                                        //     Header: "Actions",
                                        //     accessor: "actions",
                                        //     sortable: false,
                                        //     filterable: false
                                        // }
                                        ]}
                                        defaultPageSize={5}
                                        pageSize={data.length}
                                        showPaginationTop={false}
                                        showPaginationBottom={false}
                                        className="-striped -highlight"
                                        showPageSizeOptions= {false}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                    />
                                </CardBody>
                            )
                        }
                    </Card>
                </Col>
             
            </Row> 

        </div>
      </>
    );
  }
}

export default DashboardCollectorsKPI;
