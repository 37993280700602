/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// react plugin used to create charts
//import { Bar } from "react-chartjs-2";
//import { Line, Bar } from "react-chartjs-2";
// react plugin for creating vector maps
// import { VectorMap } from "react-jvectormap";

// reactstrap components
import {
  //Table,
  Row,
  Col,
  Card,
  FormGroup,
  Label,
  Input,
} from "reactstrap";


//import {sliData} from "../commom/sliHelper"
import ReactBSAlert from "react-bootstrap-sweetalert";

import ChartMonthsKPI from "./components/kpi/ChartMonthsKPI.jsx"



// var mapData = {
//   AU: 760,
//   BR: 550,
//   CA: 120,
//   DE: 1300,
//   FR: 540,
//   GB: 690,
//   GE: 200,
//   IN: 200,
//   RO: 600,
//   RU: 300,
//   US: 2920
// };

class DashboardKpi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bigChartData: "data1",
            CustomerID: sessionStorage.getItem("current_CustomerID"),
            ProviderID: sessionStorage.getItem("current_ProviderID"),
            GroupTestSegmentationID: sessionStorage.getItem("current_GroupTestSegmentationID"),
            accounts: {Qty:0, LastDate:0},
            promise: {Qty:0, LastDate:0},
            hot: {Qty:0, LastDate:0},
            collectorLasHour: {Qty:0, LastDate:0},
            AttemptsandCallsView: {},
            CallsLastDayView: {},
            CallsLast15DaysView: {},
            radioType: "simpleSum"

        };
       
    }


    componentDidMount() {       
            
       // this.LoadData(this.state.CustomerID, this.state.ProviderID);

    }

    componentDidUpdate(prevProps){
        if (this.state.CustomerID!== sessionStorage.getItem("current_CustomerID") 
            || (this.state.ProviderID!== sessionStorage.getItem("current_ProviderID"))
            || (this.state.GroupTestSegmentationID!== sessionStorage.getItem("current_GroupTestSegmentationID")) )
        {
            this.setState({
                CustomerID: sessionStorage.getItem("current_CustomerID"),
                ProviderID: sessionStorage.getItem("current_ProviderID"),
                GroupTestSegmentationID: sessionStorage.getItem("current_GroupTestSegmentationID"),             
            });
        }
    }

    basicAlert = (btnstyle) => {
        if (btnstyle==='success'){
            this.setState({
            alert: (
                <ReactBSAlert                
                success  
                style={{ display: "block", marginTop: "-100px" }}
                title={this.state.LastServerMessage}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle={btnstyle}
                //["default","primary","link","info","success","warning","danger"]
                btnSize=""
                />
            )
            });
        }
        else{
            this.setState({
                alert: (
                    <ReactBSAlert
                    danger  //Transforma a string em tag html
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.LastServerMessage}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle={btnstyle}
                    //["default","primary","link","info","success","warning","danger"]
                    btnSize=""
                    />
                )
                });
    
        }
    };    

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };  

    onRadioValueChange = (event) => {
        this.setState({
            radioType : event.target.value
          });

    }

    render() {
    let{
        alert,   
        radioType, 
    } = this.state
    return (
      <>
        <div className="content">
            {alert}   
            <Row>
                <Col lg="12">
                    <Card>
                        <FormGroup check className="form-check-radio">
                            <Label check>
                            <Input
                                // defaultChecked
                                // defaultValue="simpleSum"
                                id="radioSum"
                                name="graphType"
                                type="radio"
                                value="simpleSum"
                                checked={radioType==="simpleSum"}
                                onChange={this.onRadioValueChange}
                            />
                            <span className="form-check-sign" />
                            Soma Simples
                            </Label>    
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            <Label check>
                            <Input
                                //defaultValue="acumulative"
                                id="radioAcumulative"
                                name="graphType"
                                type="radio"
                                value="acumulative"
                                checked={radioType==="acumulative"}
                                onChange={this.onRadioValueChange}
                            />
                            <span className="form-check-sign" />
                            Acumulado
                            </Label>
                        </FormGroup>
                    </Card>
                </Col>
            </Row>
            {/* <Row>
                <Col xs="12">
                    <ChartKPI CustomerID={this.state.CustomerID} ProviderID={this.state.ProviderID} ></ChartKPI>
                </Col>
            </Row>        */}
            <Row>
                <Col xs="12">
                    <ChartMonthsKPI 
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        GraphName={radioType==="simpleSum"?"AllKpi-TentativeBySuccess":"AllKpi-TentativeBySuccess-AC"}
                        Title="(%)-Conexão por Tentativa"
                        SubTitle="Comparação por dia útil"
                        MarketValuePF={radioType==="simpleSum"?'18':'0'}
                        MarketValuePJ={radioType==="simpleSum"?'18':'0'}
                    >
                        
                    </ChartMonthsKPI>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <ChartMonthsKPI 
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        GraphName={radioType==="simpleSum"?"AllKpi-RPCBySuccess":"AllKpi-RPCBySuccess-AC"}
                        Title="(%)-CPC por Conexão"
                        SubTitle="Comparação por dia útil"
                        MarketValuePF={radioType==="simpleSum"?'40':'0'}
                        MarketValuePJ={radioType==="simpleSum"?'40':'0'}
                    >                        
                    </ChartMonthsKPI>
                    
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <ChartMonthsKPI 
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        GraphName={radioType==="simpleSum"?"AllKpi-PromiseByRPC":"AllKpi-PromiseByRPC-AC"}
                        Title="(%)-Promessa por CPC"
                        SubTitle="Comparação por dia útil"
                        MarketValuePF={radioType==="simpleSum"?'12':'0'}
                        MarketValuePJ={radioType==="simpleSum"?'12':'0'}
                    >                        
                    </ChartMonthsKPI>
                    
                </Col>
            </Row>

            <Row>
                <Col lg="6">
                    <ChartMonthsKPI 
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        GraphName={radioType==="simpleSum"?"AllKpi-RpcByInventory":"AllKpi-RpcByInventory-AC"}
                        Title="(%)-CPC por Inventário"
                        SubTitle="Comparação por dia útil"
                        MarketValuePF={radioType==="simpleSum"?'14':'0'}
                        MarketValuePJ={radioType==="simpleSum"?'14':'0'}
                    >
                        
                    </ChartMonthsKPI>
                </Col>
                <Col lg="6">
                    <ChartMonthsKPI 
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        GraphName={radioType==="simpleSum"?"AllKpi-RpcByPhoneHot":"AllKpi-RpcByPhoneHot-AC"}
                        Title="(%)-CPC por Telefone Hot"
                        SubTitle="Comparação por dia útil"
                        MarketValuePF={radioType==="simpleSum"?'40':'0'}
                        MarketValuePJ={radioType==="simpleSum"?'40':'0'}
                    >
                        
                    </ChartMonthsKPI>
                </Col>
            </Row>
            <Row>
                <Col lg="6">
                    <ChartMonthsKPI 
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        GraphName={radioType==="simpleSum"?"AllKpi-RPCbyTentative":"AllKpi-RPCbyTentative-AC"}
                        Title="(#)-CPC a cada 1000 Tentativas"
                        SubTitle="Comparação por dia útil"
                        MarketValuePF={radioType==="simpleSum"?'23':'0'}
                        MarketValuePJ={radioType==="simpleSum"?'23':'0'}
                    >
                        
                    </ChartMonthsKPI>
                </Col>
                <Col lg="6">
                    <ChartMonthsKPI 
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        GraphName={radioType==="simpleSum"?"AllKpi-PromiseByInventory":"AllKpi-PromiseByInventory-AC"}
                        Title="(%)-Promessa por Inventário"
                        SubTitle="Comparação por dia útil"
                    >
                        
                    </ChartMonthsKPI>
                </Col>
            </Row>
            <Row>
                <Col lg="6">
                    <ChartMonthsKPI 
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        GraphName={radioType==="simpleSum"?"AllKpi-TentativeByQty":"AllKpi-TentativeByQty-AC"}
                        Title="(#)-Frequencia de Discagem"
                        SubTitle="Comparação por dia útil"
                        MarketValuePF={radioType==="simpleSum"?'3':'0'}
                        MarketValuePJ={radioType==="simpleSum"?'3':'0'}
                    >
                        
                    </ChartMonthsKPI>
                </Col>
                <Col lg="6">
                    <ChartMonthsKPI 
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        GraphName={radioType==="simpleSum"?"AllKpi-Inventory":"AllKpi-Inventory-AC"}
                        Title="(#)-Inventário"
                        SubTitle="Comparação por dia útil"
                    >
                        
                    </ChartMonthsKPI>
                </Col>
            </Row>
            <Row>
                <Col lg="6">
                    <ChartMonthsKPI 
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        GraphName={radioType==="simpleSum"?"AllKpi-avgCollectors":"AllKpi-avgCollectors-AC"}
                        Title="(#)-Média de Operadores"
                        SubTitle="Comparação por dia útil"
                    >
                        
                    </ChartMonthsKPI>
                </Col>
                <Col lg="6">
                    <ChartMonthsKPI 
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        GraphName={radioType==="simpleSum"?"AllKpi-HoldDrop":"AllKpi-HoldDrop-AC"}
                        Title="(%)-Hold Drop"
                        SubTitle="Comparação por dia útil"
                        MarketValuePF={radioType==="simpleSum"?'5':'0'}
                        MarketValuePJ={radioType==="simpleSum"?'5':'0'}
                    >
                        
                    </ChartMonthsKPI>
                </Col>
            </Row>
        </div>
      </>
    );
  }
}

export default DashboardKpi;
