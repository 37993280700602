import React from 'react';
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
  } from "reactstrap";

import { Line } from "react-chartjs-2";

import {sliData} from "../../commom/sliHelper"

let chartOptions = {
    maintainAspectRatio: false,
    legend: {
        display: true,
        labels: {
            fontColor: 'rgb(217, 217, 217)'
        }
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          type: 'linear',          
          barPercentage: 1.6,
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent"
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 0,
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
        // ,{
        //     type: 'linear',          
        //     barPercentage: 1.6,
        //     display: true,
        //     position: 'right',
        //     label: 'test',
        //     id: 'y-axis-2',
        //     gridLines: {
        //       drawBorder: false,
        //       color: "rgba(29,140,248,0.0)",
        //       zeroLineColor: "transparent"
        //     },
        //     ticks: {
        //       suggestedMin: 60,
        //       suggestedMax: 125,
        //       padding: 20,
        //       fontColor: "#9a9a9a"
        //     }
        //   }
      ],
      xAxes: [
        {
          barPercentage: 1.6,          
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ]
    }
  };



class ChartMultLinesAgreements extends React.Component {
    constructor(props){
        super(props);
        this.state={
            GraphView: {},
            Title: this.props.Title,
            SubTitle:this.props.SubTitle
        }; 

    }   

    SetGraphData = (barLabel,labelData,data1,data2,data3,data4) => {
        var graphFunction = canvas => {
            let ctx = canvas.getContext("2d");
        
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        
            gradientStroke.addColorStop(1, "rgba(255, 204, 128,0.3)");
            gradientStroke.addColorStop(0.4, "rgba(255, 204, 128,0.2)");
            gradientStroke.addColorStop(0, "rgba(255, 204, 128,0.1)"); //blue colors

            let realizado='Realizado';
            let projecaoAVG='Projeção Média';
            let max='Projeção Max';
            let min='Projeção Min';
  
            return {
              labels: labelData,
              datasets: [
                {
                    type: "line",
                    label: realizado,
                    fill: true,
                    hidden: false,
                    backgroundColor: gradientStroke,
                    borderColor: "#ff6600", //"#1f8ef1",
                    borderWidth: 2,
                    borderDash: [],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: "#ff6600",//"#1f8ef1",
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: "#ff6600", //"#1f8ef1","#ff6600"
                    pointBorderWidth: 20,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 2,
                    data: data1,
                    yAxisID: "y-axis-1"
                }
                ,{
                    type: "line",
                    label: projecaoAVG,
                    fill: false,
                    hidden: false,
                    backgroundColor: gradientStroke,
                    borderColor: "#65C57B",
                    borderWidth: 3,
                    borderDash: [5,5],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: "#65C57B",
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: "#65C57B",
                    pointBorderWidth: 20,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 2,
                    data: data2,
                    yAxisID: "y-axis-1"
                }
                ,{
                    type: "line",
                    label: max,
                    fill: false,
                    hidden: false,
                    backgroundColor: gradientStroke,
                    borderColor: "#00664d",
                    borderWidth: 1,
                    borderDash: [5,5],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: "#00664d",
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: "#00664d",
                    pointBorderWidth: 20,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 2,
                    data: data3,
                    yAxisID: "y-axis-1"
                },               
                {
                    type: "line",
                    label: min,
                    fill: false,
                    hidden: false,
                    backgroundColor: gradientStroke,
                    borderColor: "#b3ffec",
                    borderWidth: 1,
                    borderDash: [5,5],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: "#b3ffec",
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: "#b3ffec",
                    pointBorderWidth: 20,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 2,
                    data: data4,
                    yAxisID: "y-axis-1"
                }
              ]
            };
        }


        return graphFunction
    }

    LoadData = () =>{

        if (this.props.CustomerID!==null && this.props.ProviderID!==null && this.props.GroupTestSegmentationID!==null ){
            
            let dataSource;

            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(this.props.CustomerID)
                , ProviderID: parseInt(this.props.ProviderID)
                , GroupTestSegmentationID: parseInt(this.props.GroupTestSegmentationID)
                , StartDate: this.props.ProjectionDate===""?null:this.props.ProjectionDate
                , Graphname: this.props.GraphName
            }).then((result) => {
                if (result.status === 'Success'){
                    if (result.data.data===null){
                
                        this.setState({GraphView: this.SetGraphData("Alias1",[1],[0],[0],[0],[0] ) });

                    }
                    else{
                
                        // t.Type, T.Date, T.Attempts, T.Connects, T.RPC, T.TP, T.Promise , ISNULL(t2.Qty,0) Inventory

                        dataSource=JSON.parse(result.data.data);
                        
                        let Graph1Label=[];
                        let Graph1Data1=[];
                        let Graph1Data2=[];
                        let Graph1Data3=[];
                        let Graph1Data4=[];

                        for (var i in dataSource){
                            Graph1Label.push(dataSource[i].Day);
                            Graph1Data1.push(dataSource[i].ProjectionActual);
                            Graph1Data2.push(dataSource[i].ForeCast);
                            Graph1Data3.push(dataSource[i].ForeCastMax);
                            Graph1Data4.push(dataSource[i].ForeCastMin);
                        }
                       

                        let Graph1ResultView = [];
                        
                        Graph1ResultView=this.SetGraphData("Alias1", Graph1Label, Graph1Data1, Graph1Data2,Graph1Data3,Graph1Data4);
                        
                        this.setState({ GraphView: Graph1ResultView });
                                

                    }

                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })


        }
    }

    initializeGraph = () =>{

        this.setState({GraphView: this.SetGraphData("Alias1",[1], [0],[0], [0],[0])});

    }


    setBgChartData = name => {
        this.setState({
        bigChartData: name
        });
    };

    componentDidMount() {
        this.initializeGraph();
        this.LoadData();
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps){
        
        if ((prevProps.CustomerID !== this.props.CustomerID) 
                || (prevProps.ProviderID !== this.props.ProviderID) 
                || (prevProps.GroupTestSegmentationID !== this.props.GroupTestSegmentationID) 
                || (prevProps.GraphName !== this.props.GraphName) 
                || (prevProps.ProjectionDate !== this.props.ProjectionDate) 
            )
        {
            this.LoadData();
        }
                
    }

   
    render(){
        let {    
            GraphView,
            Title,
            SubTitle,
        } = this.state;

        return (

            <div className="content">
                <Row>
                    <Col>
                        <Card className="card-chart">
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="6">
                                        <h5 className="card-category">{SubTitle}</h5>
                                        <CardTitle tag="h3">
                                            <i className="tim-icons icon-shape-star text-danger" />{" "}
                                            {Title}
                                        </CardTitle>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                            <div className="chart-area-double">
                                <Line
                                    data={GraphView}
                                    options={chartOptions}
                                />
                            </div>
                            </CardBody>
                        </Card>
                    </Col>                   
                </Row>
            </div>

        )

    }

}
export default ChartMultLinesAgreements;
