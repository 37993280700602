import React from 'react';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    ButtonGroup,

  } from "reactstrap";

import { Bar } from "react-chartjs-2";

import classNames from "classnames";

import {sliData} from "../../commom/sliHelper"

let chartOptions = 
{
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 120,
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }
      ]
    }
  
}

//let Graph1ViewAbI={};

class ChartAttemptsbyInventory extends React.Component {
    constructor(props){
        super(props);
        this.state={
            GraphView1: {},
            GraphView2: {},
            GraphView3: {},
            bigChartData: "data1"
        }; 

    }

    SetGraphData = (label,data1,data2) => {

        // var graphFunction = canvas => {
        //     let ctx = canvas.getContext("2d");
                        
        //     let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        
        //     gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
        //     gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
        //     gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

        //     return  {
        //         labels: label,
        //         datasets: [
        //             {
        //                 label: "Tentativas",
        //                 fill: true,
        //                 backgroundColor: "#ff8a76",
        //                 hoverBackgroundColor: " #ff8a76",
        //                 borderColor: "#ff8a76",
        //                 borderWidth: 2,
        //                 borderDash: [],
        //                 borderDashOffset: 0.0,
        //                 data: data1
        //             },
        //             {
        //                 label: "Inventário",
        //                 fill: true,
        //                 backgroundColor: "#2782f0",
        //                 hoverBackgroundColor: " #2782f0",
        //                 borderColor: "#2782f0",
        //                 borderWidth: 2,
        //                 borderDash: [],
        //                 borderDashOffset: 0.0,
        //                 data: data2
        //             }
        //         ]
        //     }
        // }

        var graphFunction = {
            labels: label,
            datasets: [
              {
                label: "Tentativas",
                fill: true,
                backgroundColor: "#ff8a76",
                hoverBackgroundColor: " #ff8a76",
                borderColor: "#ff8a76",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: data1
              },
              {
                label: "Inventário",
                fill: true,
                backgroundColor: "#2782f0",
                hoverBackgroundColor: " #2782f0",
                borderColor: "#2782f0",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: data2

              }
            ]
        }


        return graphFunction
    }

    LoadData = () =>{

        /*
            Inventário Total
        */
        if (this.props.CustomerID!==null && this.props.ProviderID!==null && this.props.GroupTestSegmentationID!==null){
                        
            /*
                Ligações por score 15 ultimos dias
            */
         //   this.setState({GraphView1: {}, GraphView2: {}, GraphView3:{} });

            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(this.props.CustomerID)
                , ProviderID: parseInt(this.props.ProviderID)
                , GroupTestSegmentationID: parseInt(this.props.GroupTestSegmentationID)
                , Graphname: 'graphCallandInventory'
            }).then((result) => {
                if (result.status === 'Success'){
                    if (result.data.data==null){
                
                        this.setState({GraphView1: {}, GraphView2: {}, GraphView3:{} })
                    }
                    else{
                
                
                        let dataSourceAbI=JSON.parse(result.data.data);
                        let Graph1Data1AbI=[];
                        let Graph1Data2AbI=[];
                        let Graph1LabelAbI=[];
                        let Graph2Data1URAAbI=[];
                        let Graph2Data2URAAbI=[];
                        let Graph2LabelURAAbI=[];
                        let Graph3Data1DialerAbI=[];
                        let Graph3Data2DialerAbI=[];
                        let Graph3LabelDialerAbI=[];


                        for (var i in dataSourceAbI){
                            if (dataSourceAbI[i].Type==="Total"){
                                Graph1Data1AbI.push(dataSourceAbI[i].Attempts);
                                Graph1Data2AbI.push(dataSourceAbI[i].Qty);
                                Graph1LabelAbI.push(dataSourceAbI[i].Date);
                            }
                            if (dataSourceAbI[i].Type==="URA"){
                                Graph2Data1URAAbI.push(dataSourceAbI[i].Attempts);
                                Graph2Data2URAAbI.push(dataSourceAbI[i].Qty);
                                Graph2LabelURAAbI.push(dataSourceAbI[i].Date);                                         
                            }
                            if (dataSourceAbI[i].Type==="Dialer"){
                                Graph3Data1DialerAbI.push(dataSourceAbI[i].Attempts);
                                Graph3Data2DialerAbI.push(dataSourceAbI[i].Qty);
                                Graph3LabelDialerAbI.push(dataSourceAbI[i].Date);
                            }
                        }

                        let Graph1ViewAbI = this.SetGraphData(Graph1LabelAbI, Graph1Data1AbI, Graph1Data2AbI);

                        let Graph2ViewAbI = this.SetGraphData(Graph2LabelURAAbI, Graph2Data1URAAbI, Graph2Data2URAAbI);

                        let Graph3ViewAbI = this.SetGraphData(Graph3LabelDialerAbI, Graph3Data1DialerAbI, Graph3Data2DialerAbI);


                        this.setState({GraphView1: Graph1ViewAbI, GraphView2: Graph2ViewAbI ,GraphView3: Graph3ViewAbI},() =>{ 

                           // call back state

                        });
                                

                    }

                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
//                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })


        }
    }

    initializeGraph = () =>{
        this.setState({GraphView1: this.SetGraphData([], [], [])
            , GraphView2: this.SetGraphData([], [], []) 
            ,GraphView3: this.SetGraphData([], [], [])
        });
    }

    setBgChartData = name => {
        this.setState({
            bigChartData: name
        });
    };

    componentDidMount() {
       // document.body.classList.toggle("BradescoTab-page");
        this.initializeGraph();
        this.LoadData();

    }
    

    componentDidUpdate(prevProps){
        
        if (
            (prevProps.CustomerID !== this.props.CustomerID) 
            || (prevProps.ProviderID !== this.props.ProviderID)
            || (prevProps.GroupTestSegmentationID !== this.props.GroupTestSegmentationID)
        )
        {
            this.LoadData();
        }
                
    }

   
    render(){
        let {    
            GraphView1,
            GraphView2,
            GraphView3,
            bigChartData,
        } = this.state;

        return (

            <div className="content">
                <Card className="card-chart">
                    <CardHeader>
                        <Row>
                            <Col className="text-left" sm="6">
                                <h5 className="card-category">Últimos 15 dias corridos</h5>
                                <CardTitle tag="h3">
                                    <i className="tim-icons icon-shape-star text-danger" />{" "}
                                    (#)-Tentativas x Inventário
                                </CardTitle>
                            </Col>
                            <Col sm="6">
                                <ButtonGroup
                                    className="btn-group-toggle float-right"
                                    data-toggle="buttons"
                                >
                                    <Button
                                        color="info"
                                        id="0"
                                        size="sm"
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: this.state.bigChartData === "data1"
                                        })}
                                        onClick={() => this.setBgChartData("data1")}
                                        >
                                        <input defaultChecked name="options" type="radio" />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Ura+Dialer
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-single-02" />
                                        </span>
                                    </Button>
                                    <Button
                                        color="info"
                                        id="1"
                                        size="sm"
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: this.state.bigChartData === "data2"
                                        })}
                                        onClick={() => this.setBgChartData("data2")}
                                        >
                                        <input name="options" type="radio" />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Ura
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-bus-front-12" />
                                        </span>
                                    </Button>
                                    <Button
                                        color="info"
                                        id="2"
                                        size="sm"
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: this.state.bigChartData === "data3"
                                        })}
                                        onClick={() => this.setBgChartData("data3")}
                                        >
                                        <input name="options" type="radio" />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Dialer
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-tap-02" />
                                        </span>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                    <div className="chart-area">
                        <Bar
                        data={bigChartData==="data1"?GraphView1:((bigChartData==="data2")?GraphView2:GraphView3)}
                        options={chartOptions}
                        />
                    </div>
                    </CardBody>
                </Card>                          
            </div>

        )

    }

}
export default ChartAttemptsbyInventory;
