import {compare,verifyLength} from './sliUtils';


export const verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
        return true;
    }
    return false;
};

export const verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
        return true;
    }
    return false;
};

// verifies if value is a valid URL
export const verifyUrl = value => {
    try {
        new URL(value);
        return true;
    } catch (_) {
        return false;
    }
};

export const verifyPattern = (value,pattern) => {
    var numberRex = new RegExp(pattern);
    if (numberRex.test(value)) {
        return true;
    }
    return false;
};


// multiple formats (e.g. yyyy/mm/dd or mm-dd-yyyy etc.)
export const tryParseDateFromString = (dateStringCandidateValue, format = "ymd") => {
    if (!dateStringCandidateValue) { return null; }
    let mapFormat = format
            .split("")
            .reduce(function (a, b, i) { a[b] = i; return a;}, {});
    // eslint-disable-next-line            
    const dateStr2Array = dateStringCandidateValue.split(/[ :\-\/]/g);
    const datePart = dateStr2Array.slice(0, 3);
    let datePartFormatted = [
            +datePart[mapFormat.y],
            +datePart[mapFormat.m]-1,
            +datePart[mapFormat.d] ];
    if (dateStr2Array.length > 3) {
        dateStr2Array.slice(3).forEach(t => datePartFormatted.push(+t));
    }
    // test date validity according to given [format]
    const dateTrial = new Date(Date.UTC.apply(null, datePartFormatted));
    return dateTrial && dateTrial.getFullYear() === datePartFormatted[0] &&
           dateTrial.getMonth() === datePartFormatted[1] &&
           dateTrial.getDate() === datePartFormatted[2]
              ? dateTrial :
              null;
  }

export const verifyCPF = value => {

    var cpf = "00000000000"+value;

    // eslint-disable-next-line
    cpf = cpf.replace(/\./g,'').replace(/\,/g,'').replace(/\-/g,'').replace(/\//g,'');

    cpf = cpf.substring(cpf.length-11,cpf.length);


    if ( !cpf || cpf.length !== 11
            || cpf === "00000000000"
            || cpf === "11111111111"
            || cpf === "22222222222" 
            || cpf === "33333333333" 
            || cpf === "44444444444" 
            || cpf === "55555555555" 
            || cpf === "66666666666"
            || cpf === "77777777777"
            || cpf === "88888888888" 
            || cpf === "99999999999" )
        return false
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++) 
        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11))  resto = 0
    if (resto !== parseInt(cpf.substring(9, 10)) ) return false
    soma = 0
    for (var j = 1; j <= 10; j++) 
        soma = soma + parseInt(cpf.substring(j-1, j)) * (12 - j)
    resto = (soma * 10) % 11
    if ((resto === 10) || (resto === 11))  resto = 0
    if (resto !== parseInt(cpf.substring(10, 11) ) ) return false
    return true
};

export const verifyCNPJ = value => {

    var cnpj =  "00000000000000"+value;
    
    // eslint-disable-next-line
    cnpj = cnpj.replace(/\./g,'').replace(/\,/g,'').replace(/\-/g,'').replace(/\//g,'');

    cnpj = cnpj.substring(cnpj.length-14,cnpj.length);




    if ( !cnpj || cnpj.length !== 14
            || cnpj === "00000000000000" 
            || cnpj === "11111111111111" 
            || cnpj === "22222222222222" 
            || cnpj === "33333333333333" 
            || cnpj === "44444444444444" 
            || cnpj === "55555555555555" 
            || cnpj === "66666666666666" 
            || cnpj === "77777777777777" 
            || cnpj === "88888888888888" 
            || cnpj === "99999999999999")
        return false
    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0,tamanho)
    var digitos = cnpj.substring(tamanho)
    var soma = 0
    var pos = tamanho - 7
    for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) pos = 9
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado !== parseInt(digitos.charAt(0))) return false;
    tamanho = tamanho + 1
    numeros = cnpj.substring(0,tamanho)
    soma = 0
    pos = tamanho - 7
    for (var j = tamanho; j >= 1; j--) {
        soma += numeros.charAt(tamanho - j) * pos--
        if (pos < 2) pos = 9
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado !== parseInt(digitos.charAt(1))) return false
    return true;
    
}


export const fieldChange = (obj , event, stateName, type, stateNameEqualTo, maxValue) => {

    switch (type) {
        case "email":
            if (verifyEmail(event.target.value)) {
                obj.setState({ [stateName + "State"]: "has-success" });
            } else {
                obj.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        case "password":
            if (verifyLength(event.target.value, 1)) {
                obj.setState({ [stateName + "State"]: "has-success" });
            } else {
                obj.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        case "equalTo":
            if (compare(event.target.value, obj.state[stateNameEqualTo])) {
                obj.setState({ [stateName + "State"]: "has-success" });
                obj.setState({ [stateNameEqualTo + "State"]: "has-success" });
            } else {
                obj.setState({ [stateName + "State"]: "has-danger" });
                obj.setState({ [stateNameEqualTo + "State"]: "has-danger" });
            }
            break;
        case "number":
            if (verifyNumber(event.target.value)) {
                obj.setState({ [stateName + "State"]: "has-success" });
            } else {
                obj.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        case "length":
            if (verifyLength(event.target.value, stateNameEqualTo)) {
                obj.setState({ [stateName + "State"]: "has-success" });
            } else {
                obj.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        case "max-length":
            if (!verifyLength(event.target.value, stateNameEqualTo + 1)) {
                obj.setState({ [stateName + "State"]: "has-success" });
            } else {
                obj.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        case "url":
            if (verifyUrl(event.target.value)) {
                obj.setState({ [stateName + "State"]: "has-success" });
            } else {
                obj.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        case "min-value":
            if (
                verifyNumber(event.target.value) &&
                event.target.value >= stateNameEqualTo
            ) {
                obj.setState({ [stateName + "State"]: "has-success" });
            } else {
                obj.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        case "max-value":
            if (
                verifyNumber(event.target.value) &&
                event.target.value <= stateNameEqualTo
            ) {
                obj.setState({ [stateName + "State"]: "has-success" });
            } else {
                obj.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        case "ddd":
                if ( 
                        event.target.value.length ===2 && 
                        event.target.value[1]!=="0"
                ) {
                    obj.setState({ [stateName + "State"]: "has-success" });
                } else {
                    obj.setState({ [stateName + "State"]: "has-danger" });
                }
                break;
        case "cpf":
            if ( 
                    verifyCPF(event.target.value) || verifyCNPJ(event.target.value)
            ) {
                obj.setState({ [stateName + "State"]: "has-success" });
            } else {
                obj.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        case "range":
            if (
                verifyNumber(event.target.value) &&
                event.target.value >= stateNameEqualTo &&
                event.target.value <= maxValue
            ) {
                obj.setState({ [stateName + "State"]: "has-success" });
            } else {
                obj.setState({ [stateName + "State"]: "has-danger" });
            }
            break;
        default:
            break;
    }
    obj.setState({ [stateName]: event.target.value });
};

