/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardImg,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";

import ReactBSAlert from "react-bootstrap-sweetalert";

import {sliValidations, sliData} from "../../commom/sliHelper"


class RegisterSLI extends React.Component {
constructor(props) {
    super(props);
        this.state = {
            registerEmail:  "",
            registerPassword: "",
            registerConfirmPassword: "",
            registerEmailState: "",
            registerPasswordState: "",
            registerConfirmPasswordState: "",
            registerFullName: "",
            registerFullNameState: "",
            InviteCodeState: "",
            InviteCode: "",
            LastServerMessage: ""
        }

        this.registerClick=this.registerClick.bind(this);
        this.basicAlert = this.basicAlert.bind(this);
        this.hideAlert = this.hideAlert.bind(this);

    }



    registerClick = () => {

        if ((this.state.InviteCode === "") 
           || (this.state.registerFullName === "")
           || (this.state.registerEmail=== "")
           || (this.state.registerPassword=== "")
           || (this.state.registerConfirmPassword=== "")           
           ){

            if (this.state.InviteCode === "") {
                this.setState({ InviteCodeState: "has-danger" });
            }
            if (this.state.registerFullName === "") {
                this.setState({ registerFullNameState : "has-danger" });
            }
            if (this.state.registerEmail === "") {
                this.setState({ registerEmailState: "has-danger" });
            }
            if (this.state.registerConfirmPassword === "") {
                this.setState({ registerConfirmPasswordState: "has-danger" });
            }
            if (
                this.state.registerPassword === "" ||
                this.state.registerConfirmPassword === ""
               ) {
                    this.setState({ registerPasswordState: "has-danger" });
                    this.setState({ registerConfirmPasswordState: "has-danger" });
            }

        }
        else {

            sliData.post('/Users/SignIn', {
                    username: this.state.registerEmail,
                    Password: this.state.registerPassword,
                    FullName: this.state.registerFullName,                    
                    InviteCode: this.state.InviteCode               
            // }).then((Response) => {
            //     return Response.json()
            }).then((result) => {
                //var objResult = JSON.parse(result);
                
                //console.log(result);
                if (result.status !== 'Success'){
                    this.setState({ LastServerMessage : result.message })
                    this.basicAlert("danger");
                }
                else{
                    this.setState({ LastServerMessage : result.message })
                    this.basicAlert("success");
                    //this.props.history.push("/login");
                }
            }).catch((error) => {
                this.setState({ LastServerMessage: error.message})
                this.basicAlert("danger");
            })  

        }

    };

    basicAlert = (btnstyle) => {

        if (btnstyle==='danger') 
        {

            this.setState({
                alert: (
                    <ReactBSAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.LastServerMessage}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="danger"
                    //["default","primary","link","info","success","warning","danger"]
                    btnSize=""
                    />
                )
            });

        }
        else{
            this.setState({
                alert: (
                    <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.LastServerMessage}
                    onConfirm={() => this.props.history.push("login")}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    //["default","primary","link","info","success","warning","danger"]
                    btnSize=""
                    />
                )
            });

        }
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

      
    componentDidMount() {


        var params = new URLSearchParams(this.props.location.search);

        if (params.has("invitecode") ){

            this.setState({ InviteCode: params.get("invitecode") })

        }
        

        
        document.body.classList.toggle("register-page");
    }
     
    render() {
        let {
        // register form
            registerEmailState,
            registerPasswordState,
            registerConfirmPasswordState,
            registerFullNameState,
            InviteCodeState
        } = this.state;
        return (
        <>
            <div className="content">
                {this.state.alert}
                <Container>
                    <Row>
                        <Col className="ml-auto" md="5">
                            <div className="info-area info-horizontal mt-5">
                            <div className="icon icon-warning">
                                <i className="tim-icons icon-align-left-2" />
                            </div>
                            <div className="description">
                                <h3 className="info-title">Score</h3>
                                <p className="description">
                                Separamos o que é realmente importante, nesse
                                caso aplicamos o princípio de Pareto.
                                </p>
                            </div>
                            </div>
                            <div className="info-area info-horizontal">
                            <div className="icon icon-primary">
                                <i className="tim-icons icon-square-pin" />
                            </div>
                            <div className="description">
                                <h3 className="info-title">Localization</h3>
                                <p className="description">
                                Enriquecimento é algo comum no mercado, saber
                                o que deve ser enriquecido é o ponto chave.
                                </p>
                            </div>
                            </div>
                            <div className="info-area info-horizontal">
                            <div className="icon icon-info">
                                <i className="tim-icons icon-components" />
                            </div>
                            <div className="description">
                                <h3 className="info-title">Inventory</h3>
                                <p className="description">
                                Direcionamos os melhores recursos para cada
                                tipo de papel.
                                </p>
                            </div>
                            </div>
                        </Col>
                        <Col className="mr-auto" md="7">
                            <Form id="RegisterValidation">
                                <Card className="card-register card-white">
                                    <CardHeader>
                                        <CardImg
                                            alt="..."
                                            src={require("assets/img/card-primary.png")}
                                        />
                                        <CardTitle tag="h4">Register</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormGroup className={`has-label ${InviteCodeState}`}>
                                            <label>Invite Code *</label>
                                            <Input
                                            name="invitecode"
                                            type="text"
                                            value={this.state.InviteCode}
                                            onChange={e => sliValidations.fieldChange(this, e, "InviteCode", "length",3)}
                                            />
                                            {this.state.InviteCodeState === "has-danger" ? (
                                            <label className="error">
                                                This field is required.
                                            </label>
                                            ) : null}
                                        </FormGroup>
                                        <FormGroup className={`has-label ${registerFullNameState}`}>
                                            <label>Full Name *</label>
                                            <Input
                                            name="fullname"
                                            type="text"
                                            onChange={e => sliValidations.fieldChange(this, e, "registerFullName", "length", 1)}
                                            />
                                            {this.state.registerFullNameState === "has-danger" ? (
                                            <label className="error">
                                                This field is required.
                                            </label>
                                            ) : null}
                                        </FormGroup>
                                        <FormGroup className={`has-label ${registerEmailState}`}>
                                            <label>Email Address *</label>
                                            <Input
                                            name="email"
                                            type="email"
                                            value={this.state.registerEmail}
                                            onChange={e => sliValidations.fieldChange(this, e, "registerEmail", "email")}
                                            />
                                            {this.state.registerEmailState === "has-danger" ? (
                                            <label className="error">
                                                Please enter a valid email address.
                                            </label>
                                            ) : null}
                                        </FormGroup>
                                        <FormGroup className={`has-label ${registerPasswordState}`}>
                                            <label>Password *</label>
                                            <Input
                                            id="registerPassword"
                                            name="password"
                                            type="password"
                                            autoComplete="off"
                                            onChange={e =>
                                                sliValidations.fieldChange(this, e, "registerPassword", "password")
                                            }
                                            />
                                            {this.state.registerPasswordState === "has-danger" ? (
                                            <label className="error">This field is required.</label>
                                            ) : null}
                                        </FormGroup>
                                        <FormGroup
                                            className={`has-label ${registerConfirmPasswordState}`}
                                        >
                                            <label>Confirm Password *</label>
                                            <Input
                                            equalto="#registerPassword"
                                            id="registerPasswordConfirmation"
                                            name="password_confirmation"
                                            type="password"
                                            autoComplete="off"
                                            onChange={e =>
                                                sliValidations.fieldChange(this,
                                                e,
                                                "registerConfirmPassword",
                                                "equalTo",
                                                "registerPassword"
                                                )
                                            }
                                            />
                                            {this.state.registerConfirmPasswordState ===
                                            "has-danger" ? (
                                                <label className="error">This field is required.</label>
                                            ) : null}
                                        </FormGroup>
                                        <div className="text-primary">
                                            * Required fields
                                        </div>
                                        {/* { <FormGroup check className="text-left">
                                            <Label check>
                                            <Input type="checkbox" />
                                            <span className="form-check-sign" />I agree to the{" "}
                                            <a href="#pablo" onClick={e => e.preventDefault}>
                                                terms and conditions
                                            </a>
                                            .
                                            </Label>
                                            </FormGroup> } */}
                                    </CardBody>
                                    <CardFooter>
                                        <Button color="primary" onClick={this.registerClick}>
                                            Sign In
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
        );
    }
}

export default RegisterSLI;
