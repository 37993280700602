import React from 'react';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    ButtonGroup,

  } from "reactstrap";

import { Line } from "react-chartjs-2";

import classNames from "classnames";

import {sliData} from "../../commom/sliHelper"

let chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: true
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          type: 'linear',          
          barPercentage: 1.6,
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent"
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a"
          }
        },
        {
            type: 'linear',          
            barPercentage: 1.6,
            display: true,
            position: 'right',
            label: 'test',
            id: 'y-axis-2',
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.0)",
              zeroLineColor: "transparent"
            },
            ticks: {
              suggestedMin: 60,
              suggestedMax: 125,
              padding: 20,
              fontColor: "#9a9a9a"
            }
          }
      ],
      xAxes: [
        {
          barPercentage: 1.6,          
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ]
    }
  };



class ChartKPI extends React.Component {
    constructor(props){
        super(props);
        this.state={
            GraphView1: {},
            GraphView2: {},
            GraphView3: {},
            bigChartData: "data1"
        }; 

    }

    SetGraphData = (barLabel,labelData,data1,data2,data3,data4) => {
        var graphFunction = canvas => {
            let ctx = canvas.getContext("2d");
        
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        
            gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
            gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
            gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
        
            return {
              labels: labelData,
              datasets: [
                {
                  type: "line",
                  label: "Conexoes",
                  fill: true,
                  backgroundColor: gradientStroke,
                  borderColor: "#ff6600", //"#1f8ef1",
                  borderWidth: 4,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  pointBackgroundColor: "#ff6600",//"#1f8ef1",
                  pointBorderColor: "rgba(255,255,255,0)",
                  pointHoverBackgroundColor: "#ff6600", //"#1f8ef1",
                  pointBorderWidth: 420,
                  pointHoverRadius: 4,
                  pointHoverBorderWidth: 15,
                  pointRadius: 4,
                  data: data1,
                  yAxisID: "y-axis-2"
                }
                ,{
                    type: "line",
                    label: "CPC",
                    fill: true,
                    backgroundColor: gradientStroke,
                    borderColor: "#99ff66",
                    borderWidth: 2,
                    borderDash: [],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: "#99ff66",
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: "#99ff66",
                    pointBorderWidth: 20,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 4,
                    data: data2,
                    yAxisID: "y-axis-1"
                }
                ,{
                    type: "line",
                    label: 'TP',
                    fill: true,
                    backgroundColor: gradientStroke,
                    borderColor: "#1f8ef1",
                    borderWidth: 2,
                    borderDash: [],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: "#1f8ef1",
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: "#1f8ef1",
                    pointBorderWidth: 20,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 4,
                    data: data3,
                    yAxisID: "y-axis-1"
                },               
                {
                    type: "line",
                    label: 'Promessas',
                    fill: true,
                    backgroundColor: gradientStroke,
                    borderColor: "#cc6699",
                    borderWidth: 2,
                    borderDash: [],
                    borderDashOffset: 0.0,
                    pointBackgroundColor: "#cc6699",
                    pointBorderColor: "rgba(255,255,255,0)",
                    pointHoverBackgroundColor: "#cc6699",
                    pointBorderWidth: 20,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 15,
                    pointRadius: 4,
                    data: data4,
                    yAxisID: "y-axis-1"
                }                                                             
              ]
            };
        }


        return graphFunction
    }

    LoadData = () =>{

        /*
            Inventário Total
        */
        if (this.props.CustomerID!==null && this.props.ProviderID!==null && this.props.GroupTestSegmentationID!==null){
            
            var dataSource;

            /*
                Ligações por score 15 ultimos dias
            */

            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(this.props.CustomerID)
                , ProviderID: parseInt(this.props.ProviderID)
                , GroupTestSegmentationID: parseInt(this.props.GroupTestSegmentationID)
                , Graphname: 'graphKPI'
            }).then((result) => {
                if (result.status === 'Success'){
                    if (result.data.data===null){
                
                        //this.setState({Graph1View: {}, Graph2View: {}, Graph3View: {} })
                        this.setState({GraphView1: this.SetGraphData("",[], [], [], [],[])
                        , GraphView2: this.SetGraphData("",[], [], [], [],[])
                        ,GraphView3: this.SetGraphData("",[], [], [], [],[])
                        });

                    }
                    else{
                
                        // t.Type, T.Date, T.Attempts, T.Connects, T.RPC, T.TP, T.Promise , ISNULL(t2.Qty,0) Inventory

                        dataSource=JSON.parse(result.data.data);
                        
                        let Graph1Label=[];
                        let Graph1Data2=[];
                        let Graph1Data3=[];
                        let Graph1Data4=[];
                        let Graph1Data5=[];

                        let Graph2Label=[];

                        let Graph2Data2=[];
                        let Graph2Data3=[];
                        let Graph2Data4=[];
                        let Graph2Data5=[];


                        let Graph3Label=[];

                        let Graph3Data2=[];
                        let Graph3Data3=[];
                        let Graph3Data4=[];
                        let Graph3Data5=[];
                        

                        for (var i in dataSource){
                            if (dataSource[i].Type==="Total"){
                                Graph1Label.push(dataSource[i].Date);
                           //     Graph1Data1.push(dataSource[i].Attempts);
                                Graph1Data2.push(dataSource[i].Connects);
                                Graph1Data3.push(dataSource[i].RPC);
                                Graph1Data4.push(dataSource[i].TP);
                                Graph1Data5.push(dataSource[i].Promise);
                           //     Graph1Data6.push(dataSource[i].Inventory);
                            }
                            if (dataSource[i].Type==="URA"){
                                Graph2Label.push(dataSource[i].Date);
                            //    Graph2Data1.push(dataSource[i].Attempts);
                                Graph2Data2.push(dataSource[i].Connects);
                                Graph2Data3.push(dataSource[i].RPC);
                                Graph2Data4.push(dataSource[i].TP);
                                Graph2Data5.push(dataSource[i].Promise);
                            //    Graph2Data6.push(dataSource[i].Inventory);                                       
                            }
                            if (dataSource[i].Type==="Dialer"){
                                Graph3Label.push(dataSource[i].Date);
                            //    Graph3Data1.push(dataSource[i].Attempts);
                                Graph3Data2.push(dataSource[i].Connects);
                                Graph3Data3.push(dataSource[i].RPC);
                                Graph3Data4.push(dataSource[i].TP);
                                Graph3Data5.push(dataSource[i].Promise);
                             //   Graph3Data6.push(dataSource[i].Inventory);
                            }
                        }

                        let Graph1ResultView = this.SetGraphData("Dialer+URA",Graph1Label, Graph1Data2,Graph1Data3,Graph1Data4,Graph1Data5);
                        let Graph2ResultView = this.SetGraphData("URA",Graph2Label, Graph2Data2,Graph2Data3,Graph2Data4,Graph2Data5);
                        let Graph3ResultView = this.SetGraphData("Dialer",Graph3Label, Graph3Data2,Graph3Data3,Graph3Data4,Graph3Data5);
                        
                        this.setState({ GraphView1: Graph1ResultView, GraphView2: Graph2ResultView ,GraphView3: Graph3ResultView});
                                

                    }

                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })


        }
    }

    initializeGraph = () =>{
        this.setState({GraphView1: this.SetGraphData("",[], [], [], [],[])
            , GraphView2: this.SetGraphData("",[], [], [], [],[])
            ,GraphView3: this.SetGraphData("",[], [], [], [],[])
        });
    }


    setBgChartData = name => {
        this.setState({
        bigChartData: name
        });
    };

    componentDidMount() {
        this.initializeGraph();
        this.LoadData();
    }

    
    componentDidUpdate(prevProps){
        
        if (
                (prevProps.CustomerID !== this.props.CustomerID) 
                || (prevProps.ProviderID !== this.props.ProviderID)
                || (prevProps.GroupTestSegmentationID !== this.props.GroupTestSegmentationID)
            )
        {
            this.LoadData();
        }
                
    }

   
    render(){
        let {    
            GraphView1,
            GraphView2,
            GraphView3,
        } = this.state;

        return (

            <div className="content">
                <Card className="card-chart">
                    <CardHeader>
                        <Row>
                            <Col className="text-left" sm="6">
                                <h5 className="card-category">Últimos 30 dias corridos</h5>
                                <CardTitle tag="h3">
                                    <i className="tim-icons icon-shape-star text-danger" />{" "}
                                    (#)-Principais KPI's
                                </CardTitle>
                            </Col>
                            <Col sm="6">
                                <ButtonGroup
                                    className="btn-group-toggle float-right"
                                    data-toggle="buttons"
                                >
                                    <Button
                                        color="info"
                                        id="0"
                                        size="sm"
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: this.state.bigChartData === "data1"
                                        })}
                                        onClick={() => this.setBgChartData("data1")}
                                        >
                                        <input defaultChecked name="options" type="radio" />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Ura+Dialer
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-single-02" />
                                        </span>
                                    </Button>
                                    <Button
                                        color="info"
                                        id="1"
                                        size="sm"
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: this.state.bigChartData === "data2"
                                        })}
                                        onClick={() => this.setBgChartData("data2")}
                                        >
                                        <input name="options" type="radio" />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Ura
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-bus-front-12" />
                                        </span>
                                    </Button>
                                    <Button
                                        color="info"
                                        id="2"
                                        size="sm"
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: this.state.bigChartData === "data3"
                                        })}
                                        onClick={() => this.setBgChartData("data3")}
                                        >
                                        <input name="options" type="radio" />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Dialer
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-tap-02" />
                                        </span>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                    <div className="chart-area">
                        <Line
                             data={this.state.bigChartData==="data1"?GraphView1:((this.state.bigChartData==="data2")?GraphView2:GraphView3)}
                             options={chartOptions}
                        />
                    </div>
                    </CardBody>
                </Card>                          
            </div>

        )

    }

}
export default ChartKPI;
