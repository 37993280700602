import React from "react";
import {sliData} from "../../commom/sliHelper"
// reactstrap components
import {  
    Table,
  } from "reactstrap";

import moment from "moment"

class FTPFiles extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading : true
        }

        this.LoadData = this.LoadData.bind(this);
        this.RenderTableData = this.RenderTableData.bind(this);
    }

    LoadData(){

        sliData.post('/File/GetFTPFileList',{ 
            CustomerID: parseInt(this.props.CustomerID),
            ProviderID: parseInt(this.props.ProviderID),
            Type: this.props.Type,
            FilePathType: this.props.FilePathType}
        ).then((result) => {
            if (result.status === 'Invalid'){
                
                    // this.setState({ LastServerMessage : result.message })
                // this.basicAlert();
            }
            else{
                this.setState({ data : result.data , isLoading: false });
                // sessionStorage.setItem('id_token', result.data.token)
                // this.props.history.push("/MainSLI/Dashboard");

            }                    
        }).catch((error) => {
            // this.setState({ LastServerMessage: error.message})
            // this.basicAlert("danger");
        })

    }

    RenderTableData(){
        
        
        if (!this.state.isLoading){
            if (this.state.data!==null)
            {
                return this.state.data.map((file, index) => {
                    const {  filename , size , creationDate } = file

                    return (
                    
                        <tr key={index}>
                            <td><a href="#1" onClick={(e) => this.downloadClick(e)}>{filename}</a></td>
                            <td>{size}</td>
                            <td className="text-center">{moment(creationDate).format('DD/MM/YYYY HH:mm:ss')}</td>
                        </tr>

                    )       

                })
            }
        }

    }
 
    downloadClick(e) {
        var filename=e.currentTarget.textContent;

        sliData.getFile('/File/DownloadFileFromFTP', {
            CustomerID: parseInt(this.props.CustomerID),
            ProviderID: parseInt(this.props.ProviderID),
            filename: e.currentTarget.textContent,
            FilePathType: this.props.FilePathType
        }
        ).then((result) => {
            if (result.size !== 0){

                //saveAs(result.blob, "hello world.txt");
                var data = new Blob([result], {type: "text/plain;charset=utf-8"});
                var csvURL = window.URL.createObjectURL(data);
                var tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', filename);
                tempLink.click();

            }
            else{
                
            }
        }).catch((error) => {
            
            this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

        })



    }

    componentDidMount() {
        this.LoadData();
    }

    componentDidUpdate(prevProps) {

        if ((prevProps.CustomerID !== this.props.CustomerID) || (prevProps.ProviderID !== this.props.ProviderID) || (prevProps.FilePathType !== this.props.FilePathType) ){
            this.LoadData();
       }

    }


    render(){
        return(
            <Table responsive>
                <thead className="text-primary">
                    <tr>
                    <th>File Name</th>
                    <th>Size</th>
                    <th className="text-center">Creation Date</th>
                    </tr>
                </thead>
                <tbody>
                    {this.RenderTableData()}
                </tbody>
            </Table>

        );

    }

}

export default FTPFiles;