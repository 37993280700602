/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  FormGroup,
  Container,
  Col,
  
} from "reactstrap";

import {sliValidations ,sliData} from "../../commom/sliHelper"


class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        var params = new URLSearchParams(this.props.location.search);
        this.state = {
            username: params.has("username") ? params.get("username") : "",
            emailkey: params.has("emailkey") ? params.get("emailkey") : "",
            registerPassword: "",
            registerPasswordState: "",
            registerConfirmPassword: "",
            registerConfirmPasswordState:"",
            LastServerMessage: ""
        }

        this.RSClick = this.RSClick.bind(this);
        this.basicAlert = this.basicAlert.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
      
    }
    
    RSClick = () => {

        if ((this.state.registerPassword=== "")
            || (this.state.registerConfirmPassword=== "")           
        ){

            if (this.state.registerConfirmPassword === "") {
                this.setState({ registerConfirmPasswordState: "has-danger" });
            }
            if (
                this.state.registerPassword === "" ||
                this.state.registerConfirmPassword === ""
                ) {
                    this.setState({ registerPasswordState: "has-danger" });
                    this.setState({ registerConfirmPasswordState: "has-danger" });
            }

        }
        else {

            sliData.post('/Users/ChangePassword', {
                    username: this.state.username,
                    emailkey: this.state.emailkey,
                    Password : this.state.registerPassword                
            }).then((result) => {
                        //var objResult = JSON.parse(result);
                        
                        //console.log(result);
                        if (result.status === 'Invalid'){
                            this.setState({ LastServerMessage : result.message })
                            this.basicAlert("danger");
                        }
                        else{
                            this.setState({ LastServerMessage : result.message })
                            this.basicAlert("success");
                            
                        }
            }).catch((error) => {
                this.setState({ LastServerMessage: error.message})
                this.basicAlert("danger");
            })
        }


    };

    basicAlert = (btnstyle) => {

        if (btnstyle==='danger') 
        {

            this.setState({
                alert: (
                    <ReactBSAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.LastServerMessage}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="danger"
                    //["default","primary","link","info","success","warning","danger"]
                    btnSize=""
                    />
                )
            });

        }
        else{
            this.setState({
                alert: (
                    <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.LastServerMessage}
                    onConfirm={() => this.props.history.push("login")}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    //["default","primary","link","info","success","warning","danger"]
                    btnSize=""
                    />
                )
            });

        }
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };


    componentDidMount() {       
        
        document.body.classList.toggle("ResetPassword-page");
        

    }
    

    render() {
        // taking all the states
         let {
            
             registerPasswordState,
             registerConfirmPasswordState,

         } = this.state;
        return (
            <>
                <div className="content">
                    {this.state.alert}
                    <Container>
                        <Col className="ml-auto mr-auto" lg="4" md="6">
                            <Form className="ResetForm">
                                <Card className="card-login card-white">
                                    <CardHeader>
                                        <img                                                                                        
                                            alt="..."                                            
                                            src={require("assets/img/card-primary.png")}
                                        />
                                        <CardTitle tag="h1">Password</CardTitle>
                                    </CardHeader>
                                    <CardBody>                                            
                                        <FormGroup className={`has-label ${registerPasswordState}`}>
                                            <label>Password *</label>
                                            <Input
                                                id="registerPassword"
                                                name="password"
                                                type="password"
                                                autoComplete="off"
                                                onChange={e =>
                                                    sliValidations.fieldChange(this, e, "registerPassword", "password")
                                                }
                                            />
                                            {this.state.registerPasswordState === "has-danger" ? (
                                                <label className="error">This field is required.</label>
                                            ) : null}
                                        </FormGroup>
                                        <FormGroup className={`has-label ${registerConfirmPasswordState}`}
                                        >
                                            <label>Confirm Password *</label>
                                            <Input
                                                equalto="#registerPassword"
                                                id="registerPasswordConfirmation"
                                                name="password_confirmation"
                                                type="password"
                                                autoComplete="off"
                                                onChange={e =>
                                                    sliValidations.fieldChange(
                                                        this,
                                                        e,
                                                        "registerConfirmPassword",
                                                        "equalTo",
                                                        "registerPassword"
                                                    )
                                            }
                                            />
                                            {this.state.registerConfirmPasswordState ===
                                                "has-danger" ? (
                                                    <label className="error">This field is required.</label>
                                            ) : null}
                                        </FormGroup>
                                        <div className="text-primary">
                                            * Required fields
                                        </div>                                            
                                    </CardBody>
                                    <CardFooter>
                                        <Button color="primary" onClick={this.RSClick}>
                                            Reset Password
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Container>
                </div>
            </>
        );
    }
}

export default ResetPassword;
