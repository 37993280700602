import React from 'react';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    FormGroup,
    Form,
    Label
  } from "reactstrap";

import ReactDatetime from "react-datetime";  
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactTable from "react-table";
import {sliData} from "../../commom/sliHelper"
import {CSVLink} from "react-csv";
import moment from 'moment';
import 'moment/locale/pt-br';
import Loader from 'react-loader-spinner'

import icons from '../../variables/icons';

class SkipProcess extends React.Component {
    
    constructor(props){
        super();
        this.state={
            cpf:"",
            LastServerMessage:"",
            qtyCallsLoaded:0,
            CustomerID: sessionStorage.getItem("current_CustomerID"),
            ProviderID: sessionStorage.getItem("current_ProviderID"),
            ddd:"",
            phoneNumber:"",
            startCallDate:"",
            endCallDate:"",
            data:[],
            dataSent:[],
            dataReceived:[],
            dddState:'',
            cpfState:'',
            phoneNumberState:'',
            btnSearchDisabled: false,
            isLoadingPage: false,
            spinner: (<Loader
                type="Rings"
                //Audio, Ball-Triangle, Bars, Circles, Grid, Hearts, Oval, Puff, Rings, TailSpin, ThreeDots
                color="#00BFFF"
                height={960}
                width={"100%"}
                //timeout={3000} //3 secs
        
                />)
        };
        this.ButtonClick = this.ButtonClick.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.csvLink = React.createRef();
        this.csvLinkReceived = React.createRef();
    }

    ButtonClick = (ev) =>{
        this.setState({btnSearchDisabled: true})
        
        if ((this.state.startCallDate ==="") ||  (this.state.endCallDate==="") )
        {
            this.setState({ LastServerMessage : "Por favor preencher as datas!" , btnSearchDisabled: false,  isLoadingPage:false },() => {this.basicAlert('danger')});
        }
        else
        {
            if ((this.state.startCallDate) !=="" && (this.state.startCallDate>this.state.endCallDate))
            {
                this.setState({ LastServerMessage : "Data inicial maior que a Data Final!" , btnSearchDisabled: false,  isLoadingPage:false },() => {this.basicAlert('danger')});
            }
            else
            {

                this.setState({isLoadingPage: true});
                sliData.post('/Skip/GetSkipProcessDailyControl',{
                    ProviderID:parseInt(this.state.ProviderID),
                    StartDate : this.state.startCallDate===""?null:this.state.startCallDate,
                    EndDate : this.state.endCallDate===""?null:this.state.endCallDate
                }).then((result) => {
                    
                    //let jsonData=JSON.parse(result.data.data)
                    //let jsonData=result.data;

                    // for 

                    var finalData=result.data.map((prop, key) => {
                        return {
                        id: key
                        ,ProviderID: prop.providerID
                        ,SkipProviderID: prop.skipProviderID
                        ,ProviderAlias: prop.providerAlias
                        ,SkipProcessFileID: prop.skipProcessFileID
                        ,SkipProcessActivityID: prop.skipProcessActivityID
                        ,EntityType: prop.entityType
                        ,SkipProviderAlias: prop.skipProviderAlias
                        ,SendFileDate: moment(prop.sendFileDate).format('DD/MM/YYYY')
                        ,ReturnFileDate: prop.returnFileDate==='0001-01-01T00:00:00'?'':moment(prop.returnFileDate).format('DD/MM/YYYY')
                        ,SendBatchID: prop.sendBatchID
                        ,ReturnBatchID: prop.returnBatchID
                        ,SendFileID: prop.sendFileID
                        ,ReturnFileID: prop.returnFileID
                        ,QtySent: prop.qtySent.toLocaleString()
                        ,QtyReturned: prop.qtyReturned.toLocaleString()
                        ,Attempts: prop.attempts.toLocaleString()
                        ,Connected: prop.connected.toLocaleString()
                        ,RPC: prop.rpc.toLocaleString()
                        ,Promise: prop.promise.toLocaleString()
                        ,LastUpdateDate: moment(prop.lastUpdateDate).format('DD/MM/YYYY')
                        ,RPCRate: prop.connected===0?0:prop.rpc/prop.connected

                        }
                    });

                                            
                    //rowsPerPage

                    this.setState(
                        { LastServerMessage : result.message
                        , data : finalData 
                        , btnSearchDisabled: false 
                        , isLoadingPage:false
                        },() =>{
                                if (result.status !== 'Success'){
                                    
                                    this.basicAlert('danger');

                                }
                                else{

                                    //this.updateRecords();
                                    //this.basicAlert('success');
                                    //this.setState({documentNumber: "",disableButtons:true});                            

                                }                    
                    })
                }).catch((error) => {
                    
                    this.setState({ LastServerMessage: error.message, btnSearchDisabled: false , isLoadingPage:false },() => {this.basicAlert('danger')})

                })
            }
        }
    }

    DownLoadSentData = (event) =>{
       
        //this.setState({btnSearchDisabled: true})
        
        if (this.state.data.length===0)
        {
            this.setState({ LastServerMessage : "Não existem dados retornados pela pesquisa" , btnSearchDisabled: false,  isLoadingPage:false },() => {this.basicAlert('danger')});
        }
        else
        {

            this.setState({isLoadingPage: true});

            sliData.post('/Skip/GetSkipProcessDailyControlSent',{
                ProviderID:parseInt(this.state.ProviderID),
                StartDate : this.state.startCallDate===""?null:this.state.startCallDate,
                EndDate : this.state.endCallDate===""?null:this.state.endCallDate
            }).then((result) => {
                               
                let finalData=result.data.map((prop, key) => {
                    return {
                        id: key
                        ,ProviderAlias: prop.providerAlias
                        ,EntityType: prop.entityType
                        ,SkipProviderAlias: prop.skipProviderAlias
                        ,DocumentNumber: prop.documentNumber
                        ,DDD: prop.ddd
                        ,Number: prop.number
                        ,SkipProcessActivityID: prop.skipProcessActivityID
                        ,SendFileID: prop.sendFileID
                        ,SendFileDate: moment(prop.sendFileDate).format('DD/MM/YYYY')
                        ,ReturnFileID: prop.returnFileID
                        ,ReturnFileDate: prop.returnFileDate==='0001-01-01T00:00:00'?'':moment(prop.returnFileDate).format('DD/MM/YYYY')
                    }
                });

                                        
                this.setState({
                    LastServerMessage : result.message
                    , dataSent : finalData 
                    , btnSearchDisabled: false                                  
                    },
                    ()=>{

                        setTimeout(() => {
                            this.csvLink.current.link.click();
                            this.setState({ isLoadingPage:false      });
                        }
                        , 3000);

                        
                })                

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message, btnSearchDisabled: false , isLoadingPage:false },() => {this.basicAlert('danger')})

            })
        }


    }

    DownLoadReceivedData = (ev) =>{

        if (this.state.data.length===0)
        {
            this.setState({ LastServerMessage : "Não existem dados retornados pela pesquisa" , btnSearchDisabled: false,  isLoadingPage:false },() => {this.basicAlert('danger')});
        }
        else
        {

            this.setState({isLoadingPage: true});

            sliData.post('/Skip/GetSkipProcessDailyControlReceived',{
                ProviderID:parseInt(this.state.ProviderID),
                StartDate : this.state.startCallDate===""?null:this.state.startCallDate,
                EndDate : this.state.endCallDate===""?null:this.state.endCallDate
            }).then((result) => {
                               
                let finalData=result.data.map((prop, key) => {
                    return {
                        id: key
                        ,ProviderAlias: prop.providerAlias
                        ,EntityType: prop.entityType
                        ,SkipProviderAlias: prop.skipProviderAlias
                        ,DocumentNumber: prop.documentNumber
                        ,DDD: prop.ddd
                        ,Number: prop.number
                        ,SkipProcessActivityID: prop.skipProcessActivityID
                        ,SendFileID: prop.sendFileID
                        ,SendFileDate: moment(prop.sendFileDate).format('DD/MM/YYYY')
                        ,ReturnFileID: prop.returnFileID
                        ,ReturnFileDate: prop.returnFileDate==='0001-01-01T00:00:00'?'':moment(prop.returnFileDate).format('DD/MM/YYYY')
                        ,QtyAttempts : prop.qtyAttempts
                        ,QtySuccess : prop.qtySuccess
                        ,QtyRPC : prop.qtyRPC
                        ,QtyPromise : prop.qtyPromise
                    }
                });

                                        
                this.setState({
                    LastServerMessage : result.message
                    , dataReceived : finalData 
                    , btnSearchDisabled: false                                  
                    },
                    ()=>{

                        setTimeout(() => {
                            this.csvLinkReceived.current.link.click();
                            this.setState({ isLoadingPage:false      });
                        }
                        , 3000);

                        
                })                

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message, btnSearchDisabled: false , isLoadingPage:false },() => {this.basicAlert('danger')})

            })
        }

        
    }

    basicAlert = (btnstyle) => {
        if (btnstyle==='success'){
            this.setState({
            alert: (
                <ReactBSAlert                
                success  
                style={{ display: "block", marginTop: "-100px" }}
                title={this.state.LastServerMessage}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle={btnstyle}
                //["default","primary","link","info","success","warning","danger"]
                btnSize=""
                />
            )
            });
        }
        else{
            this.setState({
                alert: (
                    <ReactBSAlert
                    danger  //Transforma a string em tag html
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.LastServerMessage}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle={btnstyle}
                    //["default","primary","link","info","success","warning","danger"]
                    btnSize=""
                    />
                )
                });

        }
      };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };      

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toString().toLowerCase()).startsWith(filter.value.toLowerCase())
                :
                true
        );
    }

    componentDidMount() {
        document.body.classList.toggle("BradescoTab-page");
    }

    componentDidUpdate(prevState){
        
        if (this.state.CustomerID!== sessionStorage.getItem("current_CustomerID") || (this.state.ProviderID!== sessionStorage.getItem("current_ProviderID")) ){
            this.setState({
                CustomerID: sessionStorage.getItem("current_CustomerID"),
                ProviderID: sessionStorage.getItem("current_ProviderID"),
                data: []
            });
        }
                
    }

    render(){
        let {
            btnSearchDisabled,
            alert,
            data,
            dataSent,
            dataReceived,
            isLoadingPage,
            spinner,
        } = this.state;

        return (

            <div className="content">
                {alert}
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Informe a data para pesquisa</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Col sm="4">
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h4"></CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Form action="/" className="form-horizontal" method="get">
                                                <Row>
                                                    <Label sm="2">Data Inicial</Label>
                                                    <Col sm="10">
                                                        <FormGroup>
                                                            <ReactDatetime
                                                                inputProps={{
                                                                    className: "form-control",
                                                                    placeholder: "Datetime Picker Here"
                                                                }}
                                                                onChange={e => 
                                                                    {
                                                                        this.setState({startCallDate: e._d})
                                                                        //sliValidations.fieldChange(this, e, "endDate")
                                                                        //sliValidations.fieldChange(this, e, "ddd", "length",2)
                                                                    }
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>                              
                                                    <Label sm="2">Data Final</Label>          
                                                    <Col sm="10">
                                                        <FormGroup>
                                                            <ReactDatetime
                                                                inputProps={{
                                                                    className: "form-control",
                                                                    placeholder: "Datetime Picker Here"
                                                                    
                                                                }}
                                                                onChange={e => 
                                                                    {
                                                                        this.setState({endCallDate: e._d})
                                                                        //sliValidations.fieldChange(this, e, "endDate")
                                                                        //sliValidations.fieldChange(this, e, "ddd", "length",2)
                                                                    }
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="ml-auto" md="3">
                                                        {/* </Row><Button disabled={btnloginDisabled} color="primary" onClick={this.loginClick}> */}
                                                        <Button className="float-right" size="sm" disabled={btnSearchDisabled} color="primary" onClick={e =>this.ButtonClick(e)}>
                                                            Pesquisar
                                                        </Button>
                                                    </Col>

                                                </Row>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>                                
                <Row className="mt-5">
                    <Col xs={12} md={12}>
                        <Card>
                            <CardHeader>                                
                                <CardTitle tag="h4">Resultado da pesquisa</CardTitle>
                                
                                {
                                    this.state.dataSent.length!==0?
                                        (
                                            <CSVLink
                                                data={dataSent}
                                                filename={'CPF enviados para Enriq.csv'}
                                                className="hidden"
                                                ref={this.csvLink}
                                                target="_blank"                                             
                                            />

                                        )
                                    :
                                        null
                                }
                                {
                                    this.state.dataReceived.length!==0?
                                        (
                                            <CSVLink
                                                data={dataReceived}
                                                filename={'CPF Recebidos Enriq.csv'}
                                                className="hidden"
                                                ref={this.csvLinkReceived}
                                                target="_blank"                                             
                                            />

                                        )
                                    :
                                        null
                                }
                               
                                {
                                    this.state.data.length!==0?
                                       (
                                            <CSVLink data={data}><i className={"tim-icons " + icons[5].name} />Baixar Pesquisa em .csv</CSVLink>                                            
                                       )
                                    :
                                        null
                                }

                                {
                                    this.state.data.length!==0?
                                        (
                                            <Button className="float-right" size="sm"  color="primary" onClick={e =>this.DownLoadReceivedData(e)}>
                                               Baixar Retorno de Enriq.
                                            </Button>
                                        )
                                    :
                                        null
                                }
                                {
                                    this.state.data.length!==0?
                                        (
                                            <Button className="float-right" size="sm"  color="primary" onClick={e =>this.DownLoadSentData(e)}>
                                                Baixar Pedido de Enriq.
                                            </Button>
                                        )                                       
                                    :
                                        null
                                }

                                

                                

                            </CardHeader>
                            {isLoadingPage
                                ?spinner
                                :
                                (
                                    <CardBody>
                                        <ReactTable
                                            data={data}
                                            filterable
                                            resizable={false}
                                            columns={[
                                            {
                                                Header: "Carteira",
                                                accessor: "ProviderAlias",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },
                                            {
                                                Header: "Empresa",
                                                accessor: "SkipProviderAlias",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },
                                            {
                                                Header: "Tipo",
                                                accessor: "EntityType",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },
                                            {
                                                Header: "Data Envio",
                                                accessor: "SendFileDate",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },
                                            {
                                                Header: "Qtd Enviada",
                                                accessor: "QtySent",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },
                                            {
                                                Header: "Data Retorno",
                                                accessor: "ReturnFileDate",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },
                                            {
                                                Header: "Qtd Recebida",
                                                accessor: "QtyReturned",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },
                                            {
                                                Header: "Tentativas",
                                                accessor: "Attempts",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },

                                            {
                                                Header: "Conexões",
                                                accessor: "Connected",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },

                                            {
                                                Header: "CPC",
                                                accessor: "RPC",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },

                                            {
                                                Header: "Promessas",
                                                accessor: "Promise",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },

                                            {
                                                Header: "CPC/Conexão",
                                                accessor: "RPCRate",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{(row.value*100).toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits:2})}</div>
                                                )
                                            },
                                           


                                            // {
                                            //     Header: "Actions",
                                            //     accessor: "actions",
                                            //     sortable: false,
                                            //     filterable: false
                                            // }
                                            ]}
                                            defaultPageSize={5}
                                            pageSize={(data.length > 20) ? 20 : data.length}
                                            showPaginationTop
                                            showPaginationBottom={false}
                                            className="-striped -highlight"
                                            showPageSizeOptions= {false}
                                            defaultFilterMethod={this.filterCaseInsensitive}
                                        />
                                    </CardBody>
                                )
                            }
                        </Card>
                    </Col>
         
                </Row>
                
            </div>

        )

    }

}
export default SkipProcess;
