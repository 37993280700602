import React from 'react';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Row,
    Col,
    Form,
    FormGroup,
    Label
  } from "reactstrap";

  
import ReactBSAlert from "react-bootstrap-sweetalert";

import Loader from 'react-loader-spinner'

import {sliData} from "../../commom/sliHelper"
import FTPFiles from '../../components/Files/FTPFiles';

class WebFTP extends React.Component {
    constructor(props){
        super(props);
        this.state={
            file: null,
            CustomerID: sessionStorage.getItem("current_CustomerID"),
            ProviderID: sessionStorage.getItem("current_ProviderID"),
            fileUploadState:"",
            selectedFile:"",
            isLoadingPage: false,
            filePathType:"Automated",
            spinner: (<Loader
                type="Rings"
                //Audio, Ball-Triangle, Bars, Circles, Grid, Hearts, Oval, Puff, Rings, TailSpin, ThreeDots
                color="#00BFFF"
                height={960}
                width={"100%"}
                //timeout={3000} //3 secs
        
                />)
        };
 
        this.hideAlert = this.hideAlert.bind(this);
 
    }

 
    basicAlert = (btnstyle) => {
        if (btnstyle==='success'){
            this.setState({
            alert: (
                <ReactBSAlert                
                success  
                style={{ display: "block", marginTop: "-100px" }}
                title={this.state.LastServerMessage}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle={btnstyle}
                //["default","primary","link","info","success","warning","danger"]
                btnSize=""
                />
            )
            });
        }
        else{
            this.setState({
                alert: (
                    <ReactBSAlert
                    danger  //Transforma a string em tag html
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.LastServerMessage}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle={btnstyle}
                    //["default","primary","link","info","success","warning","danger"]
                    btnSize=""
                    />
                )
                });

        }
      };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };      

    componentDidMount() {
        document.body.classList.toggle("BradescoTab-page");

    }
    
    componentDidUpdate(prevState){
        
        if (this.state.CustomerID!== sessionStorage.getItem("current_CustomerID") || (this.state.ProviderID!== sessionStorage.getItem("current_ProviderID")) ){
            this.setState({
                CustomerID: sessionStorage.getItem("current_CustomerID"),
                ProviderID: sessionStorage.getItem("current_ProviderID")
            });
        }
                
    }


    submit(e) {
        e.preventDefault();

        if (this.state.selectedFile!==""){
             this.setState({isLoadingPage: true});

            const formData = new FormData();      
            formData.append('File', this.state.file);
            formData.append('CustomerID', this.state.CustomerID);
            formData.append('ProviderID', this.state.ProviderID);
            formData.append('FilePathType', this.state.filePathType);
           

            sliData.postFile('/File/UploadFileToFTP', formData
            ).then((result) => {
                
                this.setState({isLoadingPage: false});

                if (result.status === 'Success'){
                    this.setState({ LastServerMessage : result.message , selectedFile:""},() => {this.basicAlert('success')});
                    
                }else{
                    this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }
                
            }).catch((error) => {
                                
                this.setState({ isLoadingPage: false, LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })
         }else{
            this.setState({ LastServerMessage: "Por favor selecione o arquivo!"},() => {this.basicAlert('danger')})
         }

    }

    setFile(e) {
        this.setState({ file: e.target.files[0] , selectedFile: e.target.files[0].name});
    }

    setPathType(e) {
        this.setState({filePathType: e.target.value})
    }

    fileUploadButton = ()=>
    {
        document.getElementById('fileButton').click();

        document.getElementById('fileButton').onchange = () =>
        {   
            this.setState({fileUploadState:document.getElementById('fileButton').value});
        }
    }
   
    render(){
        let {           
            alert,
            selectedFile,
            isLoadingPage,
            spinner,
        } = this.state;

        return (

            <div className="content">
                {alert}
                {isLoadingPage
                ?spinner
                :
                    (
                    <Row>
                        <Col sm="12" md={{ size: 12, offset: 0 }}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h5">Tipo de Pasta</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <FormGroup check className="form-check-radio">
                                        <Row>
                                            <Col sm="2" md={{ size: 2, offset: 0 }}>
                                                <Label check>
                                                    <Input
                                                        defaultChecked
                                                        defaultValue="Automated"
                                                        id="radioTypePath1"
                                                        name="exampleRadios"
                                                        type="radio"
                                                        onChange={e => this.setPathType(e)}
                                                    />
                                                    <span className="form-check-sign" />
                                                    Processamento Automático
                                                </Label>
                                            </Col>
                                            <Col sm="2" md={{ size: 2, offset: 0 }}>
                                                <Label check>
                                                    <Input
                                                        defaultValue="Temporary"
                                                        id="radioTypePath2"
                                                        name="exampleRadios"
                                                        type="radio"
                                                        onChange={e => this.setPathType(e)}
                                                    />
                                                    <span className="form-check-sign" /> 
                                                    Transferência de arquivo temporário
                                                </Label>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="12" md={{ size: 6, offset: 0 }}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h5">Pasta de Entrada</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <Card>
                                            <CardBody>
                                                <Form onSubmit={e => this.submit(e)}>
                                                    <Row>
                                                        <Col md="12">
                                                            <Input
                                                                    id="fileButton"
                                                                    name="fileButton"
                                                                    type="file"
                                                                    onChange={e => this.setFile(e)}
                                                                    //style={{visibility: "hidden"}}
                                                                    hidden
                                                            />
                                                            <label>Selecione o arquivo:</label>
                                                            <Button className="btn-icon"  onClick={this.fileUploadButton}>
                                                                <i className={"tim-icons icon-cloud-upload-94"} /> 
                                                            </Button>
                                                            <Input type="text" value={selectedFile}readOnly></Input>
                                                            <div className="float-right">
                                                                <Button type="submit" color="primary">Enviar Arquivo</Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <FTPFiles CustomerID={this.state.CustomerID} ProviderID={this.state.ProviderID} Type="Inbound" FilePathType={this.state.filePathType}></FTPFiles>
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col md="2"></Col> */}
                        <Col sm="12" md={{ size: 6, offset: 0 }}>
                            <Card>
                                <CardHeader>
                                    <div className="tools float-right">                    
                                    </div>
                                    <CardTitle tag="h5">Pasta de Saída</CardTitle>

                                </CardHeader>
                                <CardBody>
                                    <FTPFiles CustomerID={this.state.CustomerID} ProviderID={this.state.ProviderID} Type="OutBound" FilePathType={this.state.filePathType}></FTPFiles>                              

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    )
                }                              
            </div>

        )

    }

}
export default WebFTP;
