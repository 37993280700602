import { config } from "./constants"


const _apiHost = config.url.API_URL;

async function request(url, params, method = 'GET', uploadfile = false, downloadFile = false) {

  var options = {};

  if (uploadfile)  {
    options = {
        method,
        headers: {
        'Authorization': 'Bearer '+sessionStorage.getItem('id_token') 
        }

    }

  }else{
    options = {
        method,
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+sessionStorage.getItem('id_token') 
        }

    }
  }

  if (params) {
    if (method === 'GET') {
      url += '?' + objectToQueryString(params);
    } else {
    
      options.body = uploadfile===true? params :  JSON.stringify(params);
    }
  }

  const response = await fetch(_apiHost + url, options);

  if (response.status !== 200) {
    return generateErrorResponse('The server responded with an unexpected status.');
  }

  var result;
  if (downloadFile){
    result=await response.blob();
  }else{
    result=await response.json();
  }

  return result;

}


export function objectToQueryString(obj) {
  return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}

export function generateErrorResponse(message) {
  return {
    status : 'error',
    message
  };
}

export const get = (url, params) => {
  return request(url, params);
}

export const post = (url, params) => {
  return request(url, params, 'POST');
}

export const put = (url, params) => {
  return request(url, params, 'PUT');
}

export const del = (url, params) => {
  return request(url, params, 'DELETE');
}

export const postFile = (url, params) => {
    return request(url, params, 'POST',true);
}
  
export const getFile = (url, params) => {
    return request(url, params, 'POST',false, true);
}