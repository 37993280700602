import React from 'react';

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
  } from "reactstrap";

import { Bar } from "react-chartjs-2";


let chartOptions = 
{
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(153, 255, 204,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(153, 255, 204,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }
      ]
    }
}



class ChartBarSingleData extends React.Component {
    constructor(props){
        super(props);

        let graphData=[];
       // graphData=this.SetGraphData("Alias1",this.props.Data[0],this.props.Data[1]);

        this.state={
            GraphView: graphData,
            Title: this.props.Title,
            SubTitle: this.props.SubTitle

        };      


    }

    SetGraphData = (barLabel,labelData,data) => {
        var graphFunction = canvas => {
            let ctx = canvas.getContext("2d");
            var gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        
            gradientStroke.addColorStop(1, "rgba(153, 255, 204,0.8)");
            gradientStroke.addColorStop(0, "rgba(153, 255, 204,0)"); //blue colors
            
            return {
            labels: labelData,
            datasets: [
                {
                label: barLabel,
                fill: true,
                backgroundColor: gradientStroke,
                hoverBackgroundColor: gradientStroke,
                borderColor: "#00cc99",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: data
                }
            ]
            };
        }


        return graphFunction
    }


    componentDidMount() {
       // document.body.classList.toggle("BradescoTab-page");         

    }
   

    componentDidUpdate(prevProps){
        
        if ((prevProps.Data !== this.props.Data) ){          
            let graphData;
            graphData=this.SetGraphData("% Conversão",this.props.Data[0],this.props.Data[1]);            
            this.setState({GraphView:graphData});
        }
      
    }

   
    render(){
        let {    
            GraphView,
            Title,
            SubTitle,
        } = this.state;

        return (

            <div className="content">
                <Card className="card-chart">
                    <CardHeader>
                        <Row>
                            <Col className="text-left" sm="6">
                                <h5 className="card-category">{SubTitle}</h5>
                                <CardTitle tag="h3">
                                    <i className="tim-icons icon-shape-star text-sucess" />{" "}
                                    {Title}
                                </CardTitle>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                    <div className="chart-area">
                        <Bar
                        data={GraphView}
                        options={chartOptions}
                        />
                    </div>
                    </CardBody>
                </Card>                          
            </div>

        )

    }

}
export default ChartBarSingleData;
