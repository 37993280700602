import React from "react";
import { Route, Redirect } from "react-router-dom";
import {sliData} from "./commom/sliHelper"

export const AuthenticatedRoute = ({ component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={
                props => {

                    sliData.get('/Users/VerifyToken',null)
                    .then((result) => {
                        if (result.status !== 'Success'){
                            sessionStorage.setItem("id_token","")
                        }                                        
                    })

                    if (sessionStorage.getItem("id_token")!==""){
                       return <Component {...props} />;
                    } 
                    else{
                       return (
                            <Redirect
                                    to={{
                                        pathname:"/",
                                        state: { from: props.location }
                                    }}
                                />
                       );
                    }
                }
            }
                  
        />
    );

};

 