import React from 'react';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    ButtonGroup,

  } from "reactstrap";

import { Line } from "react-chartjs-2";

import classNames from "classnames";

import {sliData} from "../../commom/sliHelper"

let chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent"
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ]
    }
  };


class ChartInventory extends React.Component {
    constructor(props){
        super(props);
        this.state={
            InventoryGraphView1: {},
            InventoryGraphView2: {},
            InventoryGraphView3: {},
            bigChartData: "data1"
        }; 

    }

    SetGraphData = (barLabel,labelData,data) => {
        var graphFunction = canvas => {
            let ctx = canvas.getContext("2d");
        
            let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        
            gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
            gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
            gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
        
            return {
              labels: labelData,
              datasets: [
                {
                  label: barLabel,
                  fill: true,
                  backgroundColor: gradientStroke,
                  borderColor: "#1f8ef1",
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  pointBackgroundColor: "#1f8ef1",
                  pointBorderColor: "rgba(255,255,255,0)",
                  pointHoverBackgroundColor: "#1f8ef1",
                  pointBorderWidth: 20,
                  pointHoverRadius: 4,
                  pointHoverBorderWidth: 15,
                  pointRadius: 4,
                  data: data
                }
              ]
            };
        }


        return graphFunction
    }

    LoadData = () =>{

        /*
            Inventário Total
        */
        if (this.props.CustomerID!==null && this.props.ProviderID!==null){
            
            var inventoryGraph;

            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(this.props.CustomerID)
                , ProviderID: parseInt(this.props.ProviderID)
                , GroupTestSegmentationID : parseInt(this.props.GroupTestSegmentationID)
                , Graphname: 'graphInventoryTotal'
            }
            ).then((result) => {
                if (result.status === 'Success'){
                    if (result.data.data==null){
                    
                        this.setState({InventoryGraphView1: {}, InventoryGraphView2: {}, InventoryGraphView3:{} });

                    }
                    else{
                        
                        
                        inventoryGraph=JSON.parse(result.data.data);
                        let inventoryGraphData=[];
                        let inventoryGraphLabel=[];
                        let inventoryGraphDataCNPJ=[];
                        let inventoryGraphLabelCNPJ=[];
                        let inventoryGraphLabelNew=[];
                        let inventoryGraphDataNew=[];
                        //this.setState({collectorLasHour: collectors})

                        for (var i in inventoryGraph){
                            if (inventoryGraph[i].Type==="Total"){
                                inventoryGraphLabel.push(inventoryGraph[i].Date);
                                inventoryGraphData.push(inventoryGraph[i].Qty);
                            }
                            if (inventoryGraph[i].Type==="CNPJ"){
                            inventoryGraphLabelCNPJ.push(inventoryGraph[i].Date);
                            inventoryGraphDataCNPJ.push(inventoryGraph[i].Qty);
                            }
                            if (inventoryGraph[i].Type==="New"){
                                inventoryGraphLabelNew.push(inventoryGraph[i].Date);
                                inventoryGraphDataNew.push(inventoryGraph[i].Qty);
                            }                       

                        }
                        
                        let InventoryGraphView1 = this.SetGraphData("Total CPF+CNPJ",inventoryGraphLabel, inventoryGraphData)
                        let InventoryGraphView2 = this.SetGraphData("Apenas CNPJ",inventoryGraphLabelCNPJ, inventoryGraphDataCNPJ)
                        let InventoryGraphView3 = this.SetGraphData("Novos CPF+CNPJ",inventoryGraphLabelNew, inventoryGraphDataNew)
                        this.setState({InventoryGraphView1: InventoryGraphView1, InventoryGraphView2: InventoryGraphView2, InventoryGraphView3: InventoryGraphView3});
                        
                        

                    }

                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })

        }
    }

    setBgChartData = name => {
        this.setState({
        bigChartData: name
        });
    };

    componentDidMount() {
        document.body.classList.toggle("BradescoTab-page");
        this.LoadData();
    }
    

    componentDidUpdate(prevProps){
        
        if (
            (prevProps.CustomerID !== this.props.CustomerID) 
            || (prevProps.ProviderID !== this.props.ProviderID)
            || (prevProps.GroupTestSegmentationID !== this.props.GroupTestSegmentationID)
            )
        {
            this.LoadData();
        }
                
    }

   
    render(){
        let {    
            InventoryGraphView1,
            InventoryGraphView2,
            InventoryGraphView3,       
        } = this.state;

        return (

            <div className="content">
                <Card className="card-chart">
                    <CardHeader>
                        <Row>
                            <Col className="text-left" sm="6">
                                <h5 className="card-category">Total de contas por tipo de Pessoa</h5>
                                <CardTitle tag="h2">Inventário</CardTitle>
                            </Col>
                            <Col sm="6">
                                <ButtonGroup
                                    className="btn-group-toggle float-right"
                                    data-toggle="buttons"
                                >
                                    <Button
                                        color="info"
                                        id="0"
                                        size="sm"
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: this.state.bigChartData === "data1"
                                        })}
                                        onClick={() => this.setBgChartData("data1")}
                                        >
                                        <input defaultChecked name="options" type="radio" />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            CPF/CNPJ
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-single-02" />
                                        </span>
                                    </Button>
                                    <Button
                                        color="info"
                                        id="1"
                                        size="sm"
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: this.state.bigChartData === "data2"
                                        })}
                                        onClick={() => this.setBgChartData("data2")}
                                        >
                                        <input name="options" type="radio" />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            CNPJ
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-bus-front-12" />
                                        </span>
                                    </Button>
                                    <Button
                                        color="info"
                                        id="2"
                                        size="sm"
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: this.state.bigChartData === "data3"
                                        })}
                                        onClick={() => this.setBgChartData("data3")}
                                        >
                                        <input name="options" type="radio" />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Novos/dia
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-tap-02" />
                                        </span>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                    <div className="chart-area">
                        <Line
                            data={this.state.bigChartData==="data1"?InventoryGraphView1:((this.state.bigChartData==="data2")?InventoryGraphView2:InventoryGraphView3)}
                            options={chartOptions}
                        />
                    </div>
                    </CardBody>
                </Card>                             
            </div>

        )

    }

}
export default ChartInventory;
