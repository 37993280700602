import React from 'react';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Input,
    Row,
    Col,
    FormGroup,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
  } from "reactstrap";

import classNames from "classnames";
import ReactBSAlert from "react-bootstrap-sweetalert";
import ReactTable from "react-table";
import {sliData, sliValidations} from "../../../commom/sliHelper"
import { CSVLink } from "react-csv";
import moment from 'moment';
import 'moment/locale/pt-br';
import Loader from 'react-loader-spinner'
import icons from '../../../variables/icons';

class BlackList extends React.Component {
    constructor(props){
        super();
        this.state={
            LastServerMessage:"",
            qtyCallsLoaded:0,
            CustomerID: sessionStorage.getItem("current_CustomerID"),
            ProviderID: sessionStorage.getItem("current_ProviderID"),
            ddd:"",
            phoneNumber:"",
            phoneForbiddenID:0,
            comments:"",
            data:[],
            dddState:'',
            cpfState:'',
            phoneNumberState:'',
            btnSearchDisabled: false,
            btnSaveDisabled: false,
            isLoadingPage: false,
            showModal: false,
            spinner: (<Loader
                type="Rings"
                //Audio, Ball-Triangle, Bars, Circles, Grid, Hearts, Oval, Puff, Rings, TailSpin, ThreeDots
                color="#00BFFF"
                height={960}
                width={"100%"}
                //timeout={3000} //3 secs
        
                />)
        };
     

    }

    BtnSearchClick = (ev) =>{
        this.setState({btnSearchDisabled: true})
        if ( (this.state.phoneNumber ==="" && this.state.ddd==="")){

            this.setState({ LastServerMessage : "Informe o telefone ou o DDD!" , btnSearchDisabled: false},() => {this.basicAlert('danger')});

        }
        else{
        

            this.setState({isLoadingPage: true});
            sliData.post('/Skip/GetPhonesForbidden',{
                ProviderID:parseInt(this.state.ProviderID),
                DDD: parseInt(this.state.ddd),
                PhoneNumber: parseInt(this.state.phoneNumber),
            }).then((result) => {
                
                //let jsonData=JSON.parse(result.data.data)
                //let jsonData=result.data;

            // for 
                if (result!==null)
                {
                    var finalData=result.data.map((prop, key) => {
                        return {
                        id: key,
                        phoneForbiddenID: prop.phoneForbiddenID,
                        callSource: prop.callSource,
                        DDD: prop.ddd,
                        phoneNumber: prop.phoneNumber,
                        creationDate: moment(prop.creationDate).format('DD/MM/YYYY HH:mm:ss'),
                        comments: prop.comments,
                        actions: (
                                    // we've added some custom button actions
                                    <div className="actions-right">
                                        {/* use this button to add a like kind of action */}
                                        {/* <Button
                                            onClick={() => {
                                            let obj = this.state.data.find(o => o.id === key);
                                            alert(
                                                "You've clicked LIKE button on \n{ \nName: " +
                                                obj.name +
                                                ", \nposition: " +
                                                obj.position +
                                                ", \noffice: " +
                                                obj.office +
                                                ", \nage: " +
                                                obj.age +
                                                "\n}."
                                            );
                                            }}
                                            color="info"
                                            size="sm"
                                            className={classNames("btn-icon btn-link like", {
                                            "btn-neutral": key < 5
                                            })}
                                        >
                                            <i className="tim-icons icon-heart-2" />
                                        </Button>{" "}
                                        */}
                                        {/* use this button to add a edit kind of action */}
                                        <Button
                                            onClick={() => {
                                                let obj = this.state.data.find(o => o.id === key);
                                                this.setState({ddd:obj.DDD, phoneNumber: obj.phoneNumber, comments: obj.comments, showModal:true})

                                            }}
                                            color="warning"
                                            size="sm"
                                            className={classNames("btn-icon btn-link like", {
                                            "btn-neutral": key < 0
                                            })}
                                        >
                                            <i className="tim-icons icon-pencil" />
                                        </Button>{" "}

                                        {/* use this button to remove the data row */}
                                        <Button
                                            onClick={() => {
                                                var data = this.state.data;
                                                data.find((o, i) => {
                                                    if (o.id === key) {
                                                        // here you should add some custom code so you can delete the data
                                                        // from this component and from your server as well
                                                        //data.splice(i, 1);
                                                        //console.log(data);

                                                        //Perguntar e enviar command para deletar
                                                        this.setState({ LastServerMessage : "O DDD/Telefone: "+o.DDD+"/"+o.phoneNumber+" será apagado."},() => {this.warningWithConfirmMessage(o.phoneForbiddenID) });

                                                        
                                                        return true;
                                                    }
                                                    return false;
                                                });
                                                // this.setState({ data: data });
                                            }}
                                            color="danger"
                                            size="sm"
                                            className={classNames("btn-icon btn-link like", {
                                            "btn-neutral": key < 0
                                            })}
                                        >
                                            <i className="tim-icons icon-simple-remove" />
                                        </Button>{" "}
                                    </div>
                            )
                        }
                    });

                                            
                    //rowsPerPage

                    this.setState(
                        { LastServerMessage : result.message
                        , data : finalData 
                        , btnSearchDisabled: false 
                        , isLoadingPage:false
                        },() =>{
                                if (result.status !== 'Success'){
                                    
                                    this.basicAlert('danger');

                                }
                                else{

                                    //this.updateRecords();
                                    //this.basicAlert('success');
                                    //this.setState({documentNumber: "",disableButtons:true});                            

                                }                    
                    })
                }
            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message, btnSearchDisabled: false , isLoadingPage:false },() => {this.basicAlert('danger')})

            })

        }
    }

    BtnSaveClick = () =>{
       
        this.setState({btnSaveDisabled: true})


        if ( (this.state.phoneNumber ==="" || this.state.ddd==="")){

            this.setState({ LastServerMessage : "Informe o telefone e o DDD!" , btnSaveDisabled: false},() => {this.basicAlert('danger')});

        }
        else{
        

            //this.setState({isLoadingPage: true});
            sliData.post('/Skip/UpdatePhonesForbidden',{
                ProviderID:parseInt(this.state.ProviderID),
                DDD: parseInt(this.state.ddd),
                PhoneNumber: parseInt(this.state.phoneNumber),
                phoneForbiddenID: parseInt(this.state.phoneforbiddenID),
                comments:this.state.comments,
            }).then((result) => {
                               
                //let jsonData=JSON.parse(result.data.data)
                //let jsonData=result.data;

            // for 
                if (result.data!==null)
                {
                    this.setState({ LastServerMessage : result.message , btnSaveDisabled: false, showModal:false},() => {this.basicAlert('success')});
                    this.BtnSearchClick(null);
                }
                else
                {
                    this.setState({ LastServerMessage : result.message , btnSaveDisabled: false},() => {this.basicAlert('danger')});
                }

            })


        }
    }

    basicAlert = (btnstyle) => {
        if (btnstyle==='success'){
            this.setState({
            alert: (
                <ReactBSAlert                
                success  
                style={{ display: "block", marginTop: "-100px" }}
                title={this.state.LastServerMessage}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle={btnstyle}
                //["default","primary","link","info","success","warning","danger"]
                btnSize=""
                />
            )
            });
        }
        else{
            this.setState({
                alert: (
                    <ReactBSAlert
                    danger  //Transforma a string em tag html
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.LastServerMessage}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle={btnstyle}
                    //["default","primary","link","info","success","warning","danger"]
                    btnSize=""
                    />
                )
                });

        }
      };


    hideAlert = () => {
        this.setState({
            alert: null
        });
    }; 


    successDelete = (id) => {
        sliData.post('/Skip/DeletePhonesForbidden',{
            phoneForbiddenID: parseInt(id),            
        }).then((result) => {
                           
            //let jsonData=JSON.parse(result.data.data)
            //let jsonData=result.data;

        // for 
            if (result.data!==null)
            {
                this.setState({ LastServerMessage : "Registro Apagado com Sucesso!" , btnSaveDisabled: false},() => {this.basicAlert('success')});
                this.BtnSearchClick(null);
            }
            else
            {
                this.setState({ LastServerMessage : result.message , btnSaveDisabled: false},() => {this.basicAlert('danger')});
            }

        })

    };      

    warningWithConfirmMessage = (id) => {
        this.setState({
          alert: (
            <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Você tem certeza?"
              onConfirm={() => this.successDelete(id)}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              confirmBtnText="Sim, pode apagar!"
              cancelBtnText="Cancelar"
              showCancel
              btnSize=""
            >
              {this.state.LastServerMessage}
            </ReactBSAlert>
          )
        });
    };
 
    componentDidMount() {
        document.body.classList.toggle("BlackList");
    }

    componentDidUpdate(prevState){
        
        if (this.state.CustomerID!== sessionStorage.getItem("current_CustomerID") || (this.state.ProviderID!== sessionStorage.getItem("current_ProviderID")) ){
            this.setState({
                CustomerID: sessionStorage.getItem("current_CustomerID"),
                ProviderID: sessionStorage.getItem("current_ProviderID"),
                data:[]
            });
        }
                
    }
    
    toggleModal = () => 
    {
        this.setState({
            showModal: !this.state.showModal
        });
    }
    
    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toString().toLowerCase()).startsWith(filter.value.toLowerCase())
                :
                true
        );
    }

    render(){
        let {
            btnSearchDisabled,
            alert,
            ddd,
            phoneNumber,
            data,
            isLoadingPage,
            spinner,
            showModal,
            comments,
            btnSaveDisabled,
        } = this.state;

        return (

            <div className="content">                
                {alert}

                <Modal isOpen={showModal} toggle={this.toggleModal} modalClassName="modal-info" backdrop="static">
                    <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggleModal}>
                        <i className="tim-icons icon-simple-remove"></i>
                    </button>
                    <h5 className="modal-title">Manutenção da BlackList</h5>
                    </div>
                    <ModalBody>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Adicionar ou Alterar</CardTitle>
                            </CardHeader>   
                            <CardBody>                                            
                                <Form action="/" className="form-horizontal" method="get">                                            
                                    <Row>
                                        {/* <Label sm="2">DDD</Label> */}
                                        <Col sm="12">
                                            <FormGroup className={`has-label ${this.state.dddState}`}>
                                                <label>DDD</label>
                                                <Input
                                                    name="DDD"
                                                    type="text"
                                                    value = {ddd}
                                                    maxLength="2"
                                                    onChange={e => {
                                                        if (sliValidations.verifyPattern(e.target.value, /^[0-9\b]+$/) || e.target.value==="")
                                                        {
                                                            sliValidations.fieldChange(this, e, "ddd", "ddd")
                                                            if (e.target.value==="")
                                                            {
                                                                this.setState({ dddState: "has-success" })
                                                            }
                                                        }
                                                    }}
                                                />
                                                {this.state.dddState === "has-danger" ? (
                                                    <label className="error">
                                                        DDD inválido.
                                                    </label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        
                                    </Row>
                                    <Row>
                                        {/* <Label sm="2">Telefone</Label> */}
                                        <Col sm="12">
                                            <FormGroup className={`has-label ${this.state.phoneNumberState}`}>
                                                <label>Telefone</label>
                                                <Input
                                                    name="phoneNumber"
                                                    type="text"
                                                    value = {phoneNumber}
                                                    maxLength="9"
                                                    onChange={e => 
                                                        {
                                                            if (sliValidations.verifyPattern(e.target.value, /^[0-9\b]+$/) || e.target.value==="")
                                                            {
                                                                sliValidations.fieldChange(this, e, "phoneNumber", "length",8)
                                                                if (e.target.value==="")
                                                                {
                                                                    this.setState({ phoneNumberState: "has-success" })
                                                                }

                                                            }
                                                            
                                                        }
                                                    }
                                                />           
                                                {this.state.phoneNumberState === "has-danger" ? (
                                                    <label className="error">
                                                        Telefone inválido.
                                                    </label>
                                                ) : null}                                 
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {/* <Label sm="2">Telefone</Label> */}
                                        <Col sm="12">
                                            <FormGroup className={`has-label ${this.state.commentsState}`}>
                                                <label>Motivo</label>
                                                <Input
                                                    name="comments"
                                                    type="textarea"
                                                    value = {comments}
                                                    maxLength="80"
                                                    rows="3"
                                                    onChange={e => 
                                                        {
                                                            
                                                            sliValidations.fieldChange(this, e, "comments")
                                                            if (e.target.value==="")
                                                            {
                                                                this.setState({ commentsState: "has-success" })
                                                            }
                                                            
                                                        }
                                                    }
                                                />           
                                                {this.state.commentsState === "has-danger" ? (
                                                    <label className="error">
                                                        Telefone inválido.
                                                    </label>
                                                ) : null}                                 
                                            </FormGroup>
                                        </Col>
                                    </Row>                   
                                </Form>
                            </CardBody>
                        </Card>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleModal} size="sm">
                            Fechar
                        </Button>
                        <Button color="primary" disabled={btnSaveDisabled} onClick={this.BtnSaveClick} size="sm">
                            Salvar
                        </Button>
                    </ModalFooter>
                </Modal>

                <Row>                    
                    <Col md="11">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Manutenção de telefones bloqueados</CardTitle>
                                <Button className="float-left" disabled={btnSearchDisabled} color="info" size="sm" onClick={e =>this.toggleModal(e)}>
                                    Adicionar Telefone
                                </Button>
                            </CardHeader>
                            <CardBody>
                                <Col sm="12" md="8" lg="4">                                       
                                    <Card>
                                        <CardHeader>
                                            {/* <CardTitle tag="h4"></CardTitle> */}                                            
                                        </CardHeader>                                       
                                        <CardBody>
                                            
                                            <Form action="/" className="form-horizontal" method="get">                                            
                                                <Row>
                                                    {/* <Label sm="2">DDD</Label> */}
                                                    <Col sm="12">
                                                        <FormGroup className={`has-label ${this.state.dddState}`}>
                                                            <label>DDD</label>
                                                            <Input
                                                                name="DDD"
                                                                type="text"
                                                                value = {ddd}
                                                                maxLength="2"
                                                                onChange={e => {
                                                                    if (sliValidations.verifyPattern(e.target.value, /^[0-9\b]+$/) || e.target.value==="")
                                                                    {
                                                                        sliValidations.fieldChange(this, e, "ddd", "ddd")
                                                                        if (e.target.value==="")
                                                                        {
                                                                            this.setState({ dddState: "has-success" })
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            {this.state.dddState === "has-danger" ? (
                                                                <label className="error">
                                                                    DDD inválido.
                                                                </label>
                                                            ) : null}
                                                        </FormGroup>
                                                    </Col>
                                                    
                                                </Row>
                                                <Row>
                                                    {/* <Label sm="2">Telefone</Label> */}
                                                    <Col sm="12">
                                                        <FormGroup className={`has-label ${this.state.phoneNumberState}`}>
                                                            <label>Telefone</label>
                                                            <Input
                                                                name="phoneNumber"
                                                                type="text"
                                                                value = {phoneNumber}
                                                                maxLength="9"
                                                                onChange={e => 
                                                                    {
                                                                        if (sliValidations.verifyPattern(e.target.value, /^[0-9\b]+$/) || e.target.value==="")
                                                                        {
                                                                            sliValidations.fieldChange(this, e, "phoneNumber", "length",8)
                                                                            if (e.target.value==="")
                                                                            {
                                                                                this.setState({ phoneNumberState: "has-success" })
                                                                            }

                                                                        }
                                                                        
                                                                    }
                                                                }
                                                            />           
                                                            {this.state.phoneNumberState === "has-danger" ? (
                                                                <label className="error">
                                                                    Telefone inválido.
                                                                </label>
                                                            ) : null}                                 
                                                        </FormGroup>
                                                    </Col>
                                                </Row>                   
                                                <Row>
                                                
                                                    <Col className="ml-auto" sm="12" md="5" lg="6"> 
                                                        {/* </Row><Button disabled={btnloginDisabled} color="primary" onClick={this.loginClick}> */}
                                                        <Button className="float-right" disabled={btnSearchDisabled} color="primary" size="sm" onClick={e =>this.BtnSearchClick(e)}>
                                                            Pesquisar
                                                        </Button>
                                                    </Col>

                                                </Row>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>                                
                <Row className="mt-5">
                    <Col xs={12} md={12}>
                        <Card>
                            <CardHeader>                                
                                <CardTitle tag="h4">Resultado da pesquisa</CardTitle>
                                {
                                    this.state.data.length!==0?                                       
                                       (<CSVLink data={data}><i className={"tim-icons " + icons[5].name} />Baixar Pesquisa em .csv</CSVLink>)                                       
                                    :
                                    null
                                }

                            </CardHeader>
                            {isLoadingPage
                                ?spinner
                                :
                                (
                                    <CardBody>
                                        <ReactTable
                                            data={data}
                                            filterable
                                            resizable={false}
                                            columns={[                                         
                                            {
                                                Header: "DDD",
                                                accessor: "DDD",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },
                                            {
                                                Header: "Telefone",
                                                accessor: "phoneNumber",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },
                                            {
                                                Header: "Data Criação",
                                                accessor: "creationDate",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },
                                            {
                                                Header: "Obs",
                                                accessor: "comments",
                                                headerStyle: {textAlign: 'center'},
                                                Cell: row => (
                                                    <div
                                                      style={{
                                                        textAlign: 'center'
                                                      }}
                                                    >{row.value}</div>
                                                )
                                            },                                    
                                            {
                                                Header: "Ações",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false
                                            }
                                            ]}
                                            defaultPageSize={5}
                                            pageSize={(data.length > 20) ? 20 : data.length}
                                            showPaginationTop
                                            showPaginationBottom={false}
                                            className="-striped -highlight"
                                            showPageSizeOptions= {false}
                                            defaultFilterMethod={this.filterCaseInsensitive}
                                        />
                                    </CardBody>
                                )
                            }
                        </Card>
                    </Col>
         
                </Row>
                
            </div>

        )

    }

}
export default BlackList;
