import React from 'react';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    ButtonGroup,

  } from "reactstrap";

import { Bar } from "react-chartjs-2";

import classNames from "classnames";

import {sliData} from "../../commom/sliHelper"

let chartOptions = 
{
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(253,93,147,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(253,93,147,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }
      ]
    }
}



class ChartAttemptsbyScoreBD extends React.Component {
    constructor(props){
        super(props);
        this.state={
            GraphView1: {},
            GraphView2: {},
            GraphView3: {},
            bigChartData: "data1"
        }; 

    }

    SetGraphData = (barLabel,labelData,data) => {
        var graphFunction = canvas => {
            let ctx = canvas.getContext("2d");
            var gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
        
            gradientStroke.addColorStop(1, "rgba(253,93,147,0.8)");
            gradientStroke.addColorStop(0, "rgba(253,93,147,0)"); //blue colors
            return {
            labels: labelData,
            datasets: [
                {
                label: barLabel,
                fill: true,
                backgroundColor: gradientStroke,
                hoverBackgroundColor: gradientStroke,
                borderColor: "#ff5991",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                data: data
                }
            ]
            };
        }


        return graphFunction
    }

    LoadData = () =>{

        /*
            Inventário Total
        */
        if (this.props.CustomerID!==null && this.props.ProviderID!==null && this.props.GroupTestSegmentationID!==null){
            
            var CallsLast15Days;

            /*
                Ligações por score 15 ultimos dias
            */

            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(this.props.CustomerID)
                , ProviderID: parseInt(this.props.ProviderID)
                , GroupTestSegmentationID: parseInt(this.props.GroupTestSegmentationID)
                , Graphname: 'graphCallByScoreLast15days'
            }).then((result) => {
                if (result.status === 'Success'){
                    if (result.data.data==null){
                
                        this.setState({GraphView1: {}, GraphView2: {} ,GraphView3: {} })
                    }
                    else{
                
                
                        CallsLast15Days=JSON.parse(result.data.data);
                        var CallsLast15DaysData=[];
                        var CallsLast15DaysLabel=[];
                        var CallsLast15DaysDataURA=[];
                        var CallsLast15DaysLabelURA=[];
                        var CallsLast15DaysDataDialer=[];
                        var CallsLast15DaysLabelDialer=[];
                        //this.setState({collectorLasHour: collectors})

                        for (var i in CallsLast15Days){
                            if (CallsLast15Days[i].Type==="Total"){
                                CallsLast15DaysLabel.push(CallsLast15Days[i].ScoreTier);
                                CallsLast15DaysData.push(CallsLast15Days[i].Attempts);                                    
                            }
                            if (CallsLast15Days[i].Type==="URA"){
                                CallsLast15DaysLabelURA.push(CallsLast15Days[i].ScoreTier);
                                CallsLast15DaysDataURA.push(CallsLast15Days[i].Attempts);            
                            }
                            if (CallsLast15Days[i].Type==="Dialer"){
                                CallsLast15DaysLabelDialer.push(CallsLast15Days[i].ScoreTier);
                                CallsLast15DaysDataDialer.push(CallsLast15Days[i].Attempts);            
                            }
                        }

                        var CallsLast15DaysView= this.SetGraphData("URA+Dialer", CallsLast15DaysLabel, CallsLast15DaysData);
                        var CallsLast15DaysViewURA= this.SetGraphData("URA", CallsLast15DaysLabelURA, CallsLast15DaysDataURA);
                        var CallsLast15DaysViewDialer= this.SetGraphData("Dialer", CallsLast15DaysLabelDialer, CallsLast15DaysDataDialer);
                        
                        this.setState({GraphView1: CallsLast15DaysView, GraphView2: CallsLast15DaysViewURA ,GraphView3: CallsLast15DaysViewDialer});
                                

                    }

                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })


        }
    }

    setBgChartData = name => {
        this.setState({
        bigChartData: name
        });
    };

    componentDidMount() {
       // document.body.classList.toggle("BradescoTab-page");
        this.LoadData();
    }
   

    componentDidUpdate(prevProps){
        
        if (
            (prevProps.CustomerID !== this.props.CustomerID) 
            || (prevProps.ProviderID !== this.props.ProviderID)
            || (prevProps.GroupTestSegmentationID !== this.props.GroupTestSegmentationID)
        )
        {
            this.LoadData();
        }
                
    }

   
    render(){
        let {    
            GraphView1,
            GraphView2,
            GraphView3,
        } = this.state;

        return (

            <div className="content">
                <Card className="card-chart">
                    <CardHeader>
                        <Row>
                            <Col className="text-left" sm="6">
                                <h5 className="card-category">últimos 15 dias corridos</h5>
                                <CardTitle tag="h3">
                                    <i className="tim-icons icon-shape-star text-danger" />{" "}
                                    (#)-Tentativas x Score
                                </CardTitle>
                            </Col>
                            <Col sm="6">
                                <ButtonGroup
                                    className="btn-group-toggle float-right"
                                    data-toggle="buttons"
                                >
                                    <Button
                                        color="info"
                                        id="0"
                                        size="sm"
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: this.state.bigChartData === "data1"
                                        })}
                                        onClick={() => this.setBgChartData("data1")}
                                        >
                                        <input defaultChecked name="options" type="radio" />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Ura+Dialer
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-single-02" />
                                        </span>
                                    </Button>
                                    <Button
                                        color="info"
                                        id="1"
                                        size="sm"
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: this.state.bigChartData === "data2"
                                        })}
                                        onClick={() => this.setBgChartData("data2")}
                                        >
                                        <input name="options" type="radio" />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Ura
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-bus-front-12" />
                                        </span>
                                    </Button>
                                    <Button
                                        color="info"
                                        id="2"
                                        size="sm"
                                        tag="label"
                                        className={classNames("btn-simple", {
                                            active: this.state.bigChartData === "data3"
                                        })}
                                        onClick={() => this.setBgChartData("data3")}
                                        >
                                        <input name="options" type="radio" />
                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                            Dialer
                                        </span>
                                        <span className="d-block d-sm-none">
                                            <i className="tim-icons icon-tap-02" />
                                        </span>
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                    <div className="chart-area">
                        <Bar
                        data={this.state.bigChartData==="data1"?GraphView1:((this.state.bigChartData==="data2")?GraphView2:GraphView3)}
                        options={chartOptions}
                        />
                    </div>
                    </CardBody>
                </Card>                          
            </div>

        )

    }

}
export default ChartAttemptsbyScoreBD;
