import React from 'react';
import {
  Chart,
  View,
  Tooltip,
  Schema,
  Axis,
  Interval,
} from 'bizcharts';

import DataSet from '@antv/data-set';

class ChartCandle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data:this.props.data,
        };
       
    }

    componentDidMount() {
        // document.body.classList.toggle("BradescoTab-page");         
        // this.useEffect([[]]);
    }
    
 
     componentDidUpdate(prevProps){
         
        if ((prevProps.data !== this.props.data ) ){                            
             // this.setState({GraphView:graphData});
            this.useEffect(this.props);
        }
       
    }
 

    useEffect = (props) => {
    
        let newData= props.data;
     
        if (newData.length !== 0)
        {
             const ds = new DataSet();
             const dv = ds.createView();
     
             dv.source(newData)
                 .transform({
                 type: 'map',
                 callback: obj => {
                         obj.trend = (obj.start <= obj.end) ? 'rising' : 'falling';
                         obj.range = [obj.start, obj.end, obj.end>obj.max?obj.end:obj.max, obj.end<obj.min?obj.end:obj.min];
                     return obj;
                 }
                 });
                //  console.log(dv)
                 this.setState({data: dv.rows});
         }
     

    }
    
    render(){
        let {    
             data,
        } = this.state;
        return (

            <Chart
                height={400}
                padding={[10, 40, 40, 40]}
                data={data}
                autoFit
                scale={{
                time: {
                type: 'timeCat',
                range: [0, 1],
                tickCount: 4,
                },
                
                volumn: { alias: 'Volume' },
                start: { alias: 'Opening price' },
                end: { alias: 'closing price' },
                max: { alias: 'Highest price'},
                min: { alias: 'lowest price' },
                range: { alias: 'stock price' }
                }}
            >
                <Tooltip
                    showTitle={false}
                    showMarkers={true}
                    itemTpl={'<li class="g2-tooltip-list-item" data-index={index}>'
                        + '<span style="background-color:{color};" class="g2-tooltip-marker"></span>'
                        + '{name}{value}</li>'}
                />
                <View
                    data={data}
                    region={{
                            start: { x: 0, y: 0 },
                            end: { x: 1, y: 0.7 },
                    }}
                >
                    <Schema 
                        position={'time*range'}
                        shape={'candle'}
                        color={[
                            'trend', val => {
                            if (val === 'falling') {
                                return '#f04864';
                            }
                    
                            if (val === 'rising') {
                                return '#2fc25b';
                            }
                            }
                        ]}
                        tooltip={[
                        'time*start*end*max*min',
                        (time, start, end, max, min) => {
                            return {
                            name: '<span style="padding-left: 16px;color:#1a1a1a"><b>Data: '+time+'</b></span>',
                            value: '<br><span style="padding-left: 16px;color:#1a1a1a;">Projetado: ' + start.toLocaleString(undefined,{minimumFractionDigits:2}) + '</span><br/>'
                                + '<span style="padding-left: 16px;color:#1a1a1a">Realizado：' + end.toLocaleString(undefined,{minimumFractionDigits:2}) + '</span><br/>'
                                + '<span style="padding-left: 16px;color:#1a1a1a">Projeção Max：' + max.toLocaleString(undefined,{minimumFractionDigits:2}) + '</span><br/>'
                                + '<span style="padding-left: 16px;color:#1a1a1a">Projeção Min：' + min.toLocaleString(undefined,{minimumFractionDigits:2}) + '</span>'
                            }}
                        ]}
                    />
                </View>
                <View
                        data={data}
                        region={{
                        start: { x: 0, y: 0.7 },
                        end: { x: 1, y: 1 },
                        }}
                        scale={{
                        volumn: {
                            tickCount: 2,
                        }
                        }}
                    >
                    <Axis name="time" tickLine={null} label={null} />
                    <Axis name="volumn"
                        label={{
                            formatter: val => {
                            return +val / 1000 + 'k';
                            }
                    }}
                    />
                    <Interval
                        position={'time*volumn'}
                        color={['trend', val => {
                                if (val === 'falling') {
                                    return '#f04864';
                                }
                        
                                if (val === 'rising') {
                                    return '#2fc25b';
                                }
                        }]}
                        tooltip={['time*volumn', (time, volumn) => {
                                let fvolumn=volumn.toLocaleString(undefined,{minimumFractionDigits:2})
                                return {
                                    name: '<span style="padding-left: 16px;color:#1a1a1a"><b>Data: '+time+'</b></span>',
                                    value: '<br/><span style="padding-left: 16px;color:#1a1a1a">Volume：' + fvolumn + '</span><br/>'
                                };
                        }]}
                />
                </View>
            </Chart>
            // <NumberFormat value={projection.AgreementConversion*100} fixedDecimalScale={true} decimalScale={2} displayType={'text'} thousandSeparator={true}
         )
    }  
   

 }
 
 export default ChartCandle;