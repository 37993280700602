import React from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,  
  Label,
} from "reactstrap";


import {sliData} from "../commom/sliHelper"
import ReactBSAlert from "react-bootstrap-sweetalert";
import NumberFormat from 'react-number-format';
//import moment, { now } from 'moment';
import moment from 'moment';
import RingDisk from './components/ChartRingDisk';
import ChartBarSingleDataPromise from './components/ChartBarSingleDataPromise';
import ChartBarSingleDataAgreement from './components/ChartBarSingleDataAgreement';
import ChartMultLinesProjection from './components/ChartMultLinesProjection';
import ChartMultLinesPromisess from './components/ChartMultLinesPromisess';
import ChartMultLinesAgreements from './components/ChartMultLinesAgreements';
import ChartCandle from './components/ChartCandle'
import ReactDatetime from "react-datetime";

import 'moment/locale/pt-br';

//import { FaRegMoneyBillAlt } from "react-icons/fa";
//import { IconContext } from "react-icons";

class DashboardCash extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bigChartData: "data1",
            CustomerID: sessionStorage.getItem("current_CustomerID"),
            ProviderID: sessionStorage.getItem("current_ProviderID"),
            GroupTestSegmentationID: sessionStorage.getItem("current_GroupTestSegmentationID"),
            projection: {ProjectionAmount:0,ProviderGoal:0,LastUpDate:0,PromiseConversion:0,AgreementConversion:0, PromiseAfterDueConversion:0,AgreementAfterDueConversion:0,BusinessDaysLeft:0},
            promiseConversion:[[],[]] ,
            promiseConversionOut:[[],[]] ,
            agreementConversion:[[],[]] ,
            projectionData:[],
            accounts: {Qty:0, LastDate:0},
            promise: {Qty:0, LastDate:0},
            hot: {Qty:0, LastDate:0},
            collectorLasHour: {Qty:0, LastDate:0},
            AttemptsandCallsView: {},
            CallsLastDayView: {},
            CallsLast15DaysView: {},
            projectionDate: "" ,

        };
       
    }


    componentDidMount() {       
            
        this.LoadData(this.state.CustomerID, this.state.ProviderID, this.state.GroupTestSegmentationID);

    }

    componentDidUpdate(prevProps){
        if (this.state.CustomerID!== sessionStorage.getItem("current_CustomerID") 
        || (this.state.ProviderID!== sessionStorage.getItem("current_ProviderID")) 
        || (this.state.GroupTestSegmentationID!== sessionStorage.getItem("current_GroupTestSegmentationID")))
        {
            this.LoadData(sessionStorage.getItem("current_CustomerID"), sessionStorage.getItem("current_ProviderID"), sessionStorage.getItem("current_GroupTestSegmentationID") );
            this.setState({
                CustomerID: sessionStorage.getItem("current_CustomerID"),
                ProviderID: sessionStorage.getItem("current_ProviderID"),
                GroupTestSegmentationID: sessionStorage.getItem("current_GroupTestSegmentationID")
            });
         }
    }

    basicAlert = (btnstyle) => {
        if (btnstyle==='success'){
            this.setState({
            alert: (
                <ReactBSAlert                
                success  
                style={{ display: "block", marginTop: "-100px" }}
                title={this.state.LastServerMessage}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle={btnstyle}
                //["default","primary","link","info","success","warning","danger"]
                btnSize=""
                />
            )
            });
        }
        else{
            this.setState({
                alert: (
                    <ReactBSAlert
                    danger  //Transforma a string em tag html
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.LastServerMessage}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle={btnstyle}
                    //["default","primary","link","info","success","warning","danger"]
                    btnSize=""
                    />
                )
                });
    
        }
    };    


    LoadData(customerID, providerID, groupTestSegmentationID){

        if (customerID !==null && providerID !==null && groupTestSegmentationID !==null) {

            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(customerID)
                , ProviderID: parseInt(providerID)
                , GroupTestSegmentationID: parseInt(groupTestSegmentationID)
                , StartDate: this.state.projectionDate===""?null:this.state.projectionDate
                , Graphname: 'ProjectionBasicData'
            }
            ).then((result) => {
                if (result.status === 'Success'){

                    let projectionInfo; 

                    if (result.data.data==null){
                        this.setState({projection: [{ProjectionAmount:0,ProviderGoal:0,LastUpDate:0,PromiseConversion:0,AgreementConversion:0, PromiseAfterDueConversion:0,AgreementAfterDueConversion:0,BusinessDaysLeft:0}] });
                    }
                    else{
                        projectionInfo=JSON.parse(result.data.data)[0];                   
                        this.setState({projection: projectionInfo})
                    }
                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })

            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(customerID)
                , ProviderID: parseInt(providerID)
                , GroupTestSegmentationID: parseInt(groupTestSegmentationID)
                , StartDate: this.state.projectionDate===""?null:this.state.projectionDate
                , Graphname: 'ProjectionPromiseConversionIntra'
            }).then((result) => {
                if (result.status === 'Success'){
                    if (result.data.data==null){
                
                        this.setState({promiseConversion: [] })
                    }
                    else{
                
                        let promiseConversionInfo;
                        let label=[];
                        let data=[];

                        promiseConversionInfo=JSON.parse(result.data.data);

                        for (var i in promiseConversionInfo){
                            if (promiseConversionInfo[i].Day===undefined)
                            {
                                label.push(0);
                            }
                            else
                            {
                                label.push(promiseConversionInfo[i].Day);
                            }
                            if (promiseConversionInfo[i].Conversion===undefined)
                            {
                                data.push(0);
                            }
                            else
                            {
                                data.push(promiseConversionInfo[i].Conversion);
                            }
                        }
                        
                        this.setState({promiseConversion: [label,data]})

                    }

                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })
            
            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(customerID)
                , ProviderID: parseInt(providerID)
                , GroupTestSegmentationID: parseInt(groupTestSegmentationID)
                , StartDate: this.state.projectionDate===""?null:this.state.projectionDate
                , Graphname: 'ProjectionPromiseConversionOut'
            }).then((result) => {
                if (result.status === 'Success'){
                    if (result.data.data==null){
                
                        this.setState({promiseConversionOut: [] })
                    }
                    else{
                
                        let promiseConversionInfo;
                        let label=[];
                        let data=[];

                        promiseConversionInfo=JSON.parse(result.data.data);

                        for (var i in promiseConversionInfo){
                            if (promiseConversionInfo[i].Day===undefined)
                            {
                                label.push(0);
                            }
                            else
                            {
                                label.push(promiseConversionInfo[i].Day);
                            }
                            if (promiseConversionInfo[i].Conversion===undefined)
                            {
                                data.push(0);
                            }
                            else
                            {
                                data.push(promiseConversionInfo[i].Conversion);
                            }                            
                        }
                        
                        this.setState({promiseConversionOut: [label,data]})

                    }

                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })

            sliData.post('/Skip/GetGraphData', {
                CustomerID: parseInt(customerID)
                , ProviderID: parseInt(providerID)
                , GroupTestSegmentationID: parseInt(groupTestSegmentationID)
                , StartDate: this.state.projectionDate===""?null:this.state.projectionDate
                , Graphname: 'ProjectionAgreementConversion'
            }).then((result) => {
                if (result.status === 'Success'){
                    if (result.data.data==null){
                
                        this.setState({agreementConversion: [[],[]] })
                    }
                    else{
                
                        let agreementConversionInfo;
                        let label=[];
                        let data=[];

                        agreementConversionInfo=JSON.parse(result.data.data);

                        for (var i in agreementConversionInfo){
                            if (agreementConversionInfo[i].Day===undefined)
                            {
                                label.push(0);    
                            }
                            else
                            {
                                label.push(agreementConversionInfo[i].Day);
                            }
                            if (agreementConversionInfo[i].Conversion===undefined)
                            {

                            }
                            else
                            {
                                data.push(agreementConversionInfo[i].Conversion);
                            }                            
                        }
                        
                        this.setState({agreementConversion: [label,data]})

                    }

                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })

            sliData.post('/Skip/GetGraphData', {
                CustomerID:  parseInt(customerID)
                , ProviderID: parseInt(providerID)
                , GroupTestSegmentationID: parseInt(groupTestSegmentationID)
                , StartDate: this.state.projectionDate===""?null:this.state.projectionDate
                , Graphname: 'ProjectionCashStock'
            }).then((result) => {
                if (result.status === 'Success'){

                    let dataSource=[];

                    if (result.data.data===null){
                
                        this.setState({projectionData: [] });

                    }
                    else{

                        dataSource=JSON.parse(result.data.data);
                                                                      
                        this.setState({ projectionData: dataSource });                                

                    }

                }else{
                    //this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
                }

            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

            })

        }

    }

    ProjectionDate = (event) =>
    {

        this.setState({projectionDate:event._d},()=>{
            this.LoadData(this.state.CustomerID, this.state.ProviderID, this.state.GroupTestSegmentationID);
        })
     

    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };  

    render() {
    let{
        alert,   
        projection,
        promiseConversion,
        promiseConversionOut,
        agreementConversion,
        projectionData,
        projectionDate,
    } = this.state
    return (
      <>
        <div className="content">
            {alert}
            <Row>
                <Col lg="12">
                    <Card className="sticky">
                        <Row>
                            <Col sm="1">
                                <Label className="text-info">Data Base Projeção:</Label>
                            </Col>
                            <Col sm="2">
                                <ReactDatetime 
                                    defaultValue={projectionDate}
                                    timeFormat={false}
                                    locale=""
                                    onChange={e => 
                                        {
                                            this.ProjectionDate(e)
                                        }
                                    }
                                ></ReactDatetime>
                            </Col>
                        </Row>
                    </Card>                    
                </Col>
            </Row>
            <Row>               
                <Col lg="3" md="6">
                    {projection.ProviderGoal>0?
                            (
                            <RingDisk Value={(projection.ProjectionAmount/projection.ProviderGoal).toFixed(2)}></RingDisk>
                            ):
                            (
                                <RingDisk Value={0.00}></RingDisk>
                            )
                    }
                </Col>
                <Col lg="3" md="6">
                    <Card className="card-stats">
                        <CardBody>
                            <Row>
                                <Col xs="5">
                                    <div className="info-icon text-center icon-success">
                                        <i className="tim-icons icon-money-coins"/>
                                    </div>
                                </Col>
                                <Col xs="7">
                                    <div className="numbers">
                                        <p className="card-category">($)-Projeção Final</p>
                                        <CardTitle tag="h3">
                                            <NumberFormat value={projection.ProjectionAmount} fixedDecimalScale={true} decimalScale={2} displayType={'text'} thousandSeparator={true} />
                                        </CardTitle>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <hr />
                            <div className="stats">
                                <i className="tim-icons icon-refresh-01" />Ultima atualização: {moment(projection.LastUpDate).format('DD/MM/YYYY HH:mm:ss')}
                            </div>
                        </CardFooter>
                    </Card> 
                </Col>
                <Col lg="3" md="6">
                    <Card className="card-stats">
                        <CardBody>
                        <Row>
                            <Col xs="5">
                            <div className="info-icon text-center icon-warning">
                                <i className="tim-icons icon-trophy" />
                            </div>
                            </Col>
                            <Col xs="7">
                            <div className="numbers">
                                <p className="card-category">($)-Meta</p>
                                <CardTitle tag="h3">
                                    <NumberFormat value={projection.ProviderGoal} fixedDecimalScale={true} decimalScale={2} displayType={'text'} thousandSeparator={true} />
                                    </CardTitle>
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                        <hr />
                        <div className="stats">
                            <i className="tim-icons icon-refresh-01" />Ultima atualização: {moment(projection.LastUpDate).format('DD/MM/YYYY HH:mm:ss')}
                        </div>
                        </CardFooter>
                    </Card>
                </Col>
                <Col lg="3" md="6">
                    <Card className="card-stats">
                        <CardBody>
                        <Row>
                            <Col xs="5">
                            <div className="info-icon text-center icon-warning">
                                <i className="tim-icons icon-chart-pie-36" />
                            </div>
                            </Col>
                            <Col xs="7">
                            <div className="numbers">
                                <p className="card-category">(%)-Conversão Promessas (1o Parcela)</p>
                                <CardTitle tag="h3">
                                    <NumberFormat value={projection.PromiseConversion*100.00} fixedDecimalScale={true} decimalScale={2} displayType={'text'} thousandSeparator={true} />
                                    </CardTitle>
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                        <hr />
                        <div className="stats">
                            <i className="tim-icons icon-refresh-01" />Ultima atualização: {moment(projection.LastUpDate).format('DD/MM/YYYY HH:mm:ss')}
                        </div>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="3" md="6">
                    <Card className="card-stats">
                        <CardBody>
                        <Row>
                            <Col xs="5">
                            <div className="info-icon text-center icon-warning">
                                <i className="tim-icons icon-chart-pie-36" />
                            </div>
                            </Col>
                            <Col xs="7">
                            <div className="numbers">
                                <p className="card-category">(%)-Conversão Promessa (Após Vencimento)</p>
                                <CardTitle tag="h3">
                                    <NumberFormat value={projection.PromiseAfterDueConversion*100} fixedDecimalScale={true} decimalScale={2} displayType={'text'} thousandSeparator={true} />
                                </CardTitle>
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                        <hr />
                        <div className="stats">
                            <i className="tim-icons icon-refresh-01" />Ultima atualização: {moment(projection.LastUpDate).format('DD/MM/YYYY HH:mm:ss')}
                        </div>
                        </CardFooter>
                    </Card>
                </Col>
                <Col lg="3" md="6">
                    <Card className="card-stats">
                        <CardBody>
                        <Row>
                            <Col xs="5">
                            <div className="info-icon text-center icon-danger">
                                <i className="tim-icons icon-chart-pie-36" />
                            </div>
                            </Col>
                            <Col xs="7">
                            <div className="numbers">
                                <p className="card-category">(%)-Conversão Acordos (Até Vcto)</p>
                                <CardTitle tag="h3">
                                    <NumberFormat value={projection.AgreementConversion*100} fixedDecimalScale={true} decimalScale={2} displayType={'text'} thousandSeparator={true} />                                
                                </CardTitle>
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                        <hr />
                        <div className="stats">
                            <i className="tim-icons icon-refresh-01" />Ultima atualização: {moment(projection.LastUpDate).format('DD/MM/YYYY HH:mm:ss')}
                        </div>
                        </CardFooter>
                    </Card>
                </Col>
                
                <Col lg="3" md="6">
                    <Card className="card-stats">
                        <CardBody>
                        <Row>
                            <Col xs="5">
                            <div className="info-icon text-center icon-danger">
                                <i className="tim-icons icon-chart-pie-36" />
                            </div>
                            </Col>
                            <Col xs="7">
                            <div className="numbers">
                                <p className="card-category">(%)-Conversão Acordo (Após Vencimento)</p>
                                <CardTitle tag="h3">
                                    <NumberFormat value={projection.AgreementAfterDueConversion*100} fixedDecimalScale={true} decimalScale={2} displayType={'text'} thousandSeparator={true} />
                                </CardTitle>
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                        <hr />
                        <div className="stats">
                            <i className="tim-icons icon-refresh-01" />Ultima atualização: {moment(projection.LastUpDate).format('DD/MM/YYYY HH:mm:ss')}
                        </div>
                        </CardFooter>
                    </Card>
                </Col>
                <Col lg="3" md="6">
                    <Card className="card-stats">
                        <CardBody>
                        <Row>
                            <Col xs="5">
                            <div className="info-icon text-center icon-danger">
                                <i className="tim-icons icon-user-run" />
                            </div>
                            </Col>
                            <Col xs="7">
                            <div className="numbers">
                                <p className="card-category">(#)Dias úteis restantes</p>
                                <CardTitle tag="h3">
                                    <NumberFormat value={projection.BusinessDaysLeft} fixedDecimalScale={true} decimalScale={0} displayType={'text'} thousandSeparator={true} />
                                </CardTitle>
                            </div>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                        <hr />
                        <div className="stats">
                            <i className="tim-icons icon-refresh-01" />Ultima atualização: {moment(projection.LastUpDate).format('DD/MM/YYYY HH:mm:ss')}
                        </div>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>                
            <Row>
                <Col lg="4">
                    <ChartBarSingleDataPromise
                        Data={promiseConversion}
                        Title="% Promessas - Intra"
                        SubTitle="Apenas geração dentro do próprio mês"
                    >
                    </ChartBarSingleDataPromise>                
                </Col>
                <Col lg="4">
                    <ChartBarSingleDataPromise 
                        Data={promiseConversionOut}
                        Title="% Promessas - Fora do mês"
                        SubTitle="Apenas geração em meses anteriores"
                    >
                    </ChartBarSingleDataPromise>                
                </Col>
                <Col lg="4">
                    <ChartBarSingleDataAgreement 
                        Data={agreementConversion}
                        Title="% Acordos"
                        SubTitle="2a parcela em diante"
                    >
                    </ChartBarSingleDataAgreement>                
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <ChartMultLinesProjection 
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        ProjectionDate={this.state.projectionDate}
                        GraphName="ProjectionCash_ac"
                        Title="Projeção de Caixa - Acumulado"
                        SubTitle="Dias Corridos"
                    >                        
                    </ChartMultLinesProjection>
                    
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <ChartMultLinesProjection 
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        ProjectionDate={this.state.projectionDate}
                        GraphName="ProjectionCash"
                        Title="Projeção de Caixa"
                        SubTitle="Dias Corridos"
                    >                        
                    </ChartMultLinesProjection>
                    
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <ChartMultLinesPromisess 
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        ProjectionDate={this.state.projectionDate}
                        GraphName="ProjectionPromise"
                        Title="Projeção de Promessas"
                        SubTitle="Dias Corridos"
                    >                        
                    </ChartMultLinesPromisess>
                    
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <ChartMultLinesPromisess 
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        ProjectionDate={this.state.projectionDate}
                        GraphName="ProjectionPromise_ac"
                        Title="Projeção de Promessas - Acumulado"
                        SubTitle="Dias Corridos"
                    >                        
                    </ChartMultLinesPromisess>
                    
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <ChartMultLinesAgreements
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        ProjectionDate={this.state.projectionDate}
                        GraphName="ProjectionAgreement"
                        Title="Projeção de Acordos"
                        SubTitle="Dias Corridos"
                    >                        
                    </ChartMultLinesAgreements>
                    
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <ChartMultLinesAgreements
                        CustomerID={this.state.CustomerID}
                        ProviderID={this.state.ProviderID}
                        GroupTestSegmentationID={this.state.GroupTestSegmentationID}
                        ProjectionDate={this.state.projectionDate}
                        GraphName="ProjectionAgreement_ac"
                        Title="Projeção de Acordos - Acumulado"
                        SubTitle="Dias Corridos"
                    >                        
                    </ChartMultLinesAgreements>
                    
                </Col>
            </Row>
            
           
            <Row>
                <Col xs="12">
                    <Card className="card-chart">
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="6">
                                        <h5 className="card-category">Dias corridos</h5>
                                        <CardTitle tag="h3">
                                            <i className="tim-icons icon-shape-star text-danger" />{" "}
                                            Projeção de Caixa - Análise Financeira
                                        </CardTitle>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                            <div className="chart-area-double">
                                <ChartCandle
                                    data={projectionData}
                                >                        
                                </ChartCandle>
                            </div>
                            </CardBody>
                    </Card>                    
                </Col>
            </Row>
        </div>
      </>
    );
  }
}

export default DashboardCash;
