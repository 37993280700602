/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
//import Select from "react-select";
//import { Row, Col, Form } from "reactstrap";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Col,
  Row,
  Form,
  FormGroup,
  Card,
  CardHeader,  
  CardBody,
  CardFooter,
} from "reactstrap";

import {sliData,sliValidations} from "../../commom/sliHelper";

import ReactBSAlert from "react-bootstrap-sweetalert";


class AdminNavbar extends React.Component {
    constructor(props) {
            super(props);
            this.state = {
            collapseOpen: false,
            modalSearch: false,
            color: "navbar-transparent",
            isLoadingCustomer : true,
            isLoadingProvider : true,
            isLoadingSegmentation : true,
            customers: [],
            providers: [],
            segmentations:[],
            dropdownCustomer:"Loading...",
            dropdownProvider:"Loading...",
            dropdownSegmentation:"Loading...",
            selectedCustomerID:"",
            selectedProviderID:"",
            selectedGroupTestSegmentationID:"",
            loadingPage: false,
            modalProfile: false,
            company:"",
            username:"",
            firstname:"",
            lastname:"",
            email:"",
            defaultpage:"",
            modalPassword:false,
            password:"",
            newPassword:"",
            newPasswordState:"",
            newConfirmPassword:"",
            newConfirmPasswordState:"",
            oldPasswordState:"",
            oldPassword:"",
            modalPasswordError:"",
            btnDisableChangePassword: false,
        };

    }

    componentDidMount() {
        window.addEventListener("resize", this.updateColor);
        
        if (sessionStorage.getItem('current_CustomerID')!==null)
        {
            this.setState({loadingPage: true},() =>{ this.loadCustomers() })
        }else{
            this.loadCustomers();
        }

        this.loadUser();

    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateColor);
    }
    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    updateColor = () => {
        if (window.innerWidth < 993 && this.state.collapseOpen) {
        this.setState({
            color: "bg-white"
        });
        } else {
        this.setState({
            color: "navbar-transparent"
        });
        }
    };
    // this function opens and closes the collapse on small devices
    toggleCollapse = () => {
        if (this.state.collapseOpen) {
        this.setState({
            color: "navbar-transparent"
        });
        } else {
        this.setState({
            color: "bg-white"
        });
        }
        this.setState({
        collapseOpen: !this.state.collapseOpen
        });
    };
    // this function is to open the Search modal
    toggleModalSearch = () => {
        this.setState({
        modalSearch: !this.state.modalSearch
        });
    };

    //Customer
    updateSelectedDropdown = (e) => {    
        
        let _customerID=e.currentTarget.value;
        sessionStorage.setItem('current_CustomerID', e.currentTarget.value);            
        this.setState({isLoadingProvider: true, dropdownCustomer: e.currentTarget.textContent,selectedCustomerID: e.currentTarget.value},()=>{
            this.loadProviders(_customerID);
            })
    }

    updateSelectedDropdownProvider = (e) => {

        let _provider = e.currentTarget.value;

        sessionStorage.setItem('current_ProviderID', e.currentTarget.value);
         this.setState(
                {isLoadingSegmentation: true, dropdownProvider: e.currentTarget.textContent,selectedProviderID: e.currentTarget.value}
                , ()=> {this.loadSegmentations(_provider,null);
         })

        
    }

    updateSelectedDropdownSegmentation = (e) => {
        
        sessionStorage.setItem('current_GroupTestSegmentationID', e.currentTarget.value);
        this.setState({dropdownSegmentation: e.currentTarget.textContent,selectedGroupTestSegmentationID: e.currentTarget.value},
            ()=>{this.props.handleParentStateChange({ProviderID: this.state.selectedProviderID, CustomerID: this.state.selectedCustomerID, GroupTestSegmentationID: this.state.selectedGroupTestSegmentationID });
        })
        
    }

    basicAlert = (btnstyle) => {
        if (btnstyle==='success'){
            this.setState({
            alert: (
                <ReactBSAlert                
                success  
                style={{ display: "block", marginTop: "-100px" }}
                title={this.state.LastServerMessage}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle={btnstyle}
                //["default","primary","link","info","success","warning","danger"]
                btnSize=""
                />
            )
            });
        }
        else{
            this.setState({
                alert: (
                    <ReactBSAlert
                    danger  //Transforma a string em tag html
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.LastServerMessage}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle={btnstyle}
                    //["default","primary","link","info","success","warning","danger"]
                    btnSize=""
                    />
                )
                });

        }
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };  

    loadUser = () => {
        sliData.get('/Users/GetUserInfo',{ 
        }).then((result) => {
            if (result.status === 'Success'){
                if (result.data[0]!==null){
                    this.setState({                        
                        username:result.data.username,
                        firstname:result.data.firstName,
                        lastname:result.data.lastname,
                        email:result.data.email,
                        defaultpage:result.data.defaultPage
                    });
                }
  
            }
            else{
                              
                this.setState({ LastServerMessage: result.message},() => {this.basicAlert('danger')})
    
            }   
        }).catch((error) => {       
            this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})
        })


    }


    loadSegmentations = (_providerid) => {

        sliData.get('/skip/GetSegmentations',{ 
            providerID: _providerid
        }).then((result) => {
            if (result.status === 'Invalid'){
                // this.setState({ LastServerMessage : result.message })
                // this.basicAlert();
            }
            else{
                //current_SegmentationID
                if (result.data[0]!==undefined){
                 
                    if (this.state.loadingPage){                    
                        this.props.handleParentStateChange({ProviderID: parseInt(sessionStorage.getItem('current_ProviderID')) 
                                                            , CustomerID: parseInt(sessionStorage.getItem('current_CustomerID')) 
                                                            , GroupTestSegmentationID: parseInt(sessionStorage.getItem('current_GroupTestSegmentationID')) 
                        });
                    }
                    else{
                        sessionStorage.setItem('current_GroupTestSegmentationID', result.data[0].groupTestSegmentationID);
                    }

                    this.setState({ segmentations : result.data 
                            , isLoadingSegmentation: false
                            , loadingPage: false
                            , dropdownSegmentation: this.state.loadingPage? result.data.filter((e) => e.groupTestSegmentationID === parseInt(sessionStorage.getItem('current_GroupTestSegmentationID')))[0].segmentationDescription : result.data[0].segmentationDescription
                            , selectedGroupTestSegmentationID: this.state.loadingPage? parseInt(sessionStorage.getItem('current_GroupTestSegmentationID')) : result.data[0].groupTestSegmentationID },()=>{
        
                                this.props.handleParentStateChange({ProviderID: this.state.selectedProviderID, CustomerID: this.state.selectedCustomerID, GroupTestSegmentationID: result.data[0].groupTestSegmentationID });

                        }
                    );               
                    
                }
    
            }   
            
        }).catch((error) => {       
            this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})
        })
    
    
    }

    loadProviders = (_customerid) => {

        sliData.get('/skip/GetProviders',{ 
            customerID: _customerid
        }).then((result) => {
            if (result.status === 'Invalid'){
                // this.setState({ LastServerMessage : result.message })
                // this.basicAlert();
            }
            else{
                
                if (this.state.loadingPage){                    
                    this.loadSegmentations(parseInt(sessionStorage.getItem('current_ProviderID')));
                }
                else{
                    sessionStorage.setItem('current_ProviderID', result.data[0].providerID);
                    this.loadSegmentations(result.data[0].providerID);
                }

                this.setState({ providers : result.data 
                        , isLoadingProvider: false
                        , dropdownProvider: this.state.loadingPage? result.data.filter((e) => e.providerID === parseInt(sessionStorage.getItem('current_ProviderID')))[0].alias : result.data[0].alias
                        , selectedProviderID: this.state.loadingPage? parseInt(sessionStorage.getItem('current_ProviderID')) : result.data[0].providerID },()=>{
    
                            //this.props.handleParentStateChange({ProviderID: this.state.selectedProviderID, CustomerID: _customerid });
                    }
                );
               
    
            }   
            
        }).catch((error) => {       
            this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})
        })
    
    
    }
    

    loadCustomers = () => {
    
    sliData.get('/skip/GetCustomers',{
    }).then((result) => {
        if (result.status === 'Invalid'){
            // this.setState({ LastServerMessage : result.message })
            // this.basicAlert();
        }
        else{

            if (result.data[0]!==undefined){


                this.setState({ customers : result.data 
                                , isLoadingCustomer: false
                                , dropdownCustomer: this.state.loadingPage? result.data.filter((e) => e.customerID === parseInt(sessionStorage.getItem('current_CustomerID'))  )[0].alias : result.data[0].alias
                                , selectedCustomerID: this.state.loadingPage? parseInt(sessionStorage.getItem('current_CustomerID')) : result.data[0].customerID }, ()=>{

                        if (this.state.loadingPage){
                            this.loadProviders(parseInt(sessionStorage.getItem('current_CustomerID')));
                        }else
                        {                            
                            this.loadProviders(result.data[0].customerID);
                        }


                    }
                );

                //Colocando o Primeiro Cliente como default do DropDownList
                if (!this.state.loadingPage){
                    sessionStorage.setItem('current_CustomerID', result.data[0].customerID) 
                }
            }
        }   
        
    }).catch((error) => {       
        this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})
    })

  }

  showDropDownSegmentations = () => {

    if (!this.state.isLoadingSegmentation ){

        return this.state.segmentations.map((segmentation, index) =>{
            const {  groupTestSegmentationID , segmentationDescription  } = segmentation
            
            return (
               
                <DropdownItem key={groupTestSegmentationID} value={groupTestSegmentationID} onClick={this.updateSelectedDropdownSegmentation}>{segmentationDescription}</DropdownItem>

            )    

        })


    }

  }

  showDropDownCustomers = () => {

    if (!this.state.isLoadingCustomer){

        return this.state.customers.map((customer, index) =>{
            const {  customerID , alias  } = customer
            
            return (
               
                <DropdownItem key={customerID} value={customerID} onClick={this.updateSelectedDropdown}>{alias}</DropdownItem>

            )    
                //<DropdownItem key={customerID} onClick={this.updateSelectedDropdown}>{alias}</DropdownItem>

        })


    }

  }

  showDropDownProviders = () => {

    if (!this.state.isLoadingProvider){

        return this.state.providers.map((provider, index) =>{
            const {  providerID , alias  } = provider
            
            return (
               
                <DropdownItem key={providerID} value={providerID} onClick={this.updateSelectedDropdownProvider}>{alias}</DropdownItem>

            )    
                //<DropdownItem key={customerID} onClick={this.updateSelectedDropdown}>{alias}</DropdownItem>

        })


    }

  }

  logout = () => {

    sessionStorage.removeItem('current_CustomerID');
    sessionStorage.removeItem('current_ProviderID', '');

    this.props.history.push("/");

  }

  toogleModalProfile = () => {
    this.setState({modalProfile: !this.state.modalProfile})
  }

  toogleChangePassword = () => {
    this.setState({modalPassword: !this.state.modalPassword})
  }


  setNewPassword = () => {  

    this.setState({btnDisableChangePassword: true})

    if ( (this.state.oldPassword === "") || (this.state.newPassword === "") || (this.state.newConfirmPassword!==this.state.newPassword)) {

        if (this.state.oldPassword === "") {
            this.setState({ oldPasswordState: "has-danger" });
        }
        if (this.state.newPassword === "") {
            this.setState({ newPasswordState: "has-danger" });
        }
        
        if (this.state.newConfirmPassword!==this.state.newPassword) {
            this.setState({ newConfirmPasswordState: "has-danger" });
        }


        this.setState({btnDisableChangePassword: false});

    }
    else {

        sliData.post('/Users/WebChangePassword',{
            OldPassword: this.state.oldPassword,
            NewPassword: this.state.newPassword
        }).then((result) => {
            
            this.setState({ LastServerMessage : result.message });
           
            this.basicAlert((result.status === 'Success'?'success':'danger'));

            this.setState({btnDisableChangePassword: false});

        }).catch((error) => {
            this.setState({ LastServerMessage: error.message})
            this.basicAlert("danger");
            
            this.setState({btnDisableChangePassword: false});

        })
       

    }

};

  render() {
    let {           
        alert,
        username,
        firstname,
        lastname,
        email,
        defaultpage,
        oldPasswordState,
        newPasswordState,
        newConfirmPasswordState,
        btnDisableChangePassword,
    } = this.state;
    return (
      <>
        {alert}
        <Navbar
          className={classNames("navbar-absolute", {
            [this.state.color]:
              this.props.location.pathname.indexOf("full-screen-map") === -1
          })}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize d-inline">
                <Button
                  className="minimize-sidebar btn-just-icon"
                  color="link"
                  id="tooltip209599"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                  <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="tooltip209599"
                  placement="right"
                >
                  Sidebar toggle
                </UncontrolledTooltip>
              </div>
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: this.props.sidebarOpened
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.props.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="#lk" onClick={e => e.preventDefault()}>
                {this.props.brandText}
              </NavbarBrand>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navigation"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav className="ml-auto" navbar>
                <UncontrolledDropdown nav>
                    <DropdownToggle caret data-toggle="dropdown" color="info">
                        {this.state.dropdownCustomer}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>Selecione o Cliente</DropdownItem>
                        {this.showDropDownCustomers()}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav>
                    <DropdownToggle caret data-toggle="dropdown" color="info" >
                        {this.state.dropdownProvider}
                    </DropdownToggle>
                    <DropdownMenu className="scroll-menu">
                        <DropdownItem header>Selecione o Provider</DropdownItem>        
                        {this.showDropDownProviders()}             
                    </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav>
                    <DropdownToggle caret data-toggle="dropdown" color="info">
                        {this.state.dropdownSegmentation}
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>Selecione o Segmento</DropdownItem>
                        {this.showDropDownSegmentations()}
                    </DropdownMenu>
                </UncontrolledDropdown>
                {/* <InputGroup className="search-bar" tag="li">
                  <Button
                    color="link"
                    data-target="#searchModal"
                    data-toggle="modal"
                    id="search-button"
                    onClick={this.toggleModalSearch}
                  >
                    <i className="tim-icons icon-zoom-split" />
                    <span className="d-lg-none d-md-block">Search</span>
                  </Button>
                </InputGroup> */}
                {/* <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                  >
                    <div className="notification d-none d-lg-block d-xl-block" />
                    <i className="tim-icons icon-sound-wave" />
                    <p className="d-lg-none">Notifications</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Mike John responded to your email
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        You have 5 more tasks
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Your friend Michael is in town
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Another notification
                      </DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">
                        Another one
                      </DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                    onClick={e => e.preventDefault()}
                  >
                    <div className="photo">
                      <img alt="..." src={require("assets/img/default-avatar.png")} />
                    </div>
                    <b className="caret d-none d-lg-block d-xl-block" />
                    <p className="d-lg-none">Log out</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                      <DropdownItem className="nav-item" onClick={() => this.toogleModalProfile()}>Perfil</DropdownItem>
                    </NavLink>
                    <NavLink tag="li">
                      <DropdownItem className="nav-item" onClick={() => this.toogleChangePassword()}>Trocar Senha</DropdownItem>
                    </NavLink>
                    <DropdownItem divider tag="li" />
                    <NavLink tag="li">
                      <DropdownItem className="nav-item" onClick={() => this.logout()}>Sair do Sistema</DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <Modal
          modalClassName="modal-search"
          isOpen={this.state.modalSearch}
          toggle={this.toggleModalSearch}
        >
          <div className="modal-header">
            <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalSearch}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
        </Modal>
        
        <Modal isOpen={this.state.modalProfile} toggle={this.toogleModalProfile} modalClassName="modal-info">
            <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.toogleModalProfile()}>
                <i className="tim-icons icon-simple-remove"></i>
            </button>
            <h5 className="modal-title">Profile</h5>
            </div>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                            <h5 className="title">(Apenas leitura)</h5>
                            </CardHeader>
                            <CardBody>
                            <Form>
                                <Row>
                                <Col className="pr-md-1" md="6">
                                    <FormGroup>
                                    <label>Página Padrão</label>
                                    <Input
                                        defaultValue={defaultpage}
                                        disabled
                                        type="text"
                                    />
                                    </FormGroup>
                                </Col>
                                <Col className="pl-md-1" md="6">
                                    <FormGroup>
                                    <label>Usuário</label>
                                    <Input defaultValue={username} type="text" disabled/>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                    <label>Endereço de Email</label>
                                    <Input defaultValue={email} type="text" disabled/>
                                    </FormGroup>
                                </Col>
                                </Row>
                                <Row>
                                <Col className="pr-md-1" md="6">
                                    <FormGroup>
                                    <label>Primeiro Name</label>
                                    <Input defaultValue={firstname} type="text" disabled/>
                                    </FormGroup>
                                </Col>
                                <Col className="pl-md-1" md="6">
                                    <FormGroup>
                                    <label>Último Nome</label>
                                    <Input defaultValue={lastname} type="text" disabled/>
                                    </FormGroup>
                                </Col>
                                </Row> 
                                <Row>
                                <Col md="12">
                                    <FormGroup>
                                    <label>Sobre mim</label>
                                    <Input
                                        cols="80"
                                        defaultValue=""
                                        placeholder=""
                                        rows="4"
                                        type="textarea"
                                    />
                                    </FormGroup>
                                </Col>
                                </Row>
                            </Form>
                            </CardBody>
                            <CardFooter>                           
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
              

            </ModalBody>
            <ModalFooter >
                <Button color="primary" onClick={()=>this.toogleModalProfile()}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalPassword} toggle={this.toogleChangePassword} modalClassName="modal-info">
            <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.toogleChangePassword()}>
                <i className="tim-icons icon-simple-remove"></i>
            </button>
            <h5 className="modal-title">Mudança de Senha</h5>
            </div>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                            <h5 className="title">Informe sua senha</h5>
                            </CardHeader>
                            <CardBody>  
                                <FormGroup className={`has-label ${oldPasswordState}`}>
                                    <label>Senha Atual*</label>
                                    <Input
                                    id="oldPassword"
                                    name="oldPassword"
                                    type="password"
                                    autoComplete="off"
                                    onChange={e =>
                                        sliValidations.fieldChange(this, e, "oldPassword", "password")
                                    }
                                    />
                                    {this.state.oldPasswordState === "has-danger" ? (
                                    <label className="error">Campo obrigatório</label>
                                    ) : null}
                                </FormGroup>
                                <FormGroup className={`has-label ${newPasswordState}`}>
                                    <label>Nova Senha*</label>
                                    <Input
                                    id="newPassword"
                                    name="newPassword"
                                    type="password"
                                    autoComplete="off"
                                    onChange={e =>
                                        sliValidations.fieldChange(this, e, "newPassword", "password")
                                    }
                                    />
                                    {this.state.newPasswordState === "has-danger" ? (
                                    <label className="error">Este campo é obrigatório</label>
                                    ) : null}
                                </FormGroup>
                                <FormGroup className={`has-label ${newConfirmPasswordState}`}>
                                    <label>Confirme a nova senha*</label>
                                    <Input
                                    equalto="#newPassword"
                                    id="newConfirmPassword"
                                    name="newConfirmPassword"
                                    type="password"
                                    autoComplete="off"
                                    onChange={e =>
                                        sliValidations.fieldChange(this,
                                        e,
                                        "newConfirmPassword",
                                        "equalTo",
                                        "newPassword"
                                        )
                                    }
                                    />
                                    {this.state.newConfirmPasswordState ===
                                    "has-danger" ? (
                                        <label className="error">Campo obrigatório ou senha diferente.</label>
                                    ) : null}
                                </FormGroup>
                                <div className="text-primary">
                                    * Campos obrigatórios.
                                </div>

                            </CardBody>
                            <CardFooter>                           
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Container fluid>
                    <Row lg="12">
                        <Col md="8"> 
                            <Button color="default" onClick={()=>this.toogleChangePassword()}>
                                Close
                            </Button>
                        </Col>
                        <Col md="2">
                            <Button disabled={btnDisableChangePassword} color="primary" onClick={()=>this.setNewPassword()}>
                                Trocar Senha
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </ModalFooter>
        </Modal>

      </>
    );
  }
}

export default AdminNavbar;
