import React from "react";
import {sliData} from "../../commom/sliHelper"
// reactstrap components
import {  
    Table,
  } from "reactstrap";

import moment from 'moment';

class ProcessedFiles extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading : true
        }

        this.LoadData = this.LoadData.bind(this);
        this.RenderTableData = this.RenderTableData.bind(this);
    }

    LoadData(){

        sliData.get('/File/GetProcessedFiles',{ 
            direction: this.props.direction
            , rows: this.props.rows
            , CustomerID: parseInt(this.props.CustomerID)
            , ProviderID: parseInt(this.props.ProviderID)
        }
        ).then((result) => {
            if (result.status === 'Invalid'){
                
                    // this.setState({ LastServerMessage : result.message })
                // this.basicAlert();
            }
            else{
                this.setState({ data : result.data , isLoading: false });
                // sessionStorage.setItem('id_token', result.data.token)
                // this.props.history.push("/MainSLI/Dashboard");

            }                    
        }).catch((error) => {
            // this.setState({ LastServerMessage: error.message})
            // this.basicAlert("danger");
        })

    }

    RenderTableData(){
        
        
        if (!this.state.isLoading){
            if (this.state.data !== null && this.state.data !== undefined){
                return this.state.data.map((file, index) => {
                    const {  fileModel , fileName, fileStatus , creationDate } = file

                    return (
                    
                        <tr key={index}>
                            <td>{fileModel}</td>
                            <td>{fileName}</td>
                            <td className="text-center">{fileStatus}</td>
                            <td className="text-center">{moment(creationDate).format('DD/MM/YYYY HH:mm:ss')}</td>
                        </tr>

                    )       

                })
            }
        }

    }

    componentDidMount() {
        if (this.props.CustomerID!==null)
        {
            this.LoadData();            
        }
    }

    componentDidUpdate(prevProps) {

        if ((prevProps.CustomerID !== this.props.CustomerID) || (prevProps.ProviderID !== this.props.ProviderID)){
            this.LoadData();
        }

    }

    render(){
        return(
            <Table responsive>
                <thead className="text-primary">
                    <tr>
                    <th>File Model</th>
                    <th>File Name</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Creation Date</th>
                    </tr>
                </thead>
                <tbody>
                    {this.RenderTableData()}
                </tbody>
            </Table>

        );

    }

}

export default ProcessedFiles;