
import React from "react";

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayoutSLI from "sli/layouts/AuthSLI.jsx";
import MainLayout from "sli/layouts/MainSLI.jsx";

import "assets/css/nucleo-icons.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.0.0";
import "assets/demo/demo.css";
import "react-notification-alert/dist/animate.css";
import {AuthenticatedRoute} from "sli/authenticatedRoute";
import CacheBuster from "./CacheBuster";

const hist = createBrowserHistory();

class App extends React.Component {
    render() {
      return (
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
            }
  
            return (
              <div className="App">
                    <Router history={hist}>
                        <Switch>
                        <Route path="/authSLI" render={props => <AuthLayoutSLI {...props} />} /> 
                        <Route path="/authSLI/Login" render={props => <AuthLayoutSLI {...props} />} />
                        <Route path="/authSLI/Register" render={props => <AuthLayoutSLI {...props} />} />
                        <Route path="/authSLI/validateEmail" render={props => <AuthLayoutSLI {...props} />} />
                        <Route path="/authSLI/resetpassword" render={props => <AuthLayoutSLI {...props} />} />
                        {/* <Route path="/admin" render={props => <AdminLayout {...props} />} /> */}
                        {/* <Route path="/rtl" render={props => <RTLLayout {...props} />} /> */}
                        {/* <AuthenticatedRoute path="/admin" render={props => <AdminLayout {...props} />} /> */}
                        {/* <Route path="/admin" component={AdminLayout} />} /> */}
                        <AuthenticatedRoute path="/MainSLI/Dashboard" component={MainLayout} /> 
                        <AuthenticatedRoute path="/MainSLI/DashboardKpi" component={MainLayout} /> 
                        <AuthenticatedRoute path="/MainSLI/DashboardCash" component={MainLayout} /> 
                        <AuthenticatedRoute path="/MainSLI/DashboardCollectorsKPI" component={MainLayout} /> 
                        <AuthenticatedRoute path="/MainSLI/Bradesco" component={MainLayout} /> 
                        <AuthenticatedRoute path="/MainSLI/Default" component={MainLayout} /> 
                        <AuthenticatedRoute path="/MainSLI/WebFTP" component={MainLayout} /> 
                        <AuthenticatedRoute path="/MainSLI/CallHistory" component={MainLayout} /> 
                        <AuthenticatedRoute path="/MainSLI/SMSProcess" component={MainLayout} /> 
                        <AuthenticatedRoute path="/MainSLI/SkipProcess" component={MainLayout} /> 
                        <AuthenticatedRoute path="/MainSLI/BlackList" component={MainLayout} /> 
                        <AuthenticatedRoute path="/MainSLI/ProjectionSummary" component={MainLayout} /> 
                        {/* <AuthenticatedRoute path="/MainSLI/DynamicReportCalls" component={MainLayout} />  */}
                        <Redirect from="/" to="/authSLI/Login" />
                        </Switch>
                    </Router>
              </div>
            );
          }}
        </CacheBuster>
      );
    }
  }
  
  export default App;