const prod = {
    url: {
        API_URL: 'https://www.slisystem.com.br:5030',
         API_URL_USERS: 'https://myapp.herokuapp.com/users'
    }
};

const dev = {
    url: {
        API_URL: 'http://localhost:51406'
    }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;

