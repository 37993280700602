import React from 'react';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Input,
    Row,
    Col
  } from "reactstrap";

  
import ReactBSAlert from "react-bootstrap-sweetalert";

import {sliData, sliValidations} from "../../commom/sliHelper"


class BradescoTab extends React.Component {
    constructor(props){
        super(props);
        this.state={
            documentNumber:"",
            companyCode:"",
            LastServerMessage:"",
            disableButtons:true,
            qtyCallsLoaded:0,
            CustomerID: sessionStorage.getItem("current_CustomerID"),
            ProviderID: sessionStorage.getItem("current_ProviderID")
        };
        this.ButtonClick = this.ButtonClick.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.fieldChange = this.fieldChange.bind(this);
        this.updateRecords = this.updateRecords.bind(this);

    }

    ButtonClick = (ev) =>{
        
        if ((this.state.documentNumber==="") || !( sliValidations.verifyCPF(this.state.documentNumber) || sliValidations.verifyCNPJ(this.state.documentNumber) ) )
        {

            this.setState({ LastServerMessage : "CPF ou CNPJ inválido!" },() => {this.basicAlert('danger')});
        }
        else{

            sliData.post('/CustomCustomer/RegisterCalls',{
                // eslint-disable-next-line
                DocumentNumber: this.state.documentNumber.replace(/\./g,'').replace(/\,/g,'').replace(/\-/g,'').replace(/\//g,''), //this.state.loginEmail,
                CompanyCode: ev.target.name, //this.state.loginPassword''
                CustomerID: parseInt(this.state.CustomerID),
                ProviderID:parseInt(this.state.ProviderID)
            }).then((result) => {
                this.setState({ LastServerMessage : result.message },() =>{
                    if (result.status !== 'Success'){
                        
                        this.basicAlert('danger');


                    }
                    else{

                        this.updateRecords();
                        this.basicAlert('success');
                        this.setState({documentNumber: "",disableButtons:true});

                    }                    
                })
            }).catch((error) => {
                
                this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})
                


            })
        }
    }

    fieldChange = (ev) =>{

        this.setState({documentNumber: ev.target.value })

        if (ev.target.value!=="")
        {
            this.setState({ disableButtons:false});
        }
        else{
            this.setState({ disableButtons:true});
        }

    }

    basicAlert = (btnstyle) => {
        if (btnstyle==='success'){
            this.setState({
            alert: (
                <ReactBSAlert                
                success  
                style={{ display: "block", marginTop: "-100px" }}
                title={this.state.LastServerMessage}
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle={btnstyle}
                //["default","primary","link","info","success","warning","danger"]
                btnSize=""
                />
            )
            });
        }
        else{
            this.setState({
                alert: (
                    <ReactBSAlert
                    danger  //Transforma a string em tag html
                    style={{ display: "block", marginTop: "-100px" }}
                    title={this.state.LastServerMessage}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle={btnstyle}
                    //["default","primary","link","info","success","warning","danger"]
                    btnSize=""
                    />
                )
                });

        }
      };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };      


    updateRecords = () => {

        sliData.post('/CustomCustomer/GetRegisterCalls',{
            DocumentNumber: "",
                CompanyCode: "",
                CustomerID: 7            
        }).then((result) => {
            if (result.status !== 'Success'){
                this.setState({ qtyCallsLoaded : result.data });
            }
        }).catch((error) => {
            
            this.setState({ LastServerMessage: error.message},() => {this.basicAlert('danger')})

        })
        
    }

    componentDidMount() {
        document.body.classList.toggle("BradescoTab-page");
        this.updateRecords();

    }
   

    componentDidUpdate(prevState){
        
        if (this.state.CustomerID!== sessionStorage.getItem("current_CustomerID") || (this.state.ProviderID!== sessionStorage.getItem("current_ProviderID")) ){
            this.setState({
                CustomerID: sessionStorage.getItem("current_CustomerID"),
                ProviderID: sessionStorage.getItem("current_ProviderID")
            });
        }
                
    }

    render(){
        let {
            
            documentNumber,            
            disableButtons,
            alert,
            qtyCallsLoaded,
        } = this.state;

        return (

            <div className="content">
                {alert}
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Informe o CPF/CNPJ</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Input
                                    name="documentNumber"
                                    type="text"
                                    value = {documentNumber}
                                    onChange={e => this.fieldChange(e)}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>                
                <Row>
                    <Col md="4">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Improdutivo</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <Button disabled={disableButtons} name="LMUD" color="danger" size="sm" style={{width:120}} onClick={e =>this.ButtonClick(e)}>Lig. Muda</Button>                                    
                                    </Col>
                                    <Col md="6">
                                        <Button disabled={disableButtons} name="Intercep" color="danger" size="sm" style={{width:120}} onClick={e =>this.ButtonClick(e)}>Lig. Interc.</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Button disabled={disableButtons} name="Incorret" color="danger" size="sm" style={{width:120}} onClick={e =>this.ButtonClick(e)}>Desc.Cliente</Button>                                    
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Falei com Terceiro</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <Button disabled={disableButtons} name="AGENTERC" color="info" size="sm" style={{width:120}} onClick={e =>this.ButtonClick(e)}>Agend.Terc.</Button>                                    
                                    </Col>
                                    <Col md="6">
                                        <Button disabled={disableButtons} name="RECADO" color="info" size="sm" style={{width:120}} onClick={e =>this.ButtonClick(e)}>Recado</Button>                                    
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Button disabled={disableButtons} name="FALECEU" color="info" size="sm" style={{width:120}} onClick={e =>this.ButtonClick(e)}>Faleceu</Button>                                    
                                    </Col>
                                    <Col md="6">
                                        <Button disabled={disableButtons} name="recluso" color="info" size="sm" style={{width:120}} onClick={e =>this.ButtonClick(e)}>Cliente Preso</Button>                                    
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Falei com o Cliente</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <Button disabled={disableButtons} name="Alega" color="success" size="sm" style={{width:120}} onClick={e =>this.ButtonClick(e)}>Alega Pgto</Button>                                    
                                    </Col>
                                    <Col md="6">
                                        <Button disabled={disableButtons} name="AlegaNeg" color="success" size="sm" style={{width:120}} onClick={e =>this.ButtonClick(e)}>Alega Neg.</Button>                                    
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Button disabled={disableButtons} name="DescDivi" color="success" size="sm" style={{width:120}} onClick={e =>this.ButtonClick(e)}>Desc. Dívida</Button>                                    
                                    </Col>
                                    <Col md="6">
                                        <Button disabled={disableButtons} name="RECUSA" color="success" size="sm" style={{width:120}} onClick={e =>this.ButtonClick(e)}>Recusa</Button>                                    
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Button disabled={disableButtons} name="Reneg" color="success" size="sm" style={{width:120}} onClick={e =>this.ButtonClick(e)}>Renegoc.</Button>                                    
                                    </Col>
                                    <Col md="6">
                                        <Button disabled={disableButtons} name="PPGT" color="success" size="sm" style={{width:120}} onClick={e =>this.ButtonClick(e)}>Promes.Pagto</Button>                                    
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Button disabled={disableButtons} name="Follow" color="success" size="sm" style={{width:120}} onClick={e =>this.ButtonClick(e)}>Cor.de Follow</Button>                                    
                                    </Col>
                                    <Col md="6">
                                        <Button disabled={disableButtons} name="AGENCLI" color="success" size="sm" style={{width:120}} onClick={e =>this.ButtonClick(e)}>Agen. Cliente</Button>                                    
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col xs="5">
                                    <div className="info-icon text-center icon-success">
                                        <i className="tim-icons icon-single-02" />
                                    </div>
                                    </Col>
                                    <Col xs="7">
                                    <div className="numbers">
                                        <p className="card-category">Quantidade de Ligações Registradas</p>
                                        <CardTitle tag="h3">{qtyCallsLoaded}</CardTitle>
                                    </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="tim-icons icon-trophy" /> Resumo de ligações do dia
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                
            </div>

        )

    }

}
export default BradescoTab;
